.price-list .slider-track {
    background-color: #cacaca;
    background-image: -moz-linear-gradient(top, #cacaca, #cacaca);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#cacaca), to(#cacaca));
    background-image: -webkit-linear-gradient(top, #cacaca, #cacaca);
    background-image: -o-linear-gradient(top, #cacaca, #cacaca);
    background-image: linear-gradient(to bottom, #cacaca, #cacaca);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#F9F9F9', GradientType=0);
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: absolute;
    cursor: pointer;
}

.price-list .slider-selection {
    background-color: #C69C5D;
    background-image: -moz-linear-gradient(top, #C69C5D, #C69C5D);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#C69C5D), to(#C69C5D));
    background-image: -webkit-linear-gradient(top, #C69C5D, #C69C5D);
    background-image: -o-linear-gradient(top, #C69C5D, #C69C5D);
    background-image: linear-gradient(to bottom, #C69C5D, #C69C5D);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F9F9F9', endColorstr='#f5f5f5', GradientType=0);
    box-shadow: none !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: absolute;
}

.price-list .slider-handle {
    background-color: #C69C5D;
    background-image: -moz-linear-gradient(top, #C69C5D, #C69C5D);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#C69C5D), to(#C69C5D));
    background-image: -webkit-linear-gradient(top, #C69C5D, #C69C5D);
    background-image: -o-linear-gradient(top, #C69C5D, #C69C5D);
    background-image: linear-gradient(to bottom, #C69C5D, #C69C5D);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0480BE', endColorstr='#036fa5', GradientType=0);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    position: absolute;
    top: 0;
    width: 15px;
    height: 15px;
    margin-top: -4px;
    background-color: #C69C5D;
    border: 0px solid transparent;
}

.tooltip-inner {
    max-width: 200px;
    padding: .35rem .9rem;
    color: #fff;
    font-size: 15px;
    text-align: center;
    background-color: #C69C5D;
    border-radius: .25rem;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #C69C5D;
}

.price-list .slider.slider-horizontal {
    width: 100%;
    height: 3px;
}

.price-list .slider.slider-horizontal .slider-track {
    height: 3px;
    margin-top: 0;
}

.price-list .slider .tooltip.bs-tooltip-top {
    left: auto;
}

#filter-element .close-x:hover{
    transform: scale(1.2);
}



/*table*/
.price-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.price-table td {
    text-align: left;
    padding: 20px;
    font-size: 15px;
    color: #7A7E7E;
    font-weight: 500;
    line-height: 24px;
}
.price-table th{
    color: #7A7E7E;
    font-size: 15px;
    font-weight: bold;
    line-height: 24px;
    padding: 20px 20px 10px 20px;
}
.price-table .more{
    color:#222829;
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
}
.price-table .td-link:hover{
    color: #CDA871;
    fill: #CDA871;
    transform: scale(1.02);
}
.price-table tr:nth-child(even) {
    background-color: #F2F2F2;
}


@media(min-width: 993px){
    #filter-element{
        display: flex !important;
    }
}

@media (max-width: 992px) {

    #filter-mobile #filter-element {
        position: fixed;
        display: none;
        background: white;
        top: 0;
        left: 0;
        padding: 50px;
        z-index: 1000;
        width: -webkit-fill-available;
        height: 100%;
    }

    .navbar-filter-mobile{
        background: #CDA871;
        position: absolute;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        left: 0;
        top: 0;
        width: 100%;
        height: 70px;
        padding: 0 40px;
        box-shadow: 0 0 4px #8a8a8a;
    }


    .show-hide-price-list{
        display: none !important;
    }

    /* Force table to not be like tables anymore */
    .price-table table, thead, tbody, th, td, tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .price-table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .price-table tr { border: 1px solid #ccc; }

    .price-table td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50% !important;
    }

    .price-table td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        left: 15px;
        width: 45%;
        padding-right: 10px;
        padding-left: 5px;
        white-space: nowrap;
        font-weight: bold;
    }

    /*
    Label the data
    */
    .price-table td:nth-of-type(1):before { content: "Numer"; }
    .price-table td:nth-of-type(2):before { content: "Piętro"; }
    .price-table td:nth-of-type(3):before { content: "Pokoje"; }
    .price-table td:nth-of-type(4):before { content: "Pow. m²"; }
    .price-table td:nth-of-type(5):before { content: "Balkon"; }
    .price-table td:nth-of-type(6):before { content: "Cena m²"; }
    .price-table td:nth-of-type(7):before { content: "Cena"; }
    .price-table td:nth-of-type(8):before { content: "Status"; }

}
