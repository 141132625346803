/*home page css*/

.home-slider-img {
    width: 100%;
}

.home-position-banner {
    left: 0;
    top: 80%;
    position: absolute;
    transform: translateY(-50%);
}

.home-desc-slider {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 24px;
}

.home-title-slider {
    font-family: Montserrat, sans-serif;
    font-size: 60px;
    font-weight: 700;
    color: #FFFFFF;
    letter-spacing: 6px;
}

.header-hr-left {
    border-left: 3px solid #CDA871;
}

.home-btn {
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: #CDA871;
    font-family: Montserrat, sans-serif;
    padding: 12px 30px;
    color: white;
    border: 2px solid #CDA871;
}

.home-btn-solid {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222829;
    font-family: Montserrat, sans-serif;
    padding: 12px 30px;
    color: white;
    border: 2px solid #222829;
}

.home-btn-slider {
    max-width: 180px;
    font-size: 20px;
    font-weight: 500;
}

.home-btn-readmore {
    max-width: 250px;
    font-size: 15px;
    font-weight: 500;
}

.home-btn:hover,
.home-btn-solid:hover {
    color: white !important;
    background-color: #ab7938;
}

.home-btn-solid:hover {
    border: 2px solid #CDA871;
}

.background-section-2 {
    background-repeat: no-repeat;
    background-position: left;
    background-position-x: -40rem;
}

.title-home {
    font-size: 44px;
    font-weight: 400;
    color: #222829;
    /*line-height: 54px;*/
    position: relative;
    border-left: 3px solid #C69C5D;
    padding-left: 20px
}

.title-home-34 {
    font-size: 34px;
}

.description-home {
    font-size: 15px;
    color: #7A7E7E;
    line-height: 24px;
    font-weight: 500;
}

.number-animations {
    font-size: 40px;
    font-weight: 700;
    color: #CDA871;
    line-height: 54px;
    text-align: center;
}

.number-animations-text {
    font-weight: 600;
    font-size: 18px;
    color: #596275;
    text-align: center;
    line-height: 26px;
}

.p-absolute-r-second-descr {
    position: absolute;
    right: 0;
    margin-right: 0;
    top: 50%;
    transform: translate(0%, -50%);
}

.p-absolute-l-second-descr {
    position: absolute;
    left: 0;
    margin-right: 0;
    top: 50%;
    transform: translate(0%, -50%);
}

.second-description-slide {
    max-width: 960px;
    max-height: 520px;
}

.second-description-slide .swiper-slide {
    max-width: 960px;
    max-height: 520px;
}

.second-description-slide-pagination .swiper-pagination-bullet {
    margin: 0 5px;
}

#slider-flats-garages .nav-tabs {
    border-bottom: 2px solid #DEDFDF;
    padding-bottom: 25px;
    z-index: 5;
}

#slider-flats-garages .nav-link-flats {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    color: #AFB2B2;
    margin: 0 80px 0 0;
    padding-bottom: 22px;
}

#slider-flats-garages .nav-link-flats.active {
    color: #C69C5D;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 2px solid #C69C5D;
    z-index: 6;
    transition: all 0s;
}

#slider-flats-garages .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: inherit;
    background-clip: border-box;
    border: 0;
    border-radius: inherit;
}

/*swiper flats and garages*/

/*with important for our project!*/
.swiper-button-next,
.swiper-button-prev {
    background-color: #CDA871;
    border: 2px solid #CDA871;
    color: white !important;
    width: 60px !important;
    height: 35px !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    background-color: #ab7938;
    transition: all .5s;
}

.swiper-button-next:focus,
.swiper-button-prev:focus {
    outline: none;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 14px !important;
}

.swiper-btn-prev,
.swiper-btn-next {
    top: auto !important;
}

.box-card-flat {
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.box-img-flat {
    max-width: 388px;
    max-height: 388px;
    object-fit: cover;
}

.box-maxsize-flat {
    max-width: 388px;
}

.box-header-flat {
    border-left: 2px solid #CDA871;
    padding-left: 10px;
    text-align: left;
}

.box-text-flat {
    font-weight: 500;
    font-size: 20px;
    color: #AFB2B2;
}

.box-text-position-flat {
    font-weight: 500;
    font-size: 26px;
    color: #222829;
    text-align: left;
}

.box-text-size-flat {
    font-weight: 700;
    font-size: 40px;
    color: #CDA871;
}

.flat-slider-pagination {
    margin: -18px 0 0 0;
}

.flat-slider-pagination .swiper-pagination-bullet {
    margin: 0 7px 0 0;
}


/*live here*/


.swiper-slide-live-here {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 400;
    color: #222829;
    height: calc((100% - 30px) / 2) !important;
}

.hr-live-here {
    background-color: #C69C5D;
    width: 60px;
    height: 3px;
}


.text-live-here {
    display: block;
    overflow-wrap: anywhere;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

/*media queries breakpoints*/
@media (min-width: 1301px) {

}

@media (max-width: 1300px) {

}

/*media*/
@media (min-width: 1201px) {
}

@media (max-width: 1200px) {

}

@media (min-width: 993px) {
    .d-flex-end {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .d-flex-center-lg {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-slide-live-here {
        max-width: 100%;
        max-height: 200px !important;
    }

    .limit-container-slider {
        max-height: 360px;
    }
}

@media (max-width: 992px) {
    #slider-flats-garages .nav-link-flats {
        font-size: 15px;
        margin: 0 20px 0 0;
        padding-bottom: 5px;
    }

    .home-btn-readmore {
        max-width: inherit !important;
    }

    #slider-flats-garages .nav-tabs {
        border-bottom: 0;
        padding-bottom: 0;
        z-index: 5;
    }

    .home-btn-slider {
        font-size: 13px;
    }

    .title-home:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -20px;
        right: 0;
        height: 100px;
        border-left: 0px solid #C69C5D;
        z-index: -1;
    }

    .home-desc-slider {
        font-size: 15px;
        text-align: center;
    }

    .home-title-slider {
        font-size: 30px;
        text-align: center;
    }

    .home-btn-slider {
        display: inline;
    }

    .title-home {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 20px;
        padding-left: 15px;
    }

    .description-home {
        font-size: 13px;
    }

    .number-animations {
        font-size: 25px;
    }

    .number-animations-text {
        font-size: 14px;
    }

    .p-absolute-r-second-descr {
        position: relative;
        right: inherit;
        top: inherit;
        transform: inherit;
    }

    .box-img-flat {
        max-width: 300px;
        max-height: 300px;
    }

    .box-maxsize-flat {
        max-width: 300px;
    }

    .box-text-flat {
        font-size: 15px;
    }

    .box-text-position-flat {
        font-size: 18px;
    }

    .box-text-size-flat {
        font-size: 25px;
    }
}

@media (min-width: 769px) {
}

@media (max-width: 768px) {

}

@media (min-width: 577px) {
}

@media (max-width: 576px) {
    .box-img-flat {
        max-width: 260px;
        max-height: 260px;
    }

    .box-maxsize-flat {
        max-width: 260px;
    }

    .home-title-slider {
        font-size: 20px;
    }

    #slider-flats-garages .nav-link-flats {
        font-size: 12px;
        margin: 12px 15px 0 0;
        padding-bottom: 6px;
        display: block;
    }
}