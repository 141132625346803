.area-full-flat{
    background-color: #eceff1;
}
.area-flat{
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 8px rgba(0,0,0,.1);
}

.area-flat-swiper .swiper-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.area-flat-swiper .swiper-slide {
    background-size: cover;
    background-position: center;;
}

.area-flat-swiper .gallery-top {
    width: 100%;
}

.area-flat-swiper .gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}

.area-flat-swiper .gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
}

.area-flat-swiper .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
}

@media (min-width: 993px) {


}

@media (max-width: 992px) {



}
