.map-navbar {
    padding: 10px;
    background: whitesmoke;
    box-shadow: 0 0 2px rgba(0, 0, 0, .4);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 992px) {
    .map-navbar {
        display: none;
    }
}