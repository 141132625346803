.embed {
    overflow: hidden;
    padding-top: 100%;
    position: relative;
}

.embed--1-2 {
    padding-top: calc(100% / (1 / 2));
}

.embed--2-1 {
    padding-top: calc(100% / (2 / 1));
}

.embed--2-3 {
    padding-top: calc(100% / (2 / 3));
}

.embed > * {
    height: 100%;
    left: 0;
    object-fit: cover;
    padding: 10px;
    position: absolute;
    top: 0;
    width: 100%;
    cursor: pointer;
}

/* Gallery  */

.gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
}


.gallery__item--h-2 {
    grid-column-end: span 2;
}

.gallery__item--h-3 {
    grid-column-end: span 3;
}

.gallery__item--v-2 {
    grid-row-end: span 2;
}

.gallery__item--v-3 {
    grid-row-end: span 3;
}

.showGallery {
    z-index: 999;
    display: none;
}

.showGallery .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .66);
    position: fixed;
    top: 0;
    left: 0;
}

.showGallery .img-show {
    width: 600px;
    height: 400px;
    background: #FFF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden
}

.showGallery .img-show img {
    width: 600px;
    height: 400px;
    object-fit: cover;
}

.img-show span {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;
    cursor: pointer;
    color: white;
    font-weight: 700;
}

.img-show img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.load-more-item {
    display: none;
}

.totop {
    position: fixed;
    bottom: 10px;
    right: 20px;
}

.totop a {
    display: none;
}

#loadMore {
    padding: 10px;
    text-align: center;
    background-color: #C69C5D !important;
    color: #fff;
    box-shadow: 0 1px 1px #ccc;
    transition: all 600ms ease-in-out;
    -webkit-transition: all 600ms ease-in-out;
    -moz-transition: all 600ms ease-in-out;
    -o-transition: all 600ms ease-in-out;
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    border: 0;
}

#loadMore:hover {
    background-color: #884408 !important;
    color: #fae1e1 !important;
}

.popup:hover {
    cursor: pointer;
    transform: scale(1.005);
}

.show .overlay:hover {
    cursor: alias;
    background-color: rgba(0, 0, 0, .70);
}

@media (min-width: 993px) {
    .showGallery .img-show {
        width: 600px;
        height: 400px;
        background: #FFF;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden
    }

    .showGallery .img-show img {
        width: 600px;
        height: 400px;
        object-fit: cover;
    }

}

@media (max-width: 992px) {
    .showGallery .img-show {
        width: 300px;
        height: 200px;
    }

    .showGallery .img-show img {
        width: 300px;
        height: 200px;
    }

    #loadMore {
        width: 100%;
    }
}

