/*footer*/

.box-footer {
    background: #222829;
    width: 100%;
    min-height: 200px;
    padding: 40px 0;
}

.text-footer {
    font-family: Montserrat, sans-serif;
    line-height: 25px;
}

.b-footer {
    font-weight: 600;
    font-size: 15px;
    color: white;
}

.p-footer {
    font-weight: 500;
    font-size: 15px;
    color: white;
}

.p-footer-created {
    font-weight: 500;
    font-size: 13px;
    color: #9DA2A2;
}

.hr-footer {
    background-color: #ffffff;
    opacity: 0.15;
    margin: 30px 0;
}

.nav-link-footer {
    font-family: Montserrat, sans-serif;
    color: #ffffff;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: inherit;
    margin: 0 20px;
}

.nav-link-footer.active {
    color: #C69C5D !important;
    font-weight: 700;
}

/*media queries breakpoints*/
@media (min-width: 1301px) {

}

@media (max-width: 1300px) {

}

@media (min-width: 1201px) {
}

@media (max-width: 1200px) {

}

@media (min-width: 993px) {
    .bl-footer {
        border-left: 2px solid rgb(198, 156, 93);
    }
}

@media (max-width: 992px) {
    .img-logo-footer {
        max-width: 150px;
    }

    .p-footer {
        font-size: 13px;
    }

    .b-footer {
        font-size: 13px;
    }

    .p-footer-created {
        font-size: 11px;
    }

    .nav-link-footer {
        font-size: 13px;
    }
}

@media (min-width: 769px) {
}

@media (max-width: 768px) {

}

@media (min-width: 577px) {
}

@media (max-width: 576px) {

}