/*navbar*/
.container-top {
    width: 1730px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 20px;
}

.navbar {
    position: fixed;
    width: 100%;
    background-color: #FFFFFF;
    z-index: 1000 !important;
    box-shadow: 0 1px 7px -2px grey;
}

.navbar a,
.navbar p {
    font-family: Montserrat, sans-serif;
    font-size: 13px;
    font-weight: 500 !important;
    color: #2D3436 !important;
    text-decoration: none !important;
}

.navbar a:hover {
    color: #C69C5D !important;
}

.nav-link-top {
    font-family: Montserrat, sans-serif;
    color: #2D3436;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: inherit;
    margin: 0 20px;
}
.nav-link-top.active{
    color: #C69C5D !important;
    font-weight: 700;
}

.nav-link-top:hover {
    color: black;
    font-weight: 700;
    background: inherit;
}

.box-contact-navbar {
    border-left: 2px solid rgba(198, 156, 93, 0.6);
    padding-left: 30px;
}

@media (min-width: 1301px) {
}

@media (max-width: 1300px) {
    /*navbar 1300px*/
    .nav-link-top {
        margin: 0 10px;
    }

    .img-svg-navbar {
        max-width: 15px;
    }
}

/*media*/
@media (min-width: 1201px) {
}

@media (max-width: 1200px) {
    /*navbar 1200px*/
    .nav-link-top {
        font-size: 13px !important;
    }

    .box-contact-navbar {
        padding-left: 15px;
    }
}

@media (min-width: 993px) {
    .navbar-static {
        height: 120px !important;
        transition: all .2s ease-in-out;
    }

    .navbar-fluid {
        height: 95px !important;
        transition: all .2s ease-in-out;
    }

    .img-logo-navbar {
        max-width: 200px;
        max-height: 100px;
    }
    .navbar-nav-list {
        overflow: auto;
    }
}


@media (max-width: 992px) {
    /*navbar 992px*/
    .container-top {
        display: inline-flex;
        justify-content: inherit;
        align-items: center;
    }

    .btn-navbar-collapse {
        background-color: white;
    }

    .btn-navbar-collapse:hover {
        transform: scale(1.05);
    }

    .navbar-collapse {
        position: absolute;
        top: 95%;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: white;
    }

    .navbar-nav-list {
        height: 100vh;
        padding: 10px 20px !important;
    }

    .navbar-static {
        height: 90px !important;
        transition: all .1s ease-in-out;
    }

    .navbar-fluid {
        height: 70px !important;
        transition: all .2s ease-in-out;
    }
    .img-logo-navbar {
        max-width: 80px;
    }
}

@media (min-width: 769px) {
}

@media (max-width: 768px) {

}

@media (min-width: 577px) {
}

@media (max-width: 576px) {

}