@import url(https://fonts.googleapis.com/css?family=Abril+Fatface);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:400,900,800,700,500,300,100,600);
@import url(https://fonts.googleapis.com/css?family=Droid+Serif:400,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,300,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,100,300,700);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,400italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:400,300,500,700);
@import url(https://fonts.googleapis.com/css?family=Oswald:400,300,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,300italic,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Kaushan+Script);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
@import url(https://fonts.googleapis.com/css?family=Tangerine:400,700);
@import url(https://fonts.googleapis.com/css?family=Tinos:400,400i,700,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,300,400,500,600,700,800,900&display=swap&subset=latin-ext);

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-family: 'Montserrat', sans-serif;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}

body * {
    -webkit-text-size-adjust: none;
    box-sizing: border-box;
}

input:focus, select:focus, textarea:focus, button:focus {
    outline: none;
}

input, textarea, select {
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 0px none;
}

select::-ms-expand {
    display: none;
}

a, a:link, a:visited, a:active, a:hover {
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

body {
    font-weight: normal;
    font-size: 100%;
    line-height: 1;
}

ul, ol {
    list-style: none;
}

b {
    font-weight: 700;
}

input::-webkit-input-placeholder, input::-moz-placeholder, input:-moz-placeholder, input:-ms-input-placeholder, textarea::-webkit-input-placeholder, textarea::-moz-placeholder, textarea:-moz-placeholder, textarea:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.9);
    opacity: 1;
}

* {
    box-sizing: border-box;
}

.preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    text-align: center;
    z-index: 6000;
}

.preloader:after {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
}

.sk-cube-grid {
    width: 3.857rem;
    height: 3.857rem;
    margin: 0 auto;
    vertical-align: middle;
    display: inline-block;
}

.sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #333;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

@keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

div[class*="empty-sp"] {
    width: 100%;
    clear: both;
}

@media (max-width: 544px) {
    .empty-sp-xs-0 {
        height: 0rem;
    }

    .empty-sp-xs-5 {
        height: 5px;
    }

    .empty-sp-xs-10 {
        height: 0.7143rem;
    }

    .empty-sp-xs-15 {
        height: 1.071rem;
    }

    .empty-sp-xs-20 {
        height: 1.429rem;
    }

    .empty-sp-xs-25 {
        height: 1.786rem;
    }

    .empty-sp-xs-30 {
        height: 2.143rem;
    }

    .empty-sp-xs-40 {
        height: 2.857rem;
    }

    .empty-sp-xs-50 {
        height: 3.571rem;
    }

    .empty-sp-xs-60 {
        height: 4.286rem;
    }

    .empty-sp-xs-65 {
        height: 4.643rem;
    }

    .empty-sp-xs-70 {
        height: 5rem;
    }

    .empty-sp-xs-75 {
        height: 5.357rem;
    }

    .empty-sp-xs-80 {
        height: 5.714rem;
    }

    .empty-sp-xs-90 {
        height: 6.429rem;
    }

    .empty-sp-xs-160 {
        height: 160px;
    }

    .empty-sp-xs-100 {
        height: 7.143rem;
    }

    .empty-sp-xs-200 {
        height: 14.29rem;
    }
}

@media (min-width: 544px) {
    .empty-sp-sm-0 {
        height: 0rem;
    }

    .empty-sp-sm-5 {
        height: 5px;
    }

    .empty-sp-sm-10 {
        height: 0.7143rem;
    }

    .empty-sp-sm-15 {
        height: 1.071rem;
    }

    .empty-sp-sm-20 {
        height: 1.429rem;
    }

    .empty-sp-sm-25 {
        height: 1.786rem;
    }

    .empty-sp-sm-30 {
        height: 2.143rem;
    }

    .empty-sp-sm-40 {
        height: 2.857rem;
    }

    .empty-sp-sm-50 {
        height: 3.571rem;
    }

    .empty-sp-sm-60 {
        height: 4.286rem;
    }

    .empty-sp-sm-65 {
        height: 4.643rem;
    }

    .empty-sp-sm-70 {
        height: 5rem;
    }

    .empty-sp-sm-75 {
        height: 5.357rem;
    }

    .empty-sp-sm-80 {
        height: 5.714rem;
    }

    .empty-sp-sm-90 {
        height: 6.429rem;
    }

    .empty-sp-sm-100 {
        height: 7.143rem;
    }

    .empty-sp-sm-200 {
        height: 14.29rem;
    }
}

@media (min-width: 768px) {
    .empty-sp-md-0 {
        height: 0rem;
    }

    .empty-sp-md-10 {
        height: 0.7143rem;
    }

    .empty-sp-md-15 {
        height: 1.071rem;
    }

    .empty-sp-md-20 {
        height: 1.429rem;
    }

    .empty-sp-md-25 {
        height: 1.786rem;
    }

    .empty-sp-md-30 {
        height: 2.143rem;
    }

    .empty-sp-md-40 {
        height: 2.857rem;
    }

    .empty-sp-md-50 {
        height: 3.571rem;
    }

    .empty-sp-md-60 {
        height: 4.286rem;
    }

    .empty-sp-md-65 {
        height: 4.643rem;
    }

    .empty-sp-md-70 {
        height: 5rem;
    }

    .empty-sp-md-75 {
        height: 5.357rem;
    }

    .empty-sp-md-80 {
        height: 5.714rem;
    }

    .empty-sp-md-90 {
        height: 6.429rem;
    }

    .empty-sp-md-100 {
        height: 7.143rem;
    }

    .empty-sp-md-120 {
        height: 120px;
    }

    .empty-sp-md-130 {
        height: 130px;
    }

    .empty-sp-md-150 {
        height: 150px;
    }

    .empty-sp-md-170 {
        height: 12.14rem;
    }

    .empty-sp-md-160 {
        height: 160px;
    }

    .empty-sp-md-200 {
        height: 14.29rem;
    }
}

@media (min-width: 992px) {
    .empty-sp-lg-0 {
        height: 0rem;
    }

    .empty-sp-lg-10 {
        height: 0.7143rem;
    }

    .empty-sp-lg-15 {
        height: 1.071rem;
    }

    .empty-sp-lg-20 {
        height: 1.429rem;
    }

    .empty-sp-lg-25 {
        height: 1.786rem;
    }

    .empty-sp-lg-30 {
        height: 2.143rem;
    }

    .empty-sp-lg-40 {
        height: 2.857rem;
    }

    .empty-sp-lg-50 {
        height: 3.571rem;
    }

    .empty-sp-lg-60 {
        height: 4.286rem;
    }

    .empty-sp-lg-65 {
        height: 4.643rem;
    }

    .empty-sp-lg-70 {
        height: 5rem;
    }

    .empty-sp-lg-75 {
        height: 5.357rem;
    }

    .empty-sp-lg-80 {
        height: 5.714rem;
    }

    .empty-sp-lg-90 {
        height: 6.429rem;
    }

    .empty-sp-lg-100 {
        height: 7.143rem;
    }

    .empty-sp-lg-120 {
        height: 120px;
    }

    .empty-sp-lg-130 {
        height: 130px;
    }

    .empty-sp-lg-150 {
        height: 150px;
    }

    .empty-sp-lg-160 {
        height: 160px;
    }

    .empty-sp-lg-190 {
        height: 190px;
    }

    .empty-sp-lg-200 {
        height: 14.29rem;
    }

    .empty-sp-lg-230 {
        height: 230px;
    }

    .empty-sp-lg-260 {
        height: 260px;
    }
}

@media (min-width: 1200px) {
    .empty-sp-lgx-0 {
        height: 0rem;
    }

    .empty-sp-lgx-10 {
        height: 0.7143rem;
    }

    .empty-sp-lgx-15 {
        height: 1.071rem;
    }

    .empty-sp-lgx-20 {
        height: 1.429rem;
    }

    .empty-sp-lgx-25 {
        height: 1.786rem;
    }

    .empty-sp-lgx-30 {
        height: 2.143rem;
    }

    .empty-sp-lgx-40 {
        height: 2.857rem;
    }

    .empty-sp-lgx-50 {
        height: 3.571rem;
    }

    .empty-sp-lgx-60 {
        height: 4.286rem;
    }

    .empty-sp-lgx-65 {
        height: 4.643rem;
    }

    .empty-sp-lgx-70 {
        height: 5rem;
    }

    .empty-sp-lgx-75 {
        height: 5.357rem;
    }

    .empty-sp-lgx-80 {
        height: 5.714rem;
    }

    .empty-sp-lgx-90 {
        height: 6.429rem;
    }

    .empty-sp-lgx-100 {
        height: 7.143rem;
    }

    .empty-sp-lgx-120 {
        height: 120px;
    }

    .empty-sp-lgx-130 {
        height: 130px;
    }

    .empty-sp-lgx-150 {
        height: 150px;
    }

    .empty-sp-lgx-160 {
        height: 160px;
    }

    .empty-sp-lgx-200 {
        height: 14.29rem;
    }
}

/*======FONTS======*/
/*======BOOTSTRAP RESET======*/
.navbar {
    border-radius: 0;
    padding: 0;
}

.btn {
    border: 0 none;
    background: none;
    padding: 0;
    border-radius: 0;
    margin: 0;
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: 0 none;
    outline-offset: 0;
}

.btn-primary:hover {
    color: inherit;
    background-color: transparent;
    border-color: transparent;
}

/*======MIXINS======*/
/*======HELP CLASSES======*/
.absolute, .bg, .hold-layer-1, .hold-layer-2, .portfolio-item-1 .layer, .blog-item-5 .layer, .preview-portfolio .layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.transition-def, a {
    transition: all 0.35s ease-out;
    -webkit-transition: all 0.35s ease-out;
    -ms-transition: all 0.35s ease-out;
    -moz-transition: all 0.35s ease-out;
}

a {
    color: inherit;
}

.bg {
    overflow: hidden;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-attachment: scroll;
    -ms-background-size: cover;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.bg.fix {
    background-attachment: fixed;
}

.bg.containe {
    -ms-background-size: contain;
    background-size: contain;
}

.vertical-align {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
}

.vertical-align.full {
    left: 0;
    width: 100%;
    text-align: center;
}

.table-view {
    display: table;
    width: 100%;
    height: 100%;
}

.cell-view {
    display: table-cell;
    vertical-align: middle;
    height: inherit;
}

.clear {
    clear: both;
    overflow: hidden;
    height: 0;
    font-size: 0;
    display: block;
}

.vertical-wrap {
    font-size: 0;
}

.vertical-wrap > div[class*="col"], .vertical-wrap > .align-item {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    float: none;
}

.wr-last-child > div:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
}

.resp-img {
    width: 100%;
    height: auto;
    display: block;
}

.img-full {
    max-width: 100%;
    height: auto;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

body.overflow {
    overflow: hidden;
}

.hold-layer-1 {
    background: rgba(0, 0, 0, 0.7);
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.hold-layer-2 {
    background: rgba(0, 0, 0, 0.45);
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.col-50 {
    width: 50%;
    position: relative;
    float: left;
}

.col-40 {
    width: 40%;
    position: relative;
    float: left;
}

.col-25 {
    width: 25%;
    position: relative;
    float: left;
}

.col-20 {
    width: 20%;
    position: relative;
    float: left;
}

.col-33 {
    width: 33.3333%;
    position: relative;
    float: left;
}

.col-66 {
    width: 66.666%;
    position: relative;
    float: left;
}

/*======VARIABLES======*/
.bg-section-1 {
    background: #111;
}

.bg-section-2 {
    background: #fbfbfb;
}

.bg-section-3 {
    background: #fef7ed;
}

.bg-section-4 {
    background: #fbfbfb;
}

/*======TYPOGRAPHY======*/
.font-1, .page-style-1 .caption-1, .page-style-1 .caption-2, .page-style-1 .caption-3, .page-style-1 .caption-4, .button-style-1, .header-style-1 .nav-item, .footer-style-1 .copyright, .model-style-play {
    font-family: 'Abril Fatface', cursive;
}

.font-2, .page-style-1, .page-style-3, .page-style-8, .page-style-12, .footer-style-2 {
    font-family: 'Open Sans', sans-serif;
}

.font-3, .page-style-2 .caption-1, .page-style-2 .caption-2, .page-style-2 .caption-3, .page-style-2 .caption-4, .page-style-2 .caption-5, .page-style-2 .caption-6, .page-style-2 .button-style-3, .page-style-4 .caption-1, .page-style-4 .caption-2, .page-style-4 .caption-3, .page-style-4 .caption-4, .page-style-4 .caption-5, .page-style-4 .caption-6, .button-style-1.font-3, .button-style-1.type-3, .button-style-1.type-4, .button-style-2, .footer-style-2 .copyright, .nav-footer li, .price-item-1.type-2 ul li b, .price-item-1.type-3 .price-val b, .header-style-2 .nav-item, .page-style-2 .header-style-2 .nav-item, .page-style-4 .button-style-1, .footer-style-3 .footer-twitt .time span, .footer-style-3 .copyright, .no-item, .mobile-filter-wrap.type-1 .change-text, .page-style-2 .button-style-1, .page-style-2 .button-style-4, .page-style-2 .header-style-1 .nav-item, .page-style-2 .mobile-filter-wrap.type-2 .change-text, .page-style-2 .footer-address li b, .color-style-2 .isotope-filter-list.type-1 li, .color-style-2 .mobile-filter-wrap.fix-line .change-text {
    font-family: 'Montserrat', sans-serif;
    /*font-family: 'Work Sans', sans-serif;*/
}

.font-4, .page-style-2, .page-style-6, .page-style-8 .caption-1, .page-style-8 .caption-2, .page-style-8 .caption-3, .page-style-8 .caption-4, .page-style-8 .caption-5, .page-style-8 .caption-6, .price-item-1.type-2 .price-val b, .page-style-2 .item-icon.type-4.info-type .icon b, .clock-item .val, .color-style-1 .header-style-2 .nav-link, .page-style-8 .footer-style-2 .copyright, .page-style-8 .footer-form .submit, .page-style-8 .header-style-2 .nav-link, .page-style-8 .nav-item .dropdown-menu li a {
    font-family: 'Droid Serif', serif;
}

.font-5, .page-style-3 .caption-1, .page-style-3 .caption-2, .page-style-3 .caption-3, .page-style-3 .caption-4, .page-style-3 .caption-5, .page-style-3 .caption-6, .page-style-13 .caption-1, .page-style-13 .caption-2, .page-style-13 .caption-3, .page-style-13 .caption-4, .page-style-13 .caption-5, .page-style-13 .caption-6, .button-style-1.type-5, .button-style-4, .page-style-3 .button-style-1, .page-style-3 .header-style-2 .nav-item, .page-style-3 .footer-style-2 .copyright, .page-style-3 .footer-form .submit, .top-header-info .lang-list .dropdown-menu li, .page-style-13 .button-style-1, .page-style-13 .header-style-2 .nav-link, .page-style-13 .header-style-2 .nav-item .dropdown-menu li a, .page-style-13 .footer-style-4 .copyright, .page-style-13 .footer-style-4 .submit {
    font-family: 'Merriweather', serif;
}

.font-6, .page-style-4, .page-style-7, .page-style-10, .mobile-filter-wrap.type-2 .change-text {
    font-family: 'Roboto Slab', serif;
}

.font-7, .page-style-5 .caption-1, .page-style-5 .caption-2, .page-style-5 .caption-3, .page-style-5 .caption-4, .page-style-5 .caption-5, .page-style-5 .caption-6, .price-item-2 .price-val b, .page-style-5 .header-style-2 .nav-item, .page-style-5 .button-style-1, .page-style-5 .footer-style-2 .copyright, .page-style-5 .footer-form .submit, .page-style-5 .item-icon .number {
    font-family: 'Playfair Display', serif;
}

.font-8, .page-style-5, .page-style-13 {
    font-family: 'Ubuntu', sans-serif;
}

.font-9, .page-style-6 .caption-1, .page-style-6 .caption-2, .page-style-6 .caption-3, .page-style-6 .caption-4, .page-style-6 .caption-5, .page-style-6 .caption-6, .page-style-9 .caption-1, .page-style-9 .caption-2, .page-style-9 .caption-3, .page-style-9 .caption-4, .page-style-9 .caption-5, .page-style-9 .caption-6, .page-style-10 .caption-1, .page-style-10 .caption-2, .page-style-10 .caption-3, .page-style-10 .caption-4, .page-style-10 .caption-5, .page-style-10 .caption-6, .page-style-12 .caption-1, .page-style-12 .caption-2, .page-style-12 .caption-3, .page-style-12 .caption-4, .page-style-12 .caption-5, .page-style-12 .caption-6, .page-style-6 .button-style-1.type-1, .page-style-9 .button-style-1.type-2, .page-style-9 .header-style-2 .nav-item, .page-style-9 .footer-style-2 .copyright, .page-style-9 .footer-form .submit, .page-style-10 .header-style-2 .nav-link, .page-style-10 .nav-item .dropdown-menu li a, .page-style-12 .header-style-2 .nav-item .nav-link, .page-style-12 .nav-item .dropdown-menu li, .page-style-12 .footer-style-2 .copyright, .page-style-12 .footer-form .submit {
    font-family: 'Roboto Condensed', sans-serif;
}

.font-10, .page-style-7 .caption-1, .page-style-7 .caption-2, .page-style-7 .caption-3, .page-style-7 .caption-4, .page-style-7 .caption-5, .page-style-7 .caption-6, .footer-form .submit, .page-style-6 .copyright, .page-style-6 .footer-form .submit, .page-style-6 .footer-style-4 .caption-6, .page-style-6 .footer-style-4 .caption-5, .page-style-6 .header-style-2 .nav-item, .page-style-6 .nav-item .dropdown-menu li, .color-style-6 .button-style-1, .page-style-7 .price-item-1.type-2 ul li b, .page-style-7 .header-style-2 .nav-link, .page-style-7 .nav-item .dropdown-menu li a, .page-style-7 .footer-style-2 .copyright, .page-style-7 .footer-form .submit, .page-style-7 .model-style-play {
    font-family: 'Oswald', sans-serif;
}

.font-11 {
    font-family: 'Kaushan Script', cursive;
}

.font-12, .page-style-9, .page-style-11, .page-style-9 .item-icon.type-4 b {
    font-family: 'Lato', sans-serif;
}

.font-13 {
    font-family: 'Tangerine', cursive;
}

.font-14, .page-style-11 .caption-1, .page-style-11 .caption-2, .page-style-11 .caption-3, .page-style-11 .caption-4, .page-style-11 .caption-5, .page-style-11 .caption-6, .page-style-11 .footer-form .submit, .page-style-11 .footer-style-4 .copyright, .page-style-11 .header-style-2 .nav-item {
    font-family: 'Tinos', serif;
}

.font-15, .page-style-11 .caption-1, .page-style-11 .caption-2, .page-style-11 .caption-3, .page-style-11 .caption-4, .page-style-11 .caption-5, .page-style-11 .caption-6, .page-style-11 .footer-form .submit, .page-style-11 .footer-style-4 .copyright, .page-style-11 .header-style-2 .nav-item {
    font-family: 'Montserrat', sans-serif;
}

html {
    font-size: 14px;
}

.caption-1.lg {
    font-size: 6.429rem;
    line-height: 6.857rem;
}

.caption-1 {
    font-size: 4.286rem;
    line-height: 4.571rem;
}

/*60px*/
.caption-2 {
    font-size: 3rem;
    line-height: 3.571rem;
}

/*42px*/
.caption-3 {
    font-size: 2.143rem;
    line-height: 2.571rem;
    text-transform: uppercase;
}

/*30px*/
.caption-4 {
    font-size: 1.429rem;
    line-height: 1.714rem;
    text-transform: uppercase;
}

/*20px*/
.caption-5 {
    font-size: 1rem;
    line-height: 1.429rem;
    text-transform: uppercase;
}

/*14px*/
.caption-5.lg {
    font-size: 1.071rem;
}

/*15px*/
.caption-6 {
    font-size: 0.8571rem;
    line-height: 18px;
    text-transform: uppercase;
}

/*12px*/
.caption-6.lg {
    font-size: 0.9286rem;
}

/*13px*/
.caption-1, .caption-2, .caption-3, .caption-4, .caption-5, .caption-6 {
    position: relative;
}

.caption-1.color-1, .caption-2.color-1, .caption-3.color-1, .caption-4.color-1, .caption-5.color-1, .caption-6.color-1 {
    color: #fff;
}

.italic.title {
    font-style: italic;
}

.color-3.form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.color-3.form-control:-moz-placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.color-3.form-control::-moz-placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.color-3.form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.color-3.form-control {
    color: #fff;
}

.page-style-1 {
    color: #222;
    /*60px*/
    /*42px*/
    /*20px*/
    /*14px*/
}

.page-style-1 .caption-1 {
    font-size: 4.286rem;
    line-height: 4.571rem;
}

.page-style-1 .caption-2 {
    font-size: 3rem;
    line-height: 3.571rem;
}

.page-style-1 .caption-3 {
    font-size: 1.429rem;
    line-height: 1.857rem;
    text-transform: uppercase;
}

.page-style-1 .caption-4 {
    font-size: 1rem;
    line-height: 1.429rem;
    text-transform: uppercase;
}

.page-style-2 {
    color: #222;
    /*60px*/
    /*42px*/
    /*30px*/
    /*20px*/
    /*14px*/
    /*12px*/
}

.page-style-2 .caption-1 {
    font-size: 4.286rem;
    line-height: 4.371rem;
}

.page-style-2 .caption-2 {
    font-size: 3rem;
    line-height: 3.571rem;
}

.page-style-2 .caption-2.sm {
    text-transform: uppercase;
    font-size: 2.143rem;
    text-align: center;
    line-height: 36px;
}

.page-style-2 .caption-3 {
    font-size: 1.429rem;
    line-height: 1.857rem;
    text-transform: uppercase;
}

.page-style-2 .caption-4 {
    font-size: 1rem;
    line-height: 1.429rem;
    text-transform: uppercase;
}

.page-style-2 .caption-5 {
    font-size: 0.8571rem;
    line-height: 1.143rem;
    text-transform: uppercase;
}

.page-style-2 .caption-1, .page-style-2 .caption-2, .page-style-2 .caption-3, .page-style-2 .caption-4, .page-style-2 .caption-5, .page-style-2 .caption-6 {
    font-weight: 900;
}

.page-style-2.caption-style .caption-1, .page-style-2.caption-style .caption-2, .page-style-2.caption-style .caption-3, .page-style-2.caption-style .caption-4, .page-style-2.caption-style .caption-5, .page-style-2.caption-style .caption-6 {
    text-transform: uppercase;
}

.page-style-3 {
    color: #a1a1a1;
}

.page-style-3 .caption-1, .page-style-3 .caption-2, .page-style-3 .caption-3, .page-style-3 .caption-4, .page-style-3 .caption-5, .page-style-3 .caption-6 {
    color: #111;
    font-weight: 900;
    text-transform: uppercase;
}

.page-style-3 .caption-1.color-1, .page-style-3 .caption-2.color-1, .page-style-3 .caption-3.color-1, .page-style-3 .caption-4.color-1, .page-style-3 .caption-5.color-1, .page-style-3 .caption-6.color-1 {
    color: #fff;
}

.page-style-4 {
    color: #a1a1a1;
}

.page-style-4 .caption-1, .page-style-4 .caption-2, .page-style-4 .caption-3, .page-style-4 .caption-4, .page-style-4 .caption-5, .page-style-4 .caption-6 {
    color: #222;
    font-weight: 900;
    text-transform: uppercase;
}

.page-style-4 .caption-1.color-1, .page-style-4 .caption-2.color-1, .page-style-4 .caption-3.color-1, .page-style-4 .caption-4.color-1, .page-style-4 .caption-5.color-1, .page-style-4 .caption-6.color-1 {
    color: #fff;
}

.page-style-4 .italic.title {
    text-transform: none;
    font-weight: 400;
    color: #989898;
}

.page-style-4.caption-style .caption-1, .page-style-4.caption-style .caption-2 {
    text-transform: none;
}

.page-style-5 {
    color: #989898;
}

.page-style-5 .caption-1, .page-style-5 .caption-2, .page-style-5 .caption-3, .page-style-5 .caption-4, .page-style-5 .caption-5, .page-style-5 .caption-6 {
    color: #222;
    font-weight: 900;
    text-transform: uppercase;
}

.page-style-5 .caption-1.color-1, .page-style-5 .caption-2.color-1, .page-style-5 .caption-3.color-1, .page-style-5 .caption-4.color-1, .page-style-5 .caption-5.color-1, .page-style-5 .caption-6.color-1 {
    color: #fff;
}

.page-style-6 {
    color: #989898;
}

.page-style-6 .caption-1, .page-style-6 .caption-2, .page-style-6 .caption-3, .page-style-6 .caption-4, .page-style-6 .caption-5, .page-style-6 .caption-6 {
    color: #222;
    font-weight: 700;
    text-transform: uppercase;
}

.page-style-6 .caption-1.color-1, .page-style-6 .caption-2.color-1, .page-style-6 .caption-3.color-1, .page-style-6 .caption-4.color-1, .page-style-6 .caption-5.color-1, .page-style-6 .caption-6.color-1 {
    color: #fff;
}

/*home page 32*/
.page-style-7 {
    color: #a1a1a1;
}

.page-style-7 .caption-1, .page-style-7 .caption-2, .page-style-7 .caption-3, .page-style-7 .caption-4, .page-style-7 .caption-5, .page-style-7 .caption-6 {
    color: #222;
    font-weight: 700;
    text-transform: none;
}

.page-style-7 .caption-1.color-1, .page-style-7 .caption-2.color-1, .page-style-7 .caption-3.color-1, .page-style-7 .caption-4.color-1, .page-style-7 .caption-5.color-1, .page-style-7 .caption-6.color-1 {
    color: #fff;
}

/*service page*/
.page-style-8 {
    color: #a1a1a1;
}

.page-style-8 .caption-1, .page-style-8 .caption-2, .page-style-8 .caption-3, .page-style-8 .caption-4, .page-style-8 .caption-5, .page-style-8 .caption-6 {
    color: #222;
    font-weight: 700;
    text-transform: none;
}

.page-style-8 .caption-1.color-1, .page-style-8 .caption-2.color-1, .page-style-8 .caption-3.color-1, .page-style-8 .caption-4.color-1, .page-style-8 .caption-5.color-1, .page-style-8 .caption-6.color-1 {
    color: #fff;
}

/*contact*/
.page-style-9 {
    color: #a1a1a1;
}

.page-style-9 .caption-1, .page-style-9 .caption-2, .page-style-9 .caption-3, .page-style-9 .caption-4, .page-style-9 .caption-5, .page-style-9 .caption-6 {
    color: #222;
    font-weight: 700;
    text-transform: uppercase;
}

.page-style-9 .caption-1.color-1, .page-style-9 .caption-2.color-1, .page-style-9 .caption-3.color-1, .page-style-9 .caption-4.color-1, .page-style-9 .caption-5.color-1, .page-style-9 .caption-6.color-1 {
    color: #fff;
}

.page-style-10 {
    color: #989898;
}

.page-style-10 .caption-1, .page-style-10 .caption-2, .page-style-10 .caption-3, .page-style-10 .caption-4, .page-style-10 .caption-5, .page-style-10 .caption-6 {
    color: #222;
    font-weight: 700;
    text-transform: uppercase;
}

.page-style-10 .caption-1.color-1, .page-style-10 .caption-2.color-1, .page-style-10 .caption-3.color-1, .page-style-10 .caption-4.color-1, .page-style-10 .caption-5.color-1, .page-style-10 .caption-6.color-1 {
    color: #fff;
}

.page-style-11 {
    color: #989898;
}

.page-style-11 .caption-1, .page-style-11 .caption-2, .page-style-11 .caption-3, .page-style-11 .caption-4, .page-style-11 .caption-5, .page-style-11 .caption-6 {
    color: #222;
    font-weight: 700;
    text-transform: uppercase;
}

.page-style-11 .caption-1.color-1, .page-style-11 .caption-2.color-1, .page-style-11 .caption-3.color-1, .page-style-11 .caption-4.color-1, .page-style-11 .caption-5.color-1, .page-style-11 .caption-6.color-1 {
    color: #fff;
}

.page-style-12 {
    color: #a1a1a1;
}

.page-style-12 .caption-1, .page-style-12 .caption-2, .page-style-12 .caption-3, .page-style-12 .caption-4, .page-style-12 .caption-5, .page-style-12 .caption-6 {
    color: #222;
    font-weight: 700;
    text-transform: uppercase;
}

.page-style-12 .caption-1.color-1, .page-style-12 .caption-2.color-1, .page-style-12 .caption-3.color-1, .page-style-12 .caption-4.color-1, .page-style-12 .caption-5.color-1, .page-style-12 .caption-6.color-1 {
    color: #fff;
}

.page-style-13 {
    color: #a1a1a1;
}

.page-style-13 .caption-1, .page-style-13 .caption-2, .page-style-13 .caption-3, .page-style-13 .caption-4, .page-style-13 .caption-5, .page-style-13 .caption-6 {
    color: #222;
    font-weight: 900;
    text-transform: uppercase;
}

.page-style-13 .caption-1.color-1, .page-style-13 .caption-2.color-1, .page-style-13 .caption-3.color-1, .page-style-13 .caption-4.color-1, .page-style-13 .caption-5.color-1, .page-style-13 .caption-6.color-1 {
    color: #fff;
}

.simple-text {
    position: relative;
    font-weight: 400;
    /*13px*/
    /*16px*/
}

.simple-text.size-1 p {
    font-size: 0.9286rem;
    line-height: 1.714rem;
}

.simple-text.size-2 p {
    font-size: 1.143rem;
    line-height: 1.714rem;
}

.simple-text.size-3 p {
    font-size: 14px;
    line-height: 24px;
}

.simple-text.color-1 p {
    color: rgba(255, 255, 255, 0.7);
}

.simple-text.color-2 p {
    color: #989898;
}

.simple-text.color-3 p {
    color: #a1a1a1;
}

.simple-text.color-4 p {
    color: rgba(119, 119, 119, 0.7);
}

.simple-text.color-5 p {
    color: rgba(85, 85, 85, 0.7);
}

.simple-text.italic p {
    font-style: italic;
}

.qoute-type {
    padding-left: 2.143rem;
    border-left: 1px solid #222;
}

.sub-title {
    font-size: 1rem;
    line-height: 1.571rem;
    color: #dbc496;
}

.sub-title.color-2 {
    color: rgba(255, 255, 255, 0.7);
}

.sub-title.lg {
    font-size: 16px;
    color: #989898;
}

.sub-title.lg.color-2 {
    color: rgba(255, 255, 255, 0.7);
}

.sub-title.italic {
    font-style: italic;
}

.color-1 {
    color: #fff;
}

.color-2 {
    color: #222;
}

.title-style-1 {
    text-align: center;
    position: relative;
}

.title-style-1 .simple-text {
    max-width: 46.43rem;
    margin: 0 auto;
}

.title-style-1.text-left {
    text-align: left;
}

.title-style-1.text-left .simple-text {
    margin: 0;
}

.title-style-1.boxed {
    max-width: 370px;
    margin: 0 auto;
}

.title-separator {
    position: relative;
}

.title-separator.white {
    background: rgba(255, 255, 255, 0.5);
}

.title-style-2 {
    position: relative;
    padding-left: 7.143rem;
}

.title-style-2 .title-separator {
    position: absolute;
    width: 6.429rem;
    left: 0;
    top: 0.5714rem;
    height: 1px;
    background: #dbc496;
}

.title-style-2 .title-separator:after {
    content: '';
    position: absolute;
    width: 0.3571rem;
    height: 0.3571rem;
    border-radius: 50%;
    background: inherit;
    top: -0.1429rem;
    right: 0;
}

.title-style-2 .simple-text {
    max-width: 46.43rem;
}

.title-style-3 {
    position: relative;
}

.title-style-3 .title-separator {
    width: 3.571rem;
    height: 2px;
    position: relative;
    display: block;
}

.title-style-3.type-1 .title-separator {
    margin-left: -1.429rem;
}

.title-style-3.color-1 .title-separator {
    background: #fff;
}

.title-style-3.color-2 .title-separator {
    background: #222;
}

.title-style-4 {
    text-align: left;
    position: relative;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.title-style-4 .title-separator {
    width: 3.571rem;
    height: 2px;
    margin-left: -1.429rem;
}

.title-style-4 .title-separator.lg {
    width: 70px;
    margin: 0;
    height: 1px;
}

.title-style-4.center {
    text-align: center;
    max-width: 560px;
    display: block;
    margin: 0 auto;
}

.title-style-4.center.border-style {
    max-width: none;
}

.title-style-4.center .title-separator {
    margin: 0 auto;
}

.title-style-4.center.wide {
    max-width: none;
}

.title-style-5 {
    position: relative;
}

.title-style-5 .title-separator {
    display: block;
    font-size: 1rem;
}

.title-style-5 .title-separator span {
    display: inline-block;
    height: 1px;
    width: 2.143rem;
    vertical-align: middle;
    position: relative;
}

.title-style-5 .title-separator span:before, .title-style-5 .title-separator span:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 3px;
    background: inherit;
    border-radius: 2px;
    top: -1px;
}

.title-style-5 .title-separator span.left-line:before {
    right: -8px;
}

.title-style-5 .title-separator span.left-line:after {
    right: -0.8571rem;
}

.title-style-5 .title-separator span.right-line:before {
    left: -8px;
}

.title-style-5 .title-separator span.right-line:after {
    left: -0.8571rem;
}

.title-style-5 .title-separator .left-line {
    margin-right: 25px;
}

.title-style-5 .title-separator .right-line {
    margin-left: 25px;
}

.title-style-5.color-1 .title {
    color: #fff;
}

.title-style-5.color-1 .title-separator {
    color: rgba(255, 255, 255, 0.7);
}

.title-style-5.color-1 .title-separator span {
    background: #fff;
}

.title-style-5.color-2 .title {
    color: #111;
}

.title-style-5.color-2 .title-separator {
    color: #989898;
}

.title-style-5.color-2 .title-separator span {
    background: #111;
}

.list-style-1 {
    position: relative;
}

.list-style-1 li {
    font-size: 0.9286rem;
    line-height: 1.143rem;
    color: #a1a1a1;
    padding-left: 2.143rem;
    padding-bottom: 1.429rem;
    position: relative;
}

.list-style-1 li .list-point {
    position: absolute;
    left: 0;
    top: 0.5rem;
    width: 0.7143rem;
    height: 1px;
    background: #dbc496;
}

.list-style-1.color-1 li {
    color: rgba(255, 255, 255, 0.7);
}

.list-style-1.color-1 li .list-point {
    background: rgba(255, 255, 255, 0.7);
}

.list-style-1.color-2 li {
    color: #8b9290;
}

.list-style-1.color-2 li .list-point {
    background: #222;
}

.list-style-1.color-2 li b {
    color: #222;
}

.list-img {
    position: relative;
}

.list-img i {
    padding-right: 1.071rem;
    font-size: 1.429rem;
    display: block;
    float: left;
}

.list-img .title {
    padding-left: 35px;
}

.list-img .text {
    padding-left: 2.143rem;
}

.list-img .text .title {
    padding-left: 0;
}

.list-img.type-1 i {
    text-align: left;
    padding-right: 0;
}

.list-img.type-2 i {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.list-img.type-2 b {
    display: inline-block;
    vertical-align: middle;
    font-weight: 700;
}

.list-img.type-2 .title {
    display: inline-block;
    vertical-align: middle;
    padding-left: 1.071rem;
}

.list-img.type-3 span, .list-img.type-3 a {
    font-size: 0.9286rem;
    text-transform: uppercase;
    color: #989898;
}

.list-img.type-3 {
    font-size: 0.9286rem;
    line-height: 1.429rem;
    color: #989898;
}

.list-img.type-3 li {
    padding-bottom: 1.071rem;
}

.list-img.type-3 li:last-child {
    padding-bottom: 0;
}

.fade-in {
    opacity: 0.07;
}

/*======COLOR PIKER======*/
.color-piker {
    position: fixed;
    left: -239px;
    top: 50%;
    margin-top: -60px;
    width: 239px;
    z-index: 1500;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.color-piker.active {
    left: 0;
}

.jscolor-popup {
    left: 0 !important;
    z-index: 2000 !important;
    margin-top: 10px;
}

.piker-open {
    position: absolute;
    right: -51px;
    top: 0px;
    width: 50px;
    height: 50px;
    background: #fff;
    line-height: 50px;
    color: #222;
    font-size: 20px;
    z-index: 5;
    box-shadow: 3px 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    cursor: pointer;
}

.color-wrapper {
    position: relative;
    width: 100%;
    background: #fff;
    text-align: center;
    box-shadow: 3px 0 10px rgba(0, 0, 0, 0.4);
    padding: 30px;
}

.color-wrapper .title {
    padding-bottom: 20px;
}

.color-wrapper .jscolor {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    font-size: 10px;
    color: #fff !important;
    text-align: center;
    opacity: 1;
    cursor: pointer;
}

/*======SWIPER STYLE======*/
.fullh-slider, .fullh-slider .swiper-wrapper, .fullh-slider .swiper-slide {
    height: inherit;
}

.pagination-padd {
    padding-bottom: 60px;
}

.pagination {
    position: absolute;
    text-align: center;
    margin: 0;
    bottom: 0;
    width: 100%;
    left: 0;
}

.pagination.hidden {
    display: none;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
    z-index: 50;
}

.swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1 .swiper-pagination-bullet {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0;
    background: #fff;
    margin: 0 0.3571rem;
    display: inline-block;
    opacity: 1;
}

.swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1 .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #dbc496;
    opacity: 1;
}

.swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.dark .swiper-pagination-bullet {
    background: #989898;
}

.swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.dark .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #dbc496;
}

.swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.dark2 .swiper-pagination-bullet {
    background: #848484;
}

.swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.dark2 .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #222;
}

.swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.white .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.5);
}

.swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.white .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #fff;
}

.swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.dark3 .swiper-pagination-bullet {
    background: #d2d2d2;
}

.swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.dark3 .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #dbc496;
}

.swiper-wrapper {
    cursor: url(../img/drag.png) 16 9, ew-resize !important;
}

.swiper-container-horizontal > .swiper-pagination-bullets.vertical-type {
    left: auto;
    right: 45px;
    bottom: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 5;
    width: auto;
}

.swiper-container-horizontal > .swiper-pagination-bullets.vertical-type .swiper-pagination-bullet {
    display: block;
    margin: 5px 0px;
    float: left;
    clear: both;
}

.swiper-container-horizontal > .swiper-pagination-bullets.vertical-type.center {
    left: 50%;
    right: auto;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.arrow-closest {
    position: relative;
}

.swipe-arrow {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    font-size: 2.571rem;
    color: #e4e4e4;
    width: 3.571rem;
    height: 3.571rem;
    line-height: 3.571rem;
    z-index: 50;
    cursor: pointer;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.swipe-arrow:hover {
    color: #222;
}

.swipe-arrow.col-1 {
    color: rgba(255, 255, 255, 0.5);
}

.swipe-arrow.col-1:hover {
    color: #fff;
}

.swipe-arrow.type-1 {
    width: 3.571rem;
    height: 3.571rem;
    line-height: 3.571rem;
    font-size: 1.429rem;
}

.swipe-arrow.col-2 {
    background: #f9f7f7;
    color: #989898;
}

.swipe-arrow.col-2:hover {
    background: #111;
    color: #fff;
}

.swipe-arrow.col-3 {
    background: #fff;
    color: #989898;
}

.swipe-arrow.col-3:hover {
    background: #111;
    color: #fff;
}

.swiper-arrow-left {
    left: 0;
}

.swiper-arrow-right {
    right: 0;
}

.offset-arrow .swiper-arrow-left {
    left: -4.643rem;
}

.offset-arrow .swiper-arrow-right {
    right: -4.643rem;
}

.offset-arrow-2 .swiper-arrow-left {
    left: -100px;
}

.offset-arrow-2 .swiper-arrow-right {
    right: -100px;
}

/*======BUTTON STYLE======*/
.elements {
    position: relative;
    padding: 11.429rem 0;
}

.elements .button-wrap .btn {
    margin: 0 2.143rem;
}

.elements .button-wrap {
    padding-bottom: 2.143rem;
}

.section-elem {
    position: relative;
    width: 100%;
    display: inline-block;
    padding: 7.143rem 0;
}

.button-style-1 {
    position: relative;
    display: inline-block;
    background: transparent;
    height: 3.571rem;
    line-height: 3.571rem;
    text-align: center;
    padding: 0 1.786rem;
    text-transform: uppercase;
    color: #222;
    background: #dbc496;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.button-style-1:hover {
    background: #111;
    color: #fff;
}

.button-style-1.sm {
    height: 40px;
    line-height: 40px;
    padding: 0px 1.071rem;
    font-size: 11px;
}

.button-style-1.type-2 {
    color: #fff;
    background: #111;
}

.button-style-1.type-2:hover {
    color: #222;
    background: #dbc496;
}

.button-style-1.submit.type-2 {
    background: #dbc496;
    color: #222;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.button-style-1.submit.type-2:hover {
    background: #222;
    color: #fff;
}

.button-style-1.submit.type-1 {
    background: #222;
    color: #fff;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.button-style-1.submit.type-1:hover {
    background: #ce9c68;
    color: #fff;
}

.button-style-1.font-3, .button-style-1.type-3, .button-style-1.type-4, .button-style-1.button-style-2, .footer-style-2 .button-style-1.copyright, .nav-footer li.button-style-1, .price-item-1.type-2 ul li b.button-style-1, .price-item-1.type-3 .price-val b.button-style-1, .header-style-2 .button-style-1.nav-item, .page-style-4 .button-style-1, .footer-style-3 .footer-twitt .time span.button-style-1, .footer-style-3 .button-style-1.copyright, .button-style-1.no-item, .mobile-filter-wrap.type-1 .button-style-1.change-text, .page-style-2 .button-style-1, .page-style-2 .header-style-1 .button-style-1.nav-item, .page-style-2 .mobile-filter-wrap.type-2 .button-style-1.change-text, .page-style-2 .footer-address li b.button-style-1, .color-style-2 .isotope-filter-list.type-1 li.button-style-1, .color-style-2 .mobile-filter-wrap.fix-line .button-style-1.change-text {
    font-weight: 900;
}

.button-style-1.type-3 {
    background: #fff;
    color: #222;
    font-weight: 900;
}

.button-style-1.type-3:hover {
    background: #222;
    color: #fff;
}

.button-style-1.type-4 {
    background: #dd4464;
    color: #fff;
    font-weight: 900;
}

.button-style-1.type-4:hover {
    background: #222;
    color: #fff;
}

.button-style-1.type-5 {
    background: #cd9129;
    color: #fff;
    font-size: 0.8571rem;
    font-weight: 900;
}

.button-style-1.type-5:hover {
    background: #111;
}

.button-style-1.col-hover-1:hover {
    background: #eafbf4;
}

.button-style-1.col-hover-2:hover {
    background: #222;
    color: #fff;
}

.button-style-1.icon i {
    padding-right: 10px;
    font-size: 18px;
    vertical-align: middle;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.button-style-2 {
    font-size: 0.8571rem;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    padding: 0 1.429rem;
    height: 3.571rem;
    line-height: 3.571rem;
    background: #222;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.button-style-2:hover {
    background: #8b7f20;
    color: #fff;
}

.button-style-2.col-hover-3:hover {
    background: #ce9c68;
}

.button-style-3 {
    position: relative;
    display: inline-block;
    padding: 0 1.429rem;
    height: 2.857rem;
    line-height: 2.857rem;
    font-size: 0.8571rem;
    text-transform: uppercase;
    color: #555;
    background-color: #fff;
    border: 0 none;
    outline: none;
    box-shadow: none;
    font-weight: 600;
    overflow: hidden;
}

.button-style-3:hover {
    color: #fff;
    background: #8b7f20;
}

.button-style-3.col-hover-3:hover {
    background: #ce9c68;
}

.button-style-4 {
    position: relative;
    display: inline-block;
    padding: 7px 5px;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 900;
    color: #fff;
    letter-spacing: 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.button-style-4:hover {
    background: none;
    border-color: #222;
    color: #222;
}

.tag-link {
    position: relative;
    display: inline-block;
    padding: 0 1.429rem;
    font-size: 0.8571rem;
    text-transform: uppercase;
    height: 2.714rem;
    line-height: 2.571rem;
    border-radius: 0;
    border: 1px solid #e3e3e3;
    color: #989898;
    perspective: 21.43rem;
    -webkit-perspective: 21.43rem;
}

.tag-link:hover, .tag-link.act {
    color: rgba(255, 255, 255, 0.7);
    border-color: #8b7f20;
    background: #8b7f20;
}

.tag-link.sm {
    height: 2.143rem;
    line-height: 2rem;
    text-transform: capitalize;
}

.tag-link.col-hover-3:hover, .tag-link.act.col-hover-3 {
    border-color: #ce9c68;
    background: #ce9c68;
}

.contact-form {
    text-align: center;
}

.box-style {
    max-width: 500px;
    margin: 0 auto;
}

.box-style.text-left {
    margin: 0;
}

.input-group.input-style-1 {
    width: 100%;
    height: 3.214rem;
    line-height: 3.214rem;
    border: 0 none;
}

.input-group.input-style-1.sm {
    height: 25px;
    line-height: 25px;
    padding-bottom: 5px;
}

.input-group.input-style-1:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 1px;
    height: 1px;
    width: 100%;
    background: #cfae77;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.input-group.input-style-1 .form-control {
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

.input-group.input-style-1.color-2 .form-control {
    color: #222;
}

.input-group.input-style-1.color-2.focus:before {
    background: #222;
}

.input-group.input-style-1.color-1.focus:before {
    background: #fff;
}

.input-group.input-style-1.focus .form-control {
    padding: 0 10px;
}

.input-group.input-style-1 .form-control {
    background: none;
    border-radius: 0;
    display: inline-block;
    width: 100%;
    padding: 0;
    height: inherit;
    border: 0 none;
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9286rem;
}

.input-group.input-style-1 .form-control:focus {
    border-color: transparent;
}

.input-group.input-style-1 textarea.form-control {
    height: 5rem;
    resize: none;
}

.input-group.input-style-2 .form-control {
    padding: 0 1.429rem;
    height: 3.571rem;
    border: 1px solid #e3e3e3;
    font-size: 0.9286rem;
    font-style: italic;
    border-radius: 0;
    -webkit-transition: all 0.35s ease-out 0s;
    transition: all 0.35s ease-out 0s;
}

.input-group.input-style-2.focus .form-control {
    border-color: #222;
}

.widget select {
    width: 100%;
    height: 45px;
    line-height: 45px;
    color: #111;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 1px solid #111;
    padding: 0px 1.071rem;
    background-size: 10px 5px;
    background-position: right 1.429rem top 50%;
    cursor: pointer;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url(../img/select_arr_1.png);
}

.widget select option {
    width: 100%;
    text-transform: uppercase;
    font-size: 0.8571rem;
    line-height: 2.143rem;
    padding: 8px 1.429rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    border: 0px none;
    background-color: #fff;
}

.mobile .bg.fix {
    background-attachment: scroll;
}

/*======HEADER======*/
.header .container-custome {
    height: inherit;
}

.header .navbar {
    height: inherit;
}

.nav-link, .nav-item {
    position: relative;
}

.header-style-1 {
    height: 8.214rem;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.header-style-1 .nav-item {
    font-size: 2.143rem;
    position: relative;
    display: block;
    color: #fff;
}

.header-style-1 .nav-list {
    position: fixed;
    left: 0;
    top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 4.286rem 0;
    background: #111;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    overflow-y: auto;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}

.header-style-1 .nav-list.in {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}

.header-style-1 .navbar-nav {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
}

.header-style-1 .navbar-nav .nav-item {
    float: none;
    margin: 0 0 1.071rem;
}

.header-style-1 .navbar-nav .nav-link {
    display: inline-block;
    padding: 0 1.429rem;
    height: 3.571rem;
    line-height: 3.571rem;
}

.header-style-1 .navbar-nav .nav-link:hover, .header-style-1 .navbar-nav .nav-link.active {
    background: #dbc496;
    color: #222;
}

.header-style-1.fix {
    background: #111;
    height: 4.286rem;
}

.header-style-1 .collapse {
    display: block !important;
    height: 100% !important;
}

.drop-link:hover > .dropdown-menu {
    visibility: visible;
    opacity: 1;
}

.nav-item .dropdown-menu {
    display: block;
    margin-top: 0;
    padding: 1.071rem 0px 0px;
    border-radius: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.nav-item .dropdown-menu li {
    font-size: 0.8571rem;
    line-height: 16px;
    padding: 0px 1.429rem 1.071rem;
    color: #989898;
    position: relative;
}

.nav-item .dropdown-menu li a {
    position: relative;
    display: inline-block;
}

.nav-item .dropdown-menu li:hover > a {
    color: #222;
}

.drop-link .icon {
    color: #fff;
    font-size: 1rem;
    position: relative;
    display: inline-block;
    line-height: inherit;
    padding-left: 10px;
    display: none;
}

.dropdown-menu .drop-link .icon {
    color: #111;
}

.nav-item .dropdown-menu .dropdown-menu {
    top: 0px;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    left: -1px;
}

.nav-item .dropdown-menu .dropdown-menu.left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    right: -1px;
    left: auto;
}

.mobile-menu {
    width: 2.143rem;
    height: 100%;
    position: relative;
    z-index: 50;
}

.mobile-menu i {
    position: absolute;
    left: 0;
    height: 2px;
    width: 100%;
    background: #fff;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.mobile-menu i:before {
    top: -0.5rem;
}

.mobile-menu i:after {
    top: 0.5rem;
}

.mobile-menu i:before, .mobile-menu i:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #fff;
    left: 0;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.mobile-menu.active i, .mobile-menu.col-style-1.active i {
    background: transparent !important;
}

.mobile-menu.active i:before {
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
    top: 0;
}

.mobile-menu.active i:after {
    -webkit-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
    top: 0;
}

.mobile-menu.col-style-1 i, .mobile-menu.col-style-1 i:after, .mobile-menu.col-style-1 i:before {
    background: #222;
}

/*======FOOTER======*/
.footer-style-1 {
    position: relative;
    width: 100%;
    padding: 3.214rem 0;
    background: #fff;
    display: inline-block;
}

.footer-style-1 .copyright {
    float: left;
    font-size: 0.9286rem;
    color: #989898;
    line-height: 1.4rem;
}

.footer-style-1 .copyright i {
    color: #ffa800;
}

.footer-style-1 .copyright a {
    color: #222;
}

.footer-style-1 .folow {
    float: right;
}

.folow {
    position: relative;
}

.folow a {
    display: inline-block;
}

.folow.type-1 a {
    padding: 0 0.7143rem;
    border-left: 1px solid #f1f1f1;
    font-size: 1rem;
    color: #b7b7b7;
}

.folow.type-1 a:first-child {
    border-left: 0 none;
}

.folow.type-1 a:hover {
    color: #222;
}

.folow.type-2 a {
    padding: 0 0.7143rem;
    color: #989898;
}

.folow.type-2 a:hover {
    color: #222;
}

.folow.col-hover-3 a:hover {
    color: #ce9c68;
}

.folow.type-3 a {
    font-size: 1.429rem;
    margin-right: 2.143rem;
    color: #989898;
    line-height: 1.429rem;
}

.folow.type-3 a:hover {
    color: #222;
}

.folow.type-4 a {
    font-size: 16px;
    margin-right: 25px;
    color: #222;
    line-height: 16px;
}

.folow.type-4 a:hover {
    color: #989898;
}

.footer-style-2 {
    position: relative;
}

.footer-style-2 .folow.type-1 a:hover {
    color: #fff;
}

.footer-style-2 .footer-item .title {
    color: #fff;
}

.footer-style-2 .recent-mini-link a {
    color: #fff;
}

.footer-instagram {
    margin-left: -10px;
    margin-right: -10px;
}

.footer-instagram a {
    display: block;
    float: left;
    width: 33.333%;
    padding: 0px 10px 1.429rem;
}

.footer-instagram a img {
    width: 100%;
    height: auto;
}

.footer-instagram a:hover {
    opacity: 0.5;
}

.footer-style-2 {
    position: relative;
    width: 100%;
    background: #222;
    color: rgba(255, 255, 255, 0.7);
}

.footer-style-2 .copyright {
    text-align: center;
    font-size: 0.9286rem;
    font-weight: 900;
    line-height: 1.4rem;
    color: rgba(255, 255, 255, 0.7);
}

.footer-style-2 .copyright a {
    color: #fff;
}

.footer-style-2 .copyright i {
    color: #8f6623;
}

.footer-address li {
    font-size: 0.9286rem;
    line-height: 1.571rem;
    padding-bottom: 1.143rem;
    color: rgba(255, 255, 255, 0.7);
}

.footer-address li:hover a {
    color: #fff;
}

.recent-mini-link {
    position: relative;
    padding-bottom: 1.429rem;
}

.recent-mini-link .caption-4, .recent-mini-link .caption-6 {
    text-transform: none;
    display: block;
    padding-bottom: 0.3571rem;
}

.recent-mini-link .caption-4:hover, .recent-mini-link .caption-6:hover {
    color: rgba(255, 255, 255, 0.5);
}

.recent-mini-link span {
    font-size: 0.7857rem;
    color: rgba(255, 255, 255, 0.7);
}

.recent-mini-link span i {
    font-style: italic;
}

.footer-link li {
    font-size: 0.9286rem;
    padding-bottom: 1.071rem;
    color: rgba(255, 255, 255, 0.7);
}

.footer-link li:hover a {
    color: #fff;
}

.footer-twitt {
    padding-bottom: 1.071rem;
}

.footer-twitt .time {
    font-size: 0.7143rem;
    padding-bottom: 0.7143rem;
    color: rgba(255, 255, 255, 0.7);
}

.footer-twitt .time i {
    color: #1cb7eb;
    font-size: 0.8571rem;
    padding-right: 0.7143rem;
}

.footer-twitt p {
    font-size: 0.7857rem;
    line-height: 1.429rem;
    color: rgba(255, 255, 255, 0.7);
}

.footer-twitt p a {
    color: #fff;
}

.footer-twitt p a:hover {
    color: #1cb7eb;
}

.footer-item .folow.type-1 a:first-child {
    padding-left: 0px;
}

.logo-footer {
    position: relative;
    float: left;
}

.logo-footer img {
    margin-right: 0.7143rem;
}

.logo-footer span {
    font-size: 0.9286rem;
    line-height: 0.9286rem;
}

.logo-footer.center {
    float: none;
    text-align: center;
    display: block;
    width: 100%;
}

.logo-footer.center a {
    display: inline-block;
}

.logo-footer.center img {
    margin: 0;
}

.nav-footer {
    position: relative;
    float: right;
}

.nav-footer li {
    float: left;
    margin: 0 0 0 1.786rem;
    font-weight: 900;
    color: #a6afb6;
    text-transform: uppercase;
    line-height: 1.714rem;
}

.nav-footer li a:hover {
    color: #fff;
}

.footer-form {
    position: relative;
    width: 100%;
}

.footer-form .submit {
    background: #fff;
    height: 40px;
    line-height: 38px;
    border: 1px solid #fff;
    padding: 0px 1.429rem;
    text-transform: uppercase;
    color: #222;
    font-weight: 700;
    -webkit-transition: all 0.45s ease-out;
    transition: all 0.45s ease-out;
}

.footer-form .submit:hover {
    background: #222;
    color: #fff;
}

/*======TEAM ITEM======*/
.sub-caption {
    font-size: 13px;
}

.sub-caption i {
    font-style: italic;
}

.team-item-1 {
    position: relative;
    width: 100%;
    height: 350px;
}

.team-item-1 .image {
    position: relative;
    width: 60%;
    height: inherit;
    z-index: 1;
    position: absolute;
    left: 0px;
    bottom: 0px;
    max-width: 100%;
}

.team-item-1 .desc {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background: #fff;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.team-item-1 .text {
    position: relative;
    padding: 0px 30px 0px 0px;
    width: 50%;
    float: right;
    text-align: right;
}

.team-item-1 .share-link.type-2 a {
    display: block;
    margin: 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.team-item-1 .image img {
    display: block;
    vertical-align: bottom;
}

.team-item-2 {
    position: relative;
    width: 100%;
}

.team-item-2 .image {
    max-width: 400px;
    width: 50%;
    padding: 30px;
    border: 3px solid #fff;
}

.team-item-2 .desc {
    width: 50%;
    padding-left: 30px;
}

.team-item-2 .title .sub-caption {
    color: rgba(255, 255, 255, 0.7);
}

.team-item-2 .share-link.type-2 {
    float: left;
}

.team-item-2 .share-link.type-2 a {
    margin: 0px 10px 0px 0px;
}

.team-item-3 {
    position: relative;
    text-align: center;
}

.team-item-3 .share-link {
    float: none;
}

.team-item-3 .image {
    border-bottom: 1px solid #000;
}

.team-item-3 .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}

.team-item-3 .title {
    position: relative;
}

.team-item-3 .title .sepp {
    position: absolute;
    left: 50%;
    top: -50px;
    height: 30px;
    background: #000;
    width: 1px;
}

.team-item-3 .desc {
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    padding-top: 80px;
}

.team-item-3 .desc .link {
    display: block;
    color: rgba(85, 85, 85, 0.7);
    font-size: 13px;
}

.team-item-3 .share-link a {
    margin: 0 5px;
}

.team-item-3 .hide-link {
    opacity: 0;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.team-item-3:hover .desc {
    background: #eafbf4;
}

@media (min-width: 992px) {
    .team-item-3:hover .hide-link {
        opacity: 1;
    }

    .team-item-3:hover .desc {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        padding-top: 30px;
    }
}

.team-item-4 {
    position: relative;
    width: 100%;
    border: 1px solid #222;
}

.team-item-4 .image {
    width: 50%;
    position: absolute;
    left: 0px;
    bottom: -160px;
}

.team-item-4 .image img {
    max-width: 100%;
    display: block;
}

.team-item-4 .desc {
    float: right;
    width: 50%;
    padding-right: 40px;
    max-width: 400px;
}

.team-item-4 .share-link {
    float: left;
}

.team-item-4 .share-link a {
    margin: 0px 10px 0px 0px;
}

.team-item-4 .sub-caption {
    color: #989898;
}

.team-item-4 .link-cont a:hover {
    color: #222;
}

.share-link.type-2 a {
    border-radius: 0px;
    width: 30px;
    height: 30px;
    border: 0px none;
    line-height: 30px;
}

.share-link.type-color-1 a {
    background: #cd9129;
    color: rgba(255, 255, 255, 0.7);
}

.share-link.type-color-1 a:hover {
    background: #fff;
}

.share-link.type-color-2 a {
    background: #fff;
    color: #989898;
}

.share-link.type-color-2 a:hover {
    background: #111;
}

.share-link.type-color-2 a i:after {
    color: #fff;
}

.share-link.type-color-3 a {
    background: #222;
    color: #fff;
}

.share-link.type-color-3 a:hover {
    background: #fff;
}

.share-link.type-color-4 a {
    background: #989898;
    color: #fff;
}

.share-link.type-color-4 a:hover {
    background: #fff;
}

.link-cont {
    font-size: 13px;
}

.link-cont a {
    color: #cdd1d5;
}

.link-cont a:hover {
    color: #fff;
}

/*======SERVICE ITEM======*/
.service-item-1 {
    position: relative;
    width: 100%;
    min-height: 400px;
    text-align: center;
}

.service-item-1 .simple-text {
    max-width: 260px;
    margin: 0 auto;
    display: block;
}

.service-item-2 {
    position: relative;
    width: 100%;
    text-align: center;
    background: #fff;
}

.service-item-2 .image {
    position: relative;
    width: 100%;
    min-height: 200px;
}

.service-item-2 .image .icon {
    color: #fff;
    font-size: 24px;
}

.service-item-2 .simple-text {
    max-width: 260px;
    margin: 0 auto;
    display: block;
}

.service-item-2:hover .hold-layer-2 {
    background: rgba(0, 0, 0, 0.8);
}

.service-item-3 {
    position: relative;
    width: 100%;
    background: #222;
    border: 1px solid rgba(255, 255, 255, 0.1);
    min-height: 270px;
    text-align: center;
}

.service-item-3 .sub-caption {
    font-style: italic;
    color: rgba(255, 255, 255, 0.5);
}

.service-item-3 .icon {
    color: #ffbc65;
    font-size: 16px;
}

.service-item-3 .simple-text {
    max-width: 260px;
    margin: 0 auto;
    display: block;
}

/*======PRICE ITEM======*/
.price-item-1 {
    position: relative;
    width: 100%;
    z-index: 1;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.price-item-1.type-1 {
    border: 3px solid #fff;
    text-align: center;
}

.price-item-1 .title {
    position: relative;
    color: #fff;
    text-transform: uppercase;
}

.price-item-1.type-1 .title {
    padding-top: 20px;
}

.price-item-1.type-1 .title:before {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -15px;
    top: 0px;
    width: 30px;
    height: 1px;
    background: rgba(255, 255, 255, 0.7);
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.price-item-1 .sub-caption {
    color: rgba(255, 255, 255, 0.7);
}

.price-item-1 .price-val span {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.7);
    vertical-align: bottom;
    padding-right: 10px;
    line-height: 16px;
}

.price-item-1 .price-val b {
    color: #fff;
    font-size: 42px;
    line-height: 42px;
}

.price-item-1 .price-val sub {
    font-size: 30px;
    color: #fff;
    display: inline-block;
    vertical-align: top;
    line-height: 20px;
}

.price-item-1 ul {
    text-align: left;
    max-width: 230px;
    display: block;
    margin: 0 auto;
}

.price-item-1 * {
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.price-item-1.type-1:hover {
    background: #fff;
}

.price-item-1.type-1:hover .title, .price-item-1.type-1:hover .price-val b, .price-item-1.type-1:hover .price-val sub {
    color: #222;
}

.price-item-1.type-1:hover .title:before {
    background: #989898;
}

.price-item-1.type-1:hover .list-style-1.color-1 li .list-point {
    background: #222;
}

.price-item-1.type-1:hover .sub-caption, .price-item-1.type-1:hover .price-val span, .price-item-1.type-1:hover ul li {
    color: #989898;
}

.price-item-1.type-1 .button-style-1:hover {
    background: #222 !important;
}

.price-item-1.type-2 {
    text-align: left;
    background: #222;
}

.price-item-1.type-2 .price-header {
    padding: 0px 55px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.price-item-1.type-2 .title {
    color: #ffbc65;
}

.price-item-1.type-2 ul li b {
    text-transform: uppercase;
    font-weight: 900;
    color: #fff;
}

.price-item-1.type-2 .price-val b {
    font-weight: 400;
    color: #ffbc65;
}

.price-item-1.type-2 .price-val sub {
    color: #ffbc65;
}

.price-item-1.type-2 .price-list {
    padding: 0px 55px;
}

.price-item-1.type-2 .price-val span {
    padding-left: 10px;
}

.price-item-1.type-2:hover {
    background: #fff;
}

.price-item-1.type-2:hover .title, .price-item-1.type-2:hover .price-val b, .price-item-1.type-2:hover .price-val sub, .price-item-1.type-2:hover ul li b {
    color: #222;
}

.price-item-1.type-2:hover .list-style-1.color-1 li .list-point {
    background: #222;
}

.price-item-1.type-2:hover .sub-caption, .price-item-1.type-2:hover .price-val span, .price-item-1.type-2:hover ul li {
    color: #989898;
}

.price-item-1.type-2:hover .price-header {
    border-color: #e3e3e3;
}

.price-item-1.type-2 .button-style-1:hover {
    background: #222 !important;
}

.price-item-1.type-3 {
    background: #fff;
    text-align: center;
}

.price-item-1.type-3 .sub-caption, .price-item-1.type-3 .price-val span {
    color: #989898;
}

.price-item-1.type-3 .price-header {
    border-bottom: 1px solid #e3e3e3;
}

.price-item-1.type-3 .price-list {
    border-bottom: 1px solid #e3e3e3;
}

.price-item-1.type-3 .price-val {
    display: inline-block;
}

.price-item-1.type-3 .price-val b {
    color: #222;
    display: inline-block;
}

.price-item-1.type-3 .price-val sub {
    color: #222;
}

.price-item-1.type-3 .price-val span {
    line-height: 20px;
}

.price-item-1.type-3 .title {
    color: #222;
}

.price-item-1.type-3 .list-style-1 {
    text-align: center;
}

.price-item-1.type-3 .list-style-1 li {
    padding-left: 0px;
}

.price-item-1.type-3 ul li b {
    color: #222;
}

.price-item-1.type-3 .simple-text {
    display: block;
    margin: 0 auto;
    max-width: 260px;
}

.price-item-1.type-3:hover {
    background: #222;
}

.price-item-1.type-3:hover .title, .price-item-1.type-3:hover .price-val b, .price-item-1.type-3:hover .price-val sub, .price-item-1.type-3:hover ul li b {
    color: #fff;
}

.price-item-1.type-3:hover .simple-text.color-2 p {
    color: rgba(255, 255, 255, 0.7);
}

.price-item-1.type-3:hover .sub-caption, .price-item-1.type-3:hover .price-val span, .price-item-1.type-3:hover .list-style-1 li {
    color: rgba(255, 255, 255, 0.7);
}

.price-item-2 {
    position: relative;
    width: 100%;
    padding: 0px 90px;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.price-item-2 * {
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.price-item-2.type-1 {
    background: #fff;
}

.price-item-2 .price-val {
    display: inline-block;
    float: left;
}

.price-item-2 .price-val span {
    font-size: 13px;
    color: #989898;
    vertical-align: bottom;
    padding-left: 10px;
    line-height: 16px;
}

.price-item-2 .price-val b {
    color: #222;
    font-size: 42px;
    line-height: 42px;
}

.price-item-2 .price-val sub {
    font-size: 30px;
    color: #222;
    display: inline-block;
    vertical-align: top;
    line-height: 20px;
}

.price-item-2 .button-style-1 {
    display: inline-block;
    float: right;
}

.price-item-2.type-2 {
    background: #fff;
}

.price-item-2.type-2:hover, .price-item-2.type-1:hover {
    background: #222;
}

.price-item-2.type-1:hover .simple-text.color-5 p {
    color: #a1a1a1;
}

.price-item-2:hover .price-val span, .price-item-2:hover .simple-text.color-3 p, .price-item-2:hover .list-style-1.color-2 li {
    color: rgba(255, 255, 255, 0.7);
}

.price-item-2:hover .price-val b, .price-item-2:hover .price-val sub, .price-item-2:hover .title, .price-item-2:hover .list-style-1.color-2 li b {
    color: #fff;
}

.price-item-2:hover .list-style-1.color-2 li .list-point {
    background: rgba(255, 255, 255, 0.7);
}

.price-item-2:hover .pr-sep {
    background: #fff;
}

.pr-sep {
    position: relative;
    width: 100px;
    height: 1px;
    display: block;
    background: #222;
    clear: both;
    float: left;
}

/*======PORTFOLIO ITEM======*/
.portfolio-item-1 {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.portfolio-item-1 .image {
    position: relative;
    width: 100%;
}

.portfolio-item-1 .layer {
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    opacity: 0;
}

.portfolio-item-1 .layer .l-border {
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    opacity: 0;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.portfolio-item-1 .layer .l-border i {
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -25px 0px 0px -25px;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.portfolio-item-1 .layer .l-border i:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 50%;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.5);
}

.portfolio-item-1 .layer .l-border i:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0%;
    width: 1px;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
}

.portfolio-item-1.type-1 .layer .l-border {
    left: 80px;
    top: 80px;
    right: 80px;
    bottom: 80px;
}

.portfolio-item-1.type-1 a.image {
    display: block;
    overflow: hidden;
}

.portfolio-item-1.type-1 .sub-title {
    color: #989898;
}

.portfolio-item-1.type-2 .layer .l-border {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
}

.portfolio-item-1.type-2 .layer {
    background: rgba(0, 0, 0, 0.8);
}

.portfolio-item-1.type-2 .sub-caption {
    color: rgba(255, 255, 255, 0.7);
}

.portfolio-item-1.type-2 .desc {
    opacity: 0;
    margin-top: 10px;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.portfolio-item-1:hover .layer {
    opacity: 1;
}

.portfolio-item-1.type-1:hover .layer .l-border {
    left: 30px;
    top: 30px;
    right: 30px;
    bottom: 30px;
    opacity: 1;
}

.portfolio-item-1.type-1:hover .layer .l-border i {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.portfolio-item-1.type-2:hover .layer .l-border {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
    left: 30px;
    top: 30px;
    right: 30px;
    bottom: 30px;
}

.portfolio-item-1.type-2:hover .desc {
    opacity: 1;
    margin-top: 0px;
}

.portfolio-item-2 {
    position: relative;
    width: 100%;
}

.portfolio-item-2 .image {
    position: relative;
    width: 100%;
    box-shadow: 0px 3px 10px rgba(13, 15, 19, 0.15);
    min-height: 550px;
}

.portfolio-item-2 .left-desc {
    position: relative;
    float: left;
}

.portfolio-item-2 .right-desc {
    position: relative;
    float: right;
    text-align: right;
}

.portfolio-item-2 .right-desc .title span {
    display: inline-block;
    color: #989898;
    font-size: 13px;
}

.portfolio-item-2 .right-desc .title .caption-6 {
    display: inline-block;
    padding-left: 5px;
}

.portfolio-item-2 .sub-caption {
    color: #989898;
}

/*======BLOG ITEM======*/
.blog-item-2 {
    position: relative;
    width: 100%;
    min-height: 445px;
}

.blog-item-2 .top-desc {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    padding: 30px;
}

.blog-item-2 .top-desc .info-icon span {
    padding-left: 0px;
}

.blog-item-2 .bottom-desc {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    padding: 30px;
}

.blog-item-2 .title a:hover {
    color: rgba(255, 255, 255, 0.5);
}

.blog-item-2 .center-desc {
    padding: 0px 15px;
}

.blog-item-2:hover .hold-layer-2 {
    background: rgba(0, 0, 0, 0.8);
}

.info-icon {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    margin-right: 25px;
}

.info-icon span {
    padding: 0px 5px;
}

.info-icon:hover, .info-icon:active, .info-icon:focus {
    color: #fff;
}

.on-white .info-icon {
    color: #a1a1a1;
}

.on-white .info-icon:hover, .on-white .info-icon:active, .on-white .info-icon:focus {
    color: #222;
}

.blog-item-3 {
    position: relative;
    width: 100%;
    padding: 70px 100px;
}

.blog-item-3 .desc {
    border: 3px solid #fff;
    position: relative;
    height: 100%;
    padding: 0px 65px;
}

.blog-item-3 .title-style-3 {
    max-width: 380px;
}

.blog-item-3:hover .hold-layer-2 {
    background: rgba(0, 0, 0, 0.8);
}

.blog-item-3 .title a:hover {
    color: rgba(255, 255, 255, 0.5);
}

.blog-item-4 {
    position: relative;
    width: 100%;
    z-index: 1;
}

.blog-item-4 .image {
    position: relative;
    margin-left: 70px;
    z-index: 5;
    overflow: hidden;
}

.blog-item-4 .date {
    position: absolute;
    width: 170px;
    padding: 55px 0px 30px;
    text-align: center;
    border: 1px solid #111;
    margin-top: -30px;
    left: 0px;
    top: 0px;
}

.blog-item-4 .date span {
    display: block;
    font-size: 20px;
    line-height: 30px;
    color: #fff;
}

.blog-item-4 .desc {
    position: relative;
}

.blog-item-4 .text {
    padding-left: 200px;
    position: relative;
    width: 100%;
}

.blog-item-4 .title a:hover {
    color: rgba(255, 255, 255, 0.7);
}

.left-desc {
    float: left;
}

.right-desc {
    float: right;
}

.blog-item-5 {
    position: relative;
    width: 100%;
    text-align: center;
}

.blog-item-5 .image {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.blog-item-5 .layer {
    background: rgba(255, 255, 255, 0.8);
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    opacity: 0;
}

.blog-item-5 .image:hover .layer {
    opacity: 1;
}

.blog-item-5 .title a:hover {
    color: #eafbf4;
}

.map-item {
    position: relative;
    width: 100%;
    height: 670px;
}

.sub-title-2 {
    font-size: 1.143rem;
    line-height: 1.286rem;
    color: #8b7f20;
    font-style: italic;
}

.sub-title-post {
    width: 100%;
    text-align: center;
    line-height: 1.714rem;
    text-align: left;
}

.sub-title-post .link {
    color: #ce9c68;
    font-size: 0.9286rem;
    font-style: italic;
    position: relative;
    display: inline-block;
    margin: 0 1.786rem;
}

.sub-title-post .like.link {
    font-style: normal;
}

.sub-title-post .like.link .info-icon {
    padding: 0 0.5rem;
    color: inherit;
}

/*======HEADER STYLE 2======*/
.header-style-2 {
    height: 8.214rem;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

.header-style-2 .nav {
    padding: 2.143rem 0;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

.header-style-2 .nav-item {
    font-size: 0.8571rem;
    font-weight: 900;
    text-transform: uppercase;
    height: 3.929rem;
    line-height: 3.929rem;
    /*margin: 0 0.5rem;*/
}

.header-style-2 .nav-link {
    padding: 0 0.5rem;
    color: #989898;
    perspective: 21.43rem;
    -webkit-perspective: 21.43rem;
}

.header-style-2 .mobile-menu {
    display: none;
}

.header-style-2 .nav-link.active, .header-style-2 .nav-link:hover {
    color: #fff;
    background: #8b7f20;
}

.header-style-2 .nav-link.col-hover-3.active, .header-style-2 .nav-link.col-hover-3:hover {
    background: #ce9c68;
}

.header-style-2.fix {
    background: #fff;
    height: 5rem;
}

.header-style-2.fix .nav {
    padding: 0.4286rem 0;
}

.header-style-2.bg-link-color-2 .nav-link:before {
    background: #ce9c68;
}

/*======BLOG ITEM STYLE======*/
.color-style-13 .header-style-2 .nav-link.active, .color-style-13 .header-style-2 .nav-link:hover, .color-style-13 .post-tags .tag-link:hover, .color-style-13 .button-style-1.submit.type-1:hover, .color-style-13 .button-style-2:hover, .color-style-13 .tag-link:hover, .color-style-13 .tag-link.act, .color-style-13 .button-style-3:hover .color-style-13 article blockquote:before {
    background: #ce9c68;
}

.color-style-13 .sub-title-post .link, .color-style-13 .recent-post .caption-4:hover, .color-style-13 .category-list li:hover a, .color-style-13 .category-list li span, .color-style-13 .blog-post-item .post-time a.date-post:hover, .color-style-13 .blog-post-item .title:hover, .color-style-13 article blockquote span {
    color: #ce9c68;
}

.color-style-13 .tag-link:hover, .color-style-13 .tag-link.act {
    border-color: #ce9c68;
}

.sidebar-blog-item .tag-link {
    margin: 0 0.7143rem 1.071rem 0;
}

.category-list {
    position: relative;
    width: 100%;
}

.category-list li {
    position: relative;
    font-size: 0.9286rem;
    line-height: 1.143rem;
    text-transform: uppercase;
    color: #989898;
    width: 100%;
    padding-left: 2.143rem;
    margin-bottom: 1.429rem;
}

.category-list li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.4286rem;
    width: 0.3571rem;
    height: 0.3571rem;
    border-radius: 50%;
    background: #8b7f20;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.category-list li span {
    color: #8b7f20;
    padding-left: 2.143rem;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.category-list li:hover a {
    color: #8b7f20;
}

.category-list li:hover span {
    padding-left: 1.071rem;
}

.category-list li:hover:before {
    left: 0.7143rem;
}

.recent-post .image, .recent-post .text {
    width: 50%;
}

.recent-post .text {
    padding-left: 1.429rem;
}

.recent-post .caption-4 {
    text-transform: none;
}

.recent-post .caption-4:hover {
    color: #8b7f20;
}

.recent-post .caption-4.col-hover-3:hover {
    color: #ce9c68;
}

.date-post {
    font-size: 0.9286rem;
    font-style: italic;
    color: #a1a1a1;
}

.twitter-wiget {
    position: relative;
    width: 100%;
    padding: 2.857rem 2.143rem 2.857rem;
    background: #f3f3f3;
}

.twitt-item .time span {
    padding-left: 1.429rem;
    font-style: italic;
    font-size: 0.9286rem;
    color: rgba(85, 85, 85, 0.7);
}

.twitt-item .time i {
    color: #1cb7eb;
}

.twitter-slider {
    padding-bottom: 2.857rem;
}

.twitt-item .text {
    font-size: 0.9286rem;
    line-height: 1.714rem;
    color: #989898;
}

.twitt-item .text .author {
    color: #1cb7eb;
}

.twitt-item .text a {
    color: #222;
}

.twitt-item .site-link {
    color: #222;
    font-size: 0.9286rem;
    line-height: 1.714rem;
}

.twitt-item .site-link:hover {
    color: #8b7f20;
}

.instagram-wiget {
    margin-left: -0.7143rem;
    margin-right: -0.7143rem;
    position: relative;
}

.instagram-wiget a {
    position: relative;
    display: block;
    padding: 0 0.7143rem 1.429rem;
    width: 33.333%;
    float: left;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.instagram-wiget img {
    width: 100%;
    height: auto;
}

.instagram-wiget a:hover {
    opacity: 0.7;
}

.blog-post-item {
    position: relative;
    width: 100%;
}

.blog-post-item .image {
    width: 100%;
    position: relative;
    height: 26.43rem;
}

.blog-post-item .post-tag {
    position: absolute;
    left: 0.7143rem;
    top: 0.7143rem;
    z-index: 5;
}

.blog-post-item .title:hover {
    color: #8b7f20;
}

.blog-post-item .post-time .date-post {
    padding-right: 1.429rem;
}

.blog-post-item .post-time a.date-post:hover {
    color: #8b7f20;
}

.blog-post-item.sm .image {
    height: 21.43rem;
}

.blog-post-item.sm .like .info-icon {
    padding: 0 1.071rem 0 0;
}

.blog-post-item .title.col-hover-3:hover {
    color: #ce9c68;
}

.tag-post-bar {
    position: relative;
    width: 100%;
    padding: 1.071rem 0;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    display: inline-block;
}

.tag-post-bar .link a {
    font-size: 0.9286rem;
    font-style: italic;
    color: #a1a1a1;
}

.tag-post-bar .link a:hover {
    color: #8b7f20;
}

.like {
    font-size: 0.8571rem;
    color: #a1a1a1;
    position: relative;
    display: inline-block;
}

.like .info-icon {
    padding-left: 1.071rem;
}

.like .info-icon:hover {
    color: #8b7f20;
}

.like i {
    padding-left: 0.2143rem;
}

.blog-post-slider .pagination {
    bottom: 2.143rem;
    z-index: 1;
}

.blog-post-nav {
    position: relative;
    width: 100%;
    text-align: center;
}

.pagination.pagination-blog {
    position: relative;
    display: inline-block;
    width: auto;
}

.pagination.pagination-blog .page-link {
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0 1.071rem;
    border-radius: 0;
    border: 1px solid #e3e3e3;
    color: #989898;
    font-size: 0.7857rem;
    text-transform: lowercase;
    margin: 0 0.3571rem;
}

.pagination.pagination-blog .page-item.active .page-link, .pagination.pagination-blog .page-item:hover .page-link {
    background: #8b7f20;
    border-color: #8b7f20;
    color: #fff;
}

.blog-detail-slider {
    height: 25.71rem;
}

.post-slide {
    position: relative;
    width: 100%;
    height: 100%;
}

.preview-img {
    position: relative;
    height: 8.571rem;
    cursor: pointer;
}

.preview-img .bg {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.swiper-slide.active .preview-img .bg, .swiper-slide:hover .preview-img .bg {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
}

.prev-post-slider .swiper-slide, .slide-gutter-15 .swiper-slide {
    padding: 0 1.071rem;
}

article img {
    max-width: 100%;
    height: auto;
}

article img[align="left"], article img.left {
    float: left;
    padding-right: 1.071rem;
    max-width: 50%;
}

article img[align="right"], article img.right {
    float: right;
    padding-left: 1.071rem;
    max-width: 50%;
}

article p {
    font-size: 0.9286rem;
    line-height: 1.571rem;
    color: #a1a1a1;
}

article blockquote {
    padding-left: 2.143rem;
    font-style: italic;
    position: relative;
    width: 100%;
}

article blockquote span {
    color: #ce9c68;
}

article blockquote p {
    line-height: 1.714rem;
}

article blockquote:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background: #ce9c68;
}

.post-author {
    position: relative;
    text-align: center;
}

.post-author .title span {
    font-size: 0.9286rem;
    color: #989898;
    padding-left: 0.7143rem;
    display: inline-block;
}

.post-author .title .caption-3 {
    display: inline-block;
}

.post-author .image img {
    border-radius: 50%;
}

.post-tags {
    display: inline-block;
    float: left;
}

.post-tags .title {
    display: inline-block;
    padding-right: 0.7143rem;
    line-height: 2.143rem;
}

.post-tags .tag-link {
    margin: 0 0.7143rem 0 0;
}

.share-link {
    position: relative;
    display: inline-block;
    float: right;
    font-size: 14px;
}

.share-link .title {
    display: inline-block;
    line-height: 2rem;
}

.share-link a {
    width: 2rem;
    height: 2rem;
    line-height: 1.857rem;
    border: 1px solid #e3e3e3;
    border-radius: 50%;
    margin: 0 0 0 0.7143rem;
    position: relative;
    display: inline-block;
    text-align: center;
    color: #ce9c68;
    overflow: hidden;
    vertical-align: top;
}

.share-link a i:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: inherit;
    font-family: "fontello";
    font-size: 1rem;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.share-link a i:before {
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.share-link a .icon-facebook:after {
    content: '\f30c';
    color: #567da9;
}

.share-link a .icon-instagram:after {
    content: '\f32d';
    color: #97632c;
}

.share-link a .icon-pinterest:after {
    content: '\f312';
    color: #c24149;
}

.share-link a .icon-twitter:after {
    content: '\f309';
    color: #4eccf0;
}

.share-link a:hover i:before {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}

.share-link a:hover i:after {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}

.comment-item {
    position: relative;
    width: 100%;
}

.comment-item .image {
    max-width: 5rem;
    margin-right: 2.143rem;
}

.comment-item .image img {
    max-width: 100%;
    display: block;
    border-radius: 50%;
}

.comment-item .reply-link {
    position: absolute;
    right: 0;
    top: 2.143rem;
    font-size: 0.8571rem;
}

.comment-item .reply-link:hover, .comment-item .caption-4 a:hover {
    color: #ce9c68;
}

.comment-item.reply-comment {
    padding-left: 5rem;
}

.overflow-content {
    overflow: hidden;
}

@media (max-width: 992px) {
    .header-style-2 .mobile-menu {
        display: block;
    }

    .header-style-2 .nav-list {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        background: #fff;
        height: 100%;
        text-align: center;
        overflow-y: auto;
        display: block !important;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        -webkit-transition: all 0.35s ease-out;
        transition: all 0.35s ease-out;
    }

    .header-style-2 .nav-list .collapse {
        display: block;
    }

    .header-style-2 .nav-list.in {
        display: block;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
    }

    .header-style-2 .navbar-nav {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        padding: 5.714rem 0;
    }

    .header-style-2 .nav-item {
        float: none;
        position: relative;
        margin: 0 0 0.7143rem 0;
        height: auto;
        line-height: 3.214rem;
        display: inline-block;
        width: 100%;
    }

    .header-style-2 .nav-link {
        display: inline-block;
        padding: 0;
        margin-top: 0;
    }

    .header-style-2 .navbar-nav .nav-item + .nav-item {
        margin-left: 0;
    }

    .header-style-2 {
        background: #fff;
        height: 5rem;
    }

    .footer-center-content {
        text-align: center;
    }

    .footer-center-content .logo-footer {
        padding-bottom: 1.429rem;
    }

    .footer-center-content .logo-footer, .footer-center-content .nav-footer {
        float: none;
        display: inline-block;
    }

    .footer-center-content .nav-footer li {
        float: none;
        display: inline-block;
        margin: 0 0.7143rem;
    }

    .nav-item .dropdown-menu {
        position: relative;
        margin: 0 auto;
        visibility: inherit;
        left: auto;
        top: auto;
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        float: none;
        width: 100%;
        display: none;
        opacity: 1;
    }

    .nav-item .dropdown-menu li {
        text-align: center;
    }

    .open > .dropdown-menu {
        display: inline-block;
    }

    .nav-item .dropdown-menu .dropdown-menu.left {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        right: auto;
    }

    .dropdown-menu {
        border: 0 none;
    }

    .drop-link .icon {
        display: inline-block;
    }

    .hidden-mobile-md {
        display: none;
    }

    .header-style-2 .nav-link.active, .header-style-2 .nav-link:hover, .header-style-2 .drop-link .icon {
        background: none !important;
        color: #222;
    }
}

@media (max-width: 768px) {
    .post-tags, .share-link {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .page-style-2 .caption-1 {
        font-size: 3.286rem;
        line-height: 3.371rem;
    }

    .pagination.pagination-blog .page-link {
        padding: 0 0.5714rem;
        margin: 0 0.1429rem;
    }

    .blog-post-item .image {
        height: 19.29rem;
    }

    .blog-post-slider .pagination {
        bottom: 0.7143rem;
    }

    .sidebar-blog-item .tag-link {
        margin: 0 0.2143rem 0.4286rem 0;
    }

    .twitter-wiget {
        padding: 2.143rem 1.071rem 2.143rem;
    }

    article img[align="left"], article img.left {
        width: 100%;
        padding-right: 0;
        max-width: 100%;
        margin-bottom: 1.071rem;
    }

    article img[align="right"], article img.right {
        width: 100%;
        padding-left: 0;
        max-width: 100%;
    }

    .sub-title-post .link {
        margin: 0 0.7143rem;
    }

    .comment-item.reply-comment {
        padding-left: 0;
    }

    .post-tags .tag-link {
        margin: 0 0.3571rem 0.3571rem 0;
    }

    .hidden-mobile-xs {
        display: none;
    }

    .blog-post-item .post-time .date-post {
        padding-right: 1rem;
    }
}

.container-custome {
    padding: 0 7.143rem;
}

.row-custome-100 {
    margin-left: -7.143rem;
    margin-right: -7.143rem;
}

.padd-custome-100 {
    padding: 0 7.143rem;
}

.row-custome-50 {
    margin-left: -3.571rem;
    margin-right: -3.571rem;
}

.padd-custome-50 {
    padding: 0 3.571rem;
}

.padd-custome-30 {
    padding: 0px 2.143rem;
}

.padd-custome-100 {
    padding: 0 7.143rem;
}

.padd-custome-160 {
    padding: 0 11.43rem;
}

.padd-custome-200 {
    padding: 0 200px;
}

.section {
    position: relative;
    width: 100%;
}

.section .overflow {
    overflow: hidden;
    max-width: 100%;
}

.page-style-2 .tag-post-bar .like i {
    color: #989898;
}

.page-style-2 .tag-post-bar .info-icon span {
    color: #989898;
}

.header-style-1.type-1 .nav-item .dropdown-menu {
    position: relative;
    display: block;
    margin: 0 auto;
    top: 0;
    visibility: visible;
    opacity: 1;
    float: none;
    max-width: 200px;
    text-align: center;
    padding: 0;
    display: none;
}

.header-style-1.type-1 .drop-link .icon {
    display: inline-block;
    height: 50px;
    width: 30px;
    text-align: center;
    padding: 0;
    line-height: 50px;
    vertical-align: middle;
    cursor: pointer;
}

.header-style-1.type-1 .nav-item .dropdown-menu li {
    font-size: 16px;
    padding: 0;
    line-height: 16px;
}

.header-style-1.type-1 .nav-item .dropdown-menu li a {
    display: block;
    padding: 10px 0;
}

.header-style-1.type-2 .navbar-nav {
    max-width: 400px;
}

.header-style-1.type-1 .nav-list {
    padding: 250px 0;
}

/*======HOME PAGE 10======*/
.color-style-2 .testi-block .simple-text span, .color-style-2 .info-item .timer, .color-style-2 .blog-big-item .title:hover a, .color-style-2 .on-white .info-icon i, .color-style-2 .item-icon.type-2 .icon, .color-style-2 .quote-block .simple-text span, .color-style-2 .list-img i, .color-style-2 .portfolio-item-1.type-1 .title:hover a {
    color: #cd9129;
}

.color-style-2 .header-style-2 .nav-link.active, .color-style-2 .header-style-2 .nav-link:hover, .color-style-2 .title-style-4 .title-separator, .color-style-2 .icon-numb, .color-style-2 .skill-block .skill-line div {
    background: #cd9129;
}

.color-style-2 .service-item-1:hover .hold-layer-2 {
    background: rgba(205, 145, 41, 0.8);
}

.color-style-2 .filter-mob-list.type-1 li:hover, .color-style-2 .filter-mob-list.type-1 li.active {
    background: #cd9129;
    color: #fff;
    border-color: #cd9129;
}

.color-style-2 .portfolio-item-1.type-1 .layer {
    background: rgba(205, 145, 41, 0.8);
}

.color-style-2 .team-item-1:hover .desc {
    box-shadow: 0px 0px 0px 1.071rem #cd9129;
}

.color-style-2 .blog-item-2:hover .hold-layer-2 {
    background: rgba(205, 145, 41, 0.8);
}

.color-style-2 .button-style-4 {
    color: #cd9129;
    border-color: #cd9129;
}

.color-style-2 .home10-form .button-style-4 {
    color: #cd9129;
    border-color: #cd9129;
}

.color-style-2 .home10-form .button-style-4:hover {
    color: #111;
    border-color: #111;
}

.color-style-2 .button-style-4.type-2 {
    color: #fff;
    border-color: #fff;
}

.color-style-2 .button-style-4:hover {
    color: #111;
    border-color: #111;
}

.color-style-2 .button-style-1 {
    background: #cd9129;
    color: #fff;
}

.color-style-2 .button-style-1:hover {
    background: #111;
}

.color-style-2 .item-icon.type-2 .icon-numb {
    background: #cd9129;
}

.color-style-2 .share-link.type-2 a {
    background: #cd9129;
    color: #fff;
}

.color-style-2 .share-link.type-2 a:hover {
    background: #fff !important;
}

.home10-form .button-style-4 {
    color: #111;
    border-color: #111;
    background: none;
}

.home10-form .button-style-4:hover {
    color: #111;
    border-color: #111;
}

.page-style-3 .button-style-1 {
    font-weight: 700;
}

.page-style-3 .title-style-4 .title-separator.white {
    background: rgba(255, 255, 255, 0.5);
}

.page-style-3 .footer-form .submit {
    font-size: 11px;
    background: none;
    border: 0 none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    font-weight: 900;
    color: #fff;
    padding: 0 10px;
}

.page-style-3 .footer-form .submit:hover {
    color: rgba(255, 255, 255, 0.5);
    border-color: #fff;
}

.page-style-3 .footer-style-2 .footer-item .title {
    font-size: 15px;
}

.item-icon.type-2 .icon-numb {
    background: #111;
    color: #fff;
}

.service-item-1:hover .hold-layer-2 {
    background: rgba(0, 0, 0, 0.8);
}

.filter-mob-list.type-1 li:hover, .filter-mob-list.type-1 li.active {
    background: #111;
    color: #fff;
    border-color: #111;
}

.team-item-1:hover .desc {
    box-shadow: 0px 0px 0px 1.071rem #111;
}

.portfolio-item-1.type-1 .layer {
    background: #000;
    opacity: 0.8;
    visibility: hidden;
}

.portfolio-item-1:hover .layer {
    visibility: visible;
}

.top-header-info {
    height: 2.857rem;
    line-height: 2.857rem;
    background: #222;
    z-index: 5;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

.top-header-info .link {
    position: relative;
    display: inline-block;
    padding-right: 2.143rem;
}

.top-header-info .link h6 {
    color: #fff;
    display: inline-block;
    padding-right: 0.7143rem;
}

.top-header-info .link a {
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
}

.top-header-info .link a:hover {
    color: #fff;
}

.top-header-info .share-link {
    position: relative;
    float: left;
    font-size: 0px;
}

.top-header-info .share-link.type-2 a {
    background: none;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0px;
    font-size: 14px;
    line-height: 2.857rem;
    width: 4.286rem;
    height: 2.857rem;
}

.top-header-info .share-link.type-2 a i {
    display: inline-block;
    line-height: 2.857rem;
}

.top-header-info .lang-list {
    width: 4.286rem;
    height: 2.857rem;
    float: left;
    text-align: center;
}

.top-header-info .lang-list .title {
    line-height: 2.857rem;
    color: #fff;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    display: inline-block;
    position: relative;
    width: 100%;
}

.top-header-info .lang-list .dropdown-menu {
    margin-top: 0px;
    min-width: 120px;
    padding: 0;
    margin: 0;
    border-radius: 0;
}

.top-header-info .lang-list .dropdown-menu li {
    position: relative;
    width: 100%;
    font-size: 13px;
    line-height: 13px;
    font-weight: 900;
}

.top-header-info .lang-list .dropdown-menu li a {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 0.7143rem 1.429rem;
}

.top-header-info .lang-list .dropdown-menu li:hover {
    background: #222;
}

.top-header-info .lang-list .dropdown-menu li:hover a {
    color: #fff;
}

.top-header-info .right-desc {
    position: relative;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.header-style-2.with-top-bar {
    height: 11.43rem;
    background: #fff;
}

.header-style-2.with-top-bar .container-custome {
    height: auto;
}

.fix-header-align {
    margin-top: 2.857rem;
}

.with-top-bar .navbar {
    margin-top: 2.857rem;
}

.with-top-bar .navbar-brand {
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

.open > .dropdown-menu {
    visibility: visible;
    opacity: 1;
}

.quote-block {
    text-align: center;
    display: block;
    margin: 0 auto;
}

.quote-block .simple-text span {
    font-size: 1.429rem;
}

.img-border-item {
    position: relative;
    width: 100%;
    display: inline-block;
    padding: 70px;
    background: #fff;
    border: 1.071rem solid #fff;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
}

.img-border-item .col-50:nth-child(1), .img-border-item .col-50:nth-child(3) {
    padding-right: 3.571rem;
    border-right: 1px solid #e3e3e3;
}

.img-border-item .col-50:nth-child(2), .img-border-item .col-50:nth-child(4) {
    padding-left: 3.571rem;
}

.img-border-item .col-50:nth-child(1), .img-border-item .col-50:nth-child(2) {
    padding-bottom: 65px;
    border-bottom: 1px solid #e3e3e3;
}

.img-border-item .col-50:nth-child(3), .img-border-item .col-50:nth-child(4) {
    padding-top: 65px;
}

.img-border-item.sm {
    padding: 70px 2.857rem;
}

.img-border-item.team-item-1 {
    padding: 0px;
}

.bg-block {
    width: 100%;
    position: relative;
    height: 100%;
}

.bg-block .title-style-4 {
    display: block;
    margin: 0 auto;
}

.slider-type-1 .col-33.align-item {
    padding: 0px 70px;
}

.col-51 {
    width: 56%;
    padding: 0 15px;
}

.col-49 {
    width: 33%;
    padding: 0 15px;
}

.clients-logo {
    position: relative;
}

.size-1 .item {
    padding: 0px 1.071rem;
}

.grid-sizer {
    width: 0;
}

.filter-mob-list.type-1 li {
    width: 14.28%;
    position: relative;
    float: left;
    height: 3.571rem;
    line-height: 3.571rem;
    text-align: center;
    border: 1px solid #e3e3e3;
    border-left: 0px none;
    font-size: 12px;
    text-transform: uppercase;
    color: #989898;
    font-weight: 700;
    cursor: pointer;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

.filter-mob-list.type-1 li:first-child {
    border-left: 1px solid #e3e3e3;
}

.testi-block {
    text-align: center;
    position: relative;
    width: 100%;
}

.testi-block .simple-text {
    padding: 0px 3.571rem;
    max-width: 430px;
    display: block;
    margin: 0 auto;
}

.testi-block .simple-text span {
    position: absolute;
    top: 50%;
    font-size: 24px;
}

.testi-block .r-q {
    right: 0px;
}

.testi-block .l-q {
    left: 0px;
}

.testi-block.title-style-4 .simple-text span {
    color: #fff;
    font-size: 2.143rem;
    top: auto;
    position: relative;
}

.testi-block.title-style-4 .simple-text p {
    text-transform: uppercase;
}

.testi-block.title-style-4 .r-q {
    top: 0;
}

.testi-block.title-style-4 .l-q {
    bottom: 0;
}

.testi-block.title-style-4.type-3 .r-q, .testi-block .title-style-4.type-3 .l-q {
    top: 50%;
}

.testi-block.title-style-4.type-3 .sub-caption {
    color: rgba(255, 255, 255, 0.7);
}

.testi-block.title-style-4.type-3 .simple-text span {
    position: absolute;
    top: 50%;
    line-height: 55px;
    margin-top: -20px;
}

.mobile-filter-wrap {
    position: relative;
}

.change-text {
    position: relative;
    width: 100%;
    display: none;
    margin: 0 auto;
    height: 2.857rem;
    line-height: 2.857rem;
    border: 1px solid #e3e3e3;
    padding: 0px 1.071rem;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
}

.change-text i {
    position: absolute;
    right: 1.071rem;
    top: 0;
    font-size: 14px;
}

.home10-detail-slider {
    height: 400px;
}

.cl-logo-wrap a {
    display: block;
    float: left;
    width: 25%;
    min-height: 11.43rem;
    position: relative;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.cl-logo-wrap a:hover img {
    opacity: 0.7;
}

.cl-logo-wrap a img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 80%;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    display: block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.cl-logo-wrap a:nth-child(1), .cl-logo-wrap a:nth-child(5) {
    border-left: 0px none;
}

.cl-logo-wrap a:nth-child(5), .cl-logo-wrap a:nth-child(6), .cl-logo-wrap a:nth-child(7), .cl-logo-wrap a:nth-child(8) {
    border-bottom: 0px none;
}

.skill-block {
    position: relative;
    display: inline-block;
    width: 100%;
}

.skill-block .title {
    position: relative;
    font-size: 14px;
    background: #fff;
    z-index: 1;
    display: inline-block;
}

.skill-block .title i {
    padding-right: 25px;
    display: inline-block;
    font-size: 18px;
}

.skill-block .title .caption-5 {
    display: inline-block;
}

.skill-block .skill-line {
    height: 7px;
    background: #e3e3e3;
    position: relative;
    width: 100%;
}

.skill-block .skill-line div {
    position: absolute;
    background: #222;
    width: 0%;
    height: 100%;
    -webkit-transition: all 2s ease-out 0.3s;
    transition: all 2s ease-out 0.3s;
}

.skill-block .skill-line div:before {
    content: '';
    position: absolute;
    right: 0px;
    bottom: -2px;
    height: 3.571rem;
    width: 1px;
    background: inherit;
}

.skill-block .timer {
    font-size: 13px;
    text-align: right;
    position: absolute;
    right: 0.7143rem;
    top: -2.857rem;
    color: #222;
    font-weight: 400;
}

.blog-big-item {
    text-align: center;
    position: relative;
}

.info-item {
    text-align: center;
    position: relative;
}

.info-item .icon {
    font-size: 36px;
    display: block;
    color: #989898;
}

.info-item .timer {
    font-size: 42px;
    font-weight: 900;
}

.home10-form .input-group.input-style-1 {
    max-width: 32.14rem;
    display: inline-block;
    height: 2.143rem;
    vertical-align: bottom;
    margin-right: 0.7143rem;
}

.contact-sm-item {
    position: relative;
    text-align: center;
}

.contact-sm-item .link {
    display: block;
    font-size: 13px;
    color: #a1a1a1;
}

.contact-sm-item .link:hover {
    color: #222;
}

.contact-sm-item .folow.type-1 a {
    border-left: 0px none;
}

.contact-sm-item.type-2 {
    text-align: left;
}

.contact-sm-item.type-2 .link {
    font-style: italic;
    color: #989898;
}

.contact-sm-item.type-2 p {
    font-style: italic;
}

.contact-sm-item.type-1 .icon {
    font-size: 18px;
}

.contact-sm-item.type-1 .link {
    display: inline-block;
}

.contact-sm-item.type-1 .folow.type-1 a {
    padding: 0 15px;
}

.contact-sm-item.text-left {
    text-align: left;
}

.button-style-4.main-color {
    border-color: #cd9129;
    color: #cd9129;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

.button-style-4.main-color:hover {
    border-color: #222;
    color: #222;
}

.push-item {
    position: relative;
    width: 100%;
    display: inline-block;
}

.push-right {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}

.push-left {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
}

.push-left-next {
    float: right;
}

.col-no-padd {
    padding-left: 0;
    padding-right: 0;
}

.row-no-margin {
    margin-left: 0;
    margin-right: 0;
}

.padd-left-100 {
    padding-left: 100px;
}

.isotope-filter-list li {
    cursor: pointer;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

@media (min-width: 992px) {
    .header-style-2.fix {
        height: 70px;
    }

    .header-style-2.fix .top-header-info {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    .with-top-bar.fix .navbar {
        margin-top: 0px;
    }
}

@media (max-width: 1400px) {
    .clients-logo.padd-custome-100, .slider-type-1 .col-33.align-item {
        padding: 0px 3.571rem;
    }

    .img-border-item.sm {
        padding: 3.571rem 2.143rem;
    }

    .img-border-item {
        padding: 3.571rem;
    }

    .resp-coll .col-40, .resp-coll .col-20 {
        width: 25%;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets.vertical-type {
        right: 1.429rem;
    }

    .padd-left-100 {
        padding-left: 2.143rem;
        padding-right: 2.143rem;
    }

    .col-51 {
        width: 100%;
    }

    .col-49 {
        width: 100%;
        margin-bottom: 70px;
    }
}

@media (max-width: 1200px) {
    .team-item-1 .image img {
        max-width: 80%;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .blog-item-2 .top-desc, .blog-item-2 .bottom-desc {
        padding: 1.071rem;
    }

    .item.col-25 {
        width: 50%;
    }
}

@media (max-width: 992px) {
    .caption-1, .caption-1.lg {
        font-size: 2.857rem;
        line-height: 46px;
    }

    .caption-2 {
        font-size: 2.143rem;
        line-height: 36px;
    }

    .caption-3 {
        font-size: 1.429rem;
        line-height: 26px;
    }

    .caption-4 {
        font-size: 18px;
        line-height: 22px;
    }

    .header-style-2.with-top-bar {
        height: 4.286rem;
    }

    .with-top-bar .navbar {
        margin-top: 22px;
    }

    .top-header-info {
        display: none;
    }

    .vertical-align.main-baner, .bg-block .vertical-align.full, .push-right .vertical-align.full, .push-left .vertical-align.full {
        position: relative;
        top: auto;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
    }

    .resp-coll .col-40 {
        width: 100%;
    }

    .resp-coll .col-20 {
        width: 33.333%;
    }

    .img-border-item .col-50:nth-child(3), .img-border-item .col-50:nth-child(4) {
        padding-top: 2.143rem;
    }

    .img-border-item .col-50:nth-child(1), .img-border-item .col-50:nth-child(2) {
        padding-bottom: 2.143rem;
    }

    .img-border-item .col-50:nth-child(1), .img-border-item .col-50:nth-child(3) {
        padding-right: 2.143rem;
    }

    .img-border-item .col-50:nth-child(2), .img-border-item .col-50:nth-child(4) {
        padding-left: 2.143rem;
    }

    .push-right, .push-left {
        position: relative;
        height: auto;
    }

    .mobile-filter-wrap {
        max-width: 300px;
        margin: 0 auto;
    }

    .change-text {
        display: block;
    }

    .isotope-filter-list {
        position: absolute;
        left: 0px;
        top: 100%;
        background: #fff;
        z-index: 500;
        border: 1px solid #e3e3e3;
        border-top: 0 none;
        display: none;
    }

    .filter-mob-list.type-1 li {
        width: 100%;
        border: 0 none;
        border-bottom: 1px solid #e3e3e3;
        height: 2.857rem;
        line-height: 2.857rem;
    }

    .filter-mob-list.type-1 li:first-child {
        border-left: 0 none;
    }

    .filter-mob-list.type-1 li:last-child {
        border-bottom: 0 none;
    }

    .header-style-2.fix .nav {
        padding: 80px 0;
    }

    .color-style-2 .header-style-2 .nav-link.active, .color-style-2 .header-style-2 .nav-link:hover {
        background: none !important;
        color: #111;
    }

    .page-style-3 .drop-link .icon {
        color: #111;
    }

    .col-49 {
        margin-bottom: 0px;
    }

    .header-style-1.type-1 .nav-list {
        padding: 100px 0px;
    }
}

@media (max-width: 768px) {
    .title-style-4 .title-separator {
        margin-left: 0px;
    }

    .resp-coll .col-20 {
        width: 100%;
    }

    .service-item-1 {
        min-height: 300px;
    }

    .item.col-25 {
        width: 100%;
    }

    .clients-logo.padd-custome-100, .slider-type-1 .col-33.align-item, .padd-left-100 {
        padding: 0px 1.071rem;
    }

    .cl-logo-wrap a {
        width: 50%;
        border-left: 0px none;
        border-bottom: 0px none;
    }

    .team-item-1 .share-link {
        width: auto;
    }

    .home10-form .input-group.input-style-1 {
        max-width: none;
        margin-bottom: 2.143rem;
    }
}

@media (max-width: 554px) {
    .img-border-item .col-50 {
        width: 100%;
    }

    .img-border-item .col-50:nth-child(1), .img-border-item .col-50:nth-child(3) {
        padding-right: 0;
    }

    .img-border-item .col-50:nth-child(2), .img-border-item .col-50:nth-child(4) {
        padding-left: 0;
    }

    .img-border-item .col-50:nth-child(1), .img-border-item .col-50:nth-child(3) {
        border-right: 0 none;
    }

    .img-border-item .col-50:nth-child(1), .img-border-item .col-50:nth-child(2) {
        border-bottom: 0 none;
    }

    .img-border-item .col-50:nth-child(3), .img-border-item .col-50:nth-child(4) {
        padding-top: 0;
        padding-bottom: 2.143rem;
    }

    .img-border-item .col-50:last-child {
        padding-bottom: 0;
    }

    .img-border-item {
        padding: 2.143rem 1.071rem;
    }

    .img-border-item.sm {
        padding: 2.143rem 1.071rem;
    }

    .home10-detail-slider {
        height: 270px;
    }

    .cl-logo-wrap a {
        width: 100%;
        min-height: 100%;
        margin-bottom: 2.143rem;
    }

    .cl-logo-wrap a img {
        position: relative;
        top: auto;
        left: auto;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        margin: 0 auto;
    }

    .cl-logo-wrap a:last-child {
        margin-bottom: 0;
    }

    .blog-item-2 {
        min-height: 300px;
    }
}

@media (max-width: 480px) {
    .skill-block .title i {
        display: none;
    }

    .skill-block .title .caption-5 {
        font-size: 0.7143rem;
    }

    .testi-block .simple-text {
        padding: 0px 2.143rem;
    }
}

@media (max-width: 360px) {
    .img-border-item {
        border-width: 0.7143rem;
    }

    .info-icon {
        margin-right: 0.7143rem;
    }

    .contact-sm-item .folow.type-1 a {
        padding: 0px 5px;
    }
}

/*======HOME PAGE 11======*/
.page-style-4 .header-style-2.fix {
    background: #666;
}

.color-style-4 .header-style-2 .nav-link.active, .color-style-4 .header-style-2 .nav-link:hover {
    background: #111;
    color: #fff;
}

.color-style-4 .button-style-1.type-1 {
    background: #fff;
    color: #111;
}

.color-style-4 .button-style-1.type-1:hover {
    color: #fff;
    background: none !important;
    box-shadow: 0px 0px 0px 2px white;
}

.color-style-4 .button-style-1.type-2 {
    background: #cfae77;
    color: #fff;
}

.color-style-4 .button-style-1.type-2:hover {
    color: #111;
    background: none !important;
    box-shadow: 0px 0px 0px 2px #cfae77;
    color: #111;
}

.color-style-4 .list-img i, .color-style-4 .item-icon.type-2 .icon, .contact-sm-item.type-2 .link:hover {
    color: #111;
}

.color-style-4 .portfolio-item-1.type-2 .layer {
    background: rgba(227, 107, 65, 0.8);
}

.color-style-4 .blog-item-3:hover .hold-layer-2 {
    background: rgba(226, 100, 56, 0.8);
}

.color-style-4 .title-style-5.color-2 .title-separator span {
    background: #e26438;
}

.page-style-4 .header-style-2 .nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.page-style-4 .button-style-1 {
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0);
}

.border-style {
    border: 3px solid #fff;
    padding: 70px 65px;
}

.border-style .title-separator {
    background: #fff;
}

.border-style.type-1 .sub-caption {
    color: rgba(255, 255, 255, 0.7);
}

.border-style.type-2 {
    border-color: #cfae77;
}

.border-style.type-2 .title-separator {
    background: #cfae77;
}

.border-style.lg {
    padding: 130px 80px;
}

.full-height-baner .swiper-container {
    height: inherit;
}

.full-height-baner.h-50 {
    height: 50vmin;
    min-height: inherit;
    margin-top: 8.214rem;
}

.about-image {
    position: relative;
    width: 100%;
}

.about-image.right {
    float: right;
}

.sub-caption-qoute {
    color: #a1a1a1;
    font-size: 13px;
    text-transform: uppercase;
}

.sub-caption-qoute span {
    width: 2.143rem;
    height: 1px;
    background: #a1a1a1;
    display: inline-block;
    vertical-align: middle;
    margin-right: 9px;
}

.border-style.type-1 .sub-caption-qoute {
    color: rgba(255, 255, 255, 0.7);
}

.service-block {
    position: relative;
    width: 100%;
    height: 100%;
}

.custome-block-1 {
    padding: 0px 11.43rem;
}

.list-img-br {
    position: relative;
}

.list-img-br .col-50 {
    padding: 65px 2.143rem 3.571rem;
    min-height: 300px;
}

.list-img-br .col-50:nth-child(1), .list-img-br .col-50:nth-child(3) {
    border-right: 1px solid #e3e3e3;
}

.list-img-br .col-50:nth-child(1), .list-img-br .col-50:nth-child(2) {
    border-bottom: 1px solid #e3e3e3;
}

.client-item {
    text-align: center;
    min-height: 120px;
}

.client-item .title {
    vertical-align: bottom;
    display: inline-block;
}

.client-item .title:hover a {
    color: #111;
}

.client-item .image {
    display: inline-block;
    vertical-align: top;
}

.client-item .image a {
    display: block;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

.client-item .image:hover a {
    opacity: 0.5;
}

.footer-style-3 {
    text-align: center;
    position: relative;
    width: 100%;
}

.footer-style-3 .footer-item .title, .footer-style-3 .recent-mini-link a {
    color: #fff;
}

.footer-style-3 .footer-item .title {
    font-size: 16px;
}

.footer-style-3 .footer-twitt .time span {
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
}

.footer-style-3 .copyright {
    font-weight: 900;
}

.footer-style-3 .copyright a, .footer-style-3 .copyright i {
    color: #fff;
}

.footer-style-3 .folow.type-1 a:hover {
    color: #fff;
}

.page-style-4 .mobile-menu.col-style-1 i, .page-style-4 .mobile-menu.col-style-1 i:after, .page-style-4 .mobile-menu.col-style-1 i:before {
    background: #fff;
}

.page-style-4 .mobile-menu.active i, .page-style-4 .mobile-menu.col-style-1.active i {
    background: transparent;
}

.list-img-br .list-style-1 li:last-child {
    padding-bottom: 0;
}

@media (max-width: 1700px) {
    .about-image img {
        max-width: 100%;
    }

    .padd-custome-160 {
        padding: 0px 3.571rem;
    }

    .list-img-br .col-50 {
        padding: 2.143rem 1.071rem;
    }
}

@media (max-width: 1400px) {
    .padd-custome-160, .padd-custome-200, .blog-item-3 .desc {
        padding: 0 2.143rem;
    }

    .border-style.lg, .blog-item-3 {
        padding: 75px 4.286rem;
    }
}

@media (max-width: 1200px) {
    .blog-item-3 {
        padding: 2.143rem 2.857rem;
    }

    .blog-item-3 .info-icon {
        margin-right: 1.071rem;
    }
}

@media (max-width: 992px) {
    .page-style-4 .header-style-2, .page-style-4 .header-style-2.fix, .page-style-4 .header-style-2 .nav-list {
        background: #111;
    }

    .page-style-4 .header-style-2.white-mobile .nav-list {
        background: #fff;
    }

    .page-style-4 .header-style-2.white-mobile .nav-list .nav-link.active {
        color: #222;
    }

    .border-style {
        padding: 2.143rem 2.857rem;
    }

    .list-img-br .col-50 {
        padding: 0px;
        min-height: 100%;
    }

    .list-img-br .col-50:nth-child(1), .list-img-br .col-50:nth-child(3) {
        border-right: 0 none;
    }

    .list-img-br .col-50:nth-child(1), .list-img-br .col-50:nth-child(2) {
        border-bottom: 0 none;
    }

    .size-1 .item.col-33 {
        width: 50%;
    }

    .border-style.lg {
        padding: 2.143rem 2.857rem;
    }

    .map-item {
        height: 500px;
    }

    .full-height-baner.h-60 {
        min-height: 30rem;
    }
}

@media (max-width: 768px) {
    .arrow-closest .swipe-arrow {
        display: none;
    }

    .size-1 .item.col-33 {
        width: 100%;
    }

    .list-img-br .col-50 {
        width: 100%;
        text-align: center;
    }

    .list-img-br.list-img .col-50 .icon {
        float: none;
        padding: 0;
        margin-bottom: 1.071rem;
    }

    .list-img-br.list-img .list-style-1 li {
        padding-left: 0;
    }

    .list-img-br.list-img .list-style-1 li .list-point {
        position: relative;
        display: inline-block;
        top: -5px;
        margin-right: 0.7143rem;
    }

    .list-img-br.list-img .title {
        padding-left: 0;
    }

    .team-item-2 .image {
        width: 100%;
        margin: 0 auto;
        display: block;
    }

    .team-item-2 .desc {
        width: 100%;
    }

    .team-item-2 .desc {
        text-align: center;
        padding-left: 0;
    }
}

@media (max-width: 554px) {
    .border-style {
        padding: 2.143rem;
    }

    .testi-block.title-style-4 .simple-text p {
        font-size: 13px;
        line-height: 16px;
    }

    .blog-item-3 {
        padding: 0;
    }

    .blog-item-3 .desc {
        border: 0 none;
    }

    .team-item-2 .image {
        padding: 1.071rem;
    }

    .blog-item-3 .info-icon:nth-child(3), .blog-item-3 .info-icon:nth-child(4) {
        display: block;
        float: right;
        margin: 0 0 0.7143rem 0;
        clear: right;
    }

    .blog-item-3 .info-icon:nth-child(1), .blog-item-3 .info-icon:nth-child(2) {
        display: block;
        float: left;
        margin: 0 0.7143rem 0 0;
    }

    .input-group.input-style-1.sm {
        margin-bottom: 1.429rem;
    }

    .contact-sm-item.type-2 {
        text-align: center;
    }

    .map-item {
        height: 350px;
    }

    .portfolio-item-1.type-2:hover .layer .l-border {
        left: 1.071rem;
        top: 1.071rem;
        right: 1.071rem;
        bottom: 1.071rem;
    }
}

/*======HOME PAGE 25======*/
.line-section:before {
    content: '';
    position: absolute;
    left: 50%;
    height: 33.333%;
    width: 1px;
    top: 33.333%;
    background: #f4f4f4;
}

.line-section.bg-section-1:before {
    background: rgba(255, 255, 255, 0.1);
}

div[class*="row-custome"]:after {
    content: "";
    display: table;
    clear: both;
}

.full-height-baner {
    position: relative;
    width: 100%;
    min-height: 50rem;
    height: 100vmin;
    margin-top: -20px;
}

@media (max-width: 992px) {
    .full-height-baner {
        margin-top: 0;
    }
}

.main-baner {
    width: 100%;
}

.video-open.over {
    z-index: 1000;
}

.page-style-1 .video-open.over {
    z-index: 5000;
}

.model-style-baner .title-style-1 {
    z-index: 1;
}

.model-style-baner .simple-text {
    max-width: 35.71rem;
    margin: 0 auto;
}

.model-style-baner .image {
    margin-top: -1.429rem;
    height: 38.57rem;
    position: relative;
    width: 100%;
}

.model-style-play {
    width: 3.786rem;
    height: 3.786rem;
    border-radius: 50%;
    background: #fff;
    display: block;
    margin: 0 auto;
    line-height: 3.786rem;
    font-size: 0.9286rem;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.45s ease-out;
    transition: all 0.45s ease-out;
}

.model-style-play:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0 0 0.5rem rgba(234, 251, 244, 0.2);
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.model-style-play .line-play {
    position: absolute;
    left: 50%;
    height: 2.857rem;
    top: -1.429rem;
    width: 0.07143rem;
    margin-top: -0.5rem;
    -webkit-transition: all 0.45s ease-out;
    transition: all 0.45s ease-out;
}

.model-style-play .line-play:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 50%;
    width: 100%;
    background: #fff;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.model-style-play .line-play:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    height: 50%;
    width: 100%;
    background: #222;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.model-style-play:hover {
    background: #222;
    color: #fff;
}

.model-style-play:hover:before {
    box-shadow: 0 0 0 0.8rem rgba(234, 251, 244, 0.2);
}

.model-style-play:hover .line-play {
    margin-top: -0.8rem;
}

.model-style-play:hover .line-play:after {
    background: #fff;
}

.model-style-play:hover .line-play:before {
    background: #222;
}

.video-item {
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: -5;
}

.bgvid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}

.close-video {
    width: 2.308rem;
    height: 2.308rem;
    background: #fff;
    z-index: 8;
    color: #272b2b;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 2.462rem;
    text-align: center;
    font-size: 1.846rem;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.close-video span {
    display: block;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.video-item.act .video-title {
    opacity: 0;
}

.video-item.act .close-video {
    opacity: 1;
}

.video-item.act {
    z-index: 5;
}

.video-item.act .video-wrapper {
    opacity: 1;
    visibility: visible;
}

.video-wrapper {
    position: relative;
    width: 100%;
    display: block;
    background: #000;
    height: 100%;
    opacity: 0;
    visibility: hidden;
}

.video-iframe {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.video-iframe iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
}

.ag-portfolio-desc .caption-4 {
    display: inline-block;
    padding-right: 0.3571rem;
}

.ag-portfolio-desc p, .ag-team-desc p {
    display: inline-block;
    font-size: 0.9286rem;
    color: #989898;
}

.ag-team-desc .title i {
    display: inline-block;
    padding-right: 0.7143rem;
    font-size: 1.143rem;
    color: #dbc496;
}

.ag-team-desc .title .caption-4 {
    display: inline-block;
}

.blog-item-1 .bl-date {
    float: left;
}

.blog-item-1 .bl-author {
    float: right;
}

.blog-item-1 .bl-date, .blog-item-1 .bl-author {
    font-size: 0.9286rem;
    color: rgba(255, 255, 255, 0.5);
}

.blog-item-1 .simple-text {
    width: 100%;
}

.blog-item-1 .image {
    width: 100%;
}

.blog-item-1 .title {
    margin-top: -0.7143rem;
    z-index: 6;
    position: relative;
}

.blog-item-1 .title:hover a {
    color: #dbc496;
}

.blog-desc {
    display: block;
    width: 100%;
    display: inline-block;
}

.slider-model {
    padding-bottom: 4.286rem;
}

.item-icon {
    position: relative;
    text-align: center;
}

.item-icon .icon {
    position: relative;
}

.item-icon.type-1 .title {
    display: inline-block;
}

.item-icon.type-1 .icon {
    display: inline-block;
    padding-right: 1.429rem;
    font-size: 1.143rem;
    color: #dbc496;
}

.item-icon.type-2 .icon {
    font-size: 24px;
}

.item-icon.text-left {
    text-align: left;
}

.item-icon.type-3 {
    padding: 2.857rem;
}

.item-icon.type-3 .icon {
    color: #fff;
    font-size: 2.143rem;
}

.item-icon.type-5 {
    height: 270px;
    text-align: center;
    padding: 0 50px;
    position: relative;
}

.item-icon.type-5 .icon {
    font-size: 26px;
}

.item-icon.type-5 .sub-caption {
    color: rgba(255, 255, 255, 0.5);
}

.icon-numb {
    position: relative;
    display: inline-block;
    margin: 0px auto;
    color: #FFF;
    padding: 0px 25px;
    height: 100px;
    line-height: 100px;
    font-weight: 900;
    font-size: 42px;
}

.hover-link:hover a {
    color: #dbc496;
}

.hover-img {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.hover-img img {
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.hover-img:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.hover-img.image {
    position: relative;
    overflow: hidden;
}

.hover-img.image .bg {
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.hover-img.image:hover .bg {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.title-style-1.offset {
    margin-bottom: -1.071rem;
    z-index: 5;
}

.simple-item-2 {
    text-align: center;
    position: relative;
    width: 100%;
}

.simple-item-2 .image {
    height: 35.71rem;
    position: relative;
}

.simple-item-2 .title-style-1 {
    z-index: 1;
}

.simple-item-2 .text {
    display: block;
    margin: 0 auto;
    max-width: 65%;
}

.slider-model-portfolio .swiper-slide {
    padding: 0 1.071rem;
}

.casting-list .item-icon {
    max-width: 22.86rem;
    margin: 0 auto;
}

.simple-item-2.casting-item {
    text-align: left;
    padding: 0 3.571rem 0 7.143rem;
}

.simple-item-2.casting-item .text {
    margin-left: -7.143rem;
}

.simple-item-2.casting-item .title-style-1 {
    text-align: left;
}

.simple-item-2.casting-item .image {
    height: 50rem;
}

.slider-model-team {
    padding-bottom: 4.286rem;
}

.slider-model-team .swiper-slide {
    padding: 0 3.571rem;
}

.achievement-item {
    position: relative;
    text-align: center;
    max-width: 45rem;
    margin: 0 auto;
}

.achievement-item img {
    max-width: 100%;
    margin: 0 auto;
}

.achievement-item .date {
    font-size: 13px;
    color: #a1a1a1;
}

.achievement-item .caption-3 {
    font-size: 14px;
}

.achievement-item .hover-img {
    display: inline-block;
}

.slider-model-blog {
    padding-bottom: 4.286rem;
}

.slider-model-blog .swiper-slide {
    padding: 0 3.571rem;
}

@media (max-height: 800px) {
    .model-style-baner .image {
        height: 26.57rem;
    }
}

@media (max-width: 1700px) {
    .ag-portfolio-desc.fr, .ag-portfolio-desc.fl {
        float: none;
        display: block;
        clear: both;
        padding-bottom: 0.3571rem;
    }
}

@media (max-width: 1400px) {
    .padd-custome-100 {
        padding: 0 2.143rem;
    }

    .row-custome-100 {
        margin-left: -2.143rem;
        margin-right: -2.143rem;
    }

    .container-custome, .simple-item-2.casting-item {
        padding: 0 2.143rem;
    }

    .row-custome-50 {
        margin-left: -1.071rem;
        margin-right: -1.071rem;
    }

    .slider-model-team .swiper-slide, .slider-model-blog .swiper-slide, .padd-custome-50 {
        padding: 0 1.071rem;
    }

    .simple-item-2.casting-item .text {
        margin-left: -2.143rem;
    }

    .pagination-show-lg .swipe-arrow {
        display: none;
    }

    .pagination-show-lg .swiper-container {
        padding-bottom: 4.286rem;
    }

    .pagination-show-lg .pagination.hidden {
        display: block !important;
    }
}

@media (max-width: 992px) {
    .header-style-1 {
        height: 4.286rem;
        background: #111;
    }

    .page-style-1 .caption-1 {
        font-size: 3.286rem;
        line-height: 3.571rem;
    }

    .header-style-1 .nav-item {
        font-size: 1.571rem;
    }

    .header-style-1 .navbar-nav .nav-link {
        height: 2.857rem;
        line-height: 2.857rem;
    }

    .line-section:before {
        display: none;
    }

    .pagination-show-md .swipe-arrow {
        display: none;
    }

    .pagination-show-md .swiper-container {
        padding-bottom: 4.286rem;
    }

    .pagination-show-md .pagination.hidden {
        display: block !important;
    }

    .model-style-baner .image {
        height: 24.57rem;
    }

    .full-height-baner {
        min-height: 100%;
    }

    .main-baner.vertical-align.full {
        position: relative;
        top: 0;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        padding: 8.571rem 0;
    }

    .no-align-mobile-md.vertical-wrap > div[class*="col"] {
        display: block;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .padd-custome-100 {
        padding: 0 1.071rem;
    }

    .row-custome-100 {
        margin-left: -1.071rem;
        margin-right: -1.071rem;
    }

    .padd-custome-160 {
        padding: 0 1.071rem;
    }

    .container-custome, .simple-item-2.casting-item {
        padding: 0 1.071rem;
    }

    .no-align-mobile-sm.vertical-wrap > div[class*="col"] {
        display: block;
        width: 100%;
    }

    .simple-item-2 .image {
        height: 25.71rem;
    }

    .simple-item-2 .text {
        max-width: 100%;
    }

    .simple-item-2.casting-item {
        padding: 0;
    }

    .simple-item-2.casting-item .text {
        margin-left: 0;
        padding: 0 2.143rem;
        width: 100%;
        left: 0;
    }

    .simple-item-2.casting-item .image {
        height: 35.71rem;
    }

    .footer-style-1 {
        padding: 2.143rem 0;
        text-align: center;
    }

    .footer-style-1 .copyright {
        padding-bottom: 1.429rem;
    }

    .footer-style-1 .copyright, .footer-style-1 .folow {
        float: none;
    }
}

@media (max-width: 480px) {
    .page-style-1 .caption-2 {
        font-size: 2.5rem;
        line-height: 2.671rem;
    }

    .simple-text.size-2 p {
        font-size: 1.043rem;
        line-height: 1.514rem;
    }

    .title-style-2 .title-separator {
        display: none;
    }

    .title-style-2 {
        padding-left: 0;
        text-align: center;
    }

    .simple-item-2.casting-item .text {
        padding: 0 1.071rem;
    }

    .main-baner.vertical-align.full {
        padding: 5.714rem 0 3.571rem;
    }

    .ag-portfolio-desc {
        text-align: center;
    }

    .ag-portfolio-desc .caption-4 {
        display: block;
        padding-bottom: 0.5rem;
    }

    .ag-portfolio-desc p {
        padding-bottom: 1rem;
    }

    .item-icon.text-left {
        text-align: center;
    }
}

/*======HOME PAGE 30======*/
.page-style-4 .header-style-2.fix.fix-color {
    background: #111;
}

.color-style-3 .header-style-2 .nav-link.active, .color-style-3 .header-style-2 .nav-link:hover {
    background: #dd4464;
    color: #fff;
}

.color-style-3 .button-style-1.type-1 {
    background: #dd4464;
    color: #fff;
    font-size: 12px;
}

.color-style-3 .button-style-1.type-1:hover {
    color: #fff;
}

.color-style-3 .button-style-1.type-1.color-1 {
    background: #fff;
    color: #111;
}

.color-style-3 .button-style-1.type-1.color-1 i {
    color: #dd4464;
}

.color-style-3 .button-style-1.type-1.color-1:hover {
    background: #dd4464;
    color: #fff;
}

.color-style-3 .button-style-1.type-1.color-1:hover i {
    color: #fff !important;
}

.color-style-3 .title-style-4 .title-separator {
    background: #dd4464;
}

.color-style-3 .sub-title.color-1, .color-style-3 .list-img i, .color-style-3 .tab-link-style-1:hover i, .color-style-3 .tab-link.active .tab-link-style-1 i {
    color: #dd4464;
}

.color-style-3 .swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1 .swiper-pagination-bullet.swiper-pagination-bullet-active, .color-style-3 .swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1 .swiper-pagination-bullet:hover {
    background: #dd4464;
    opacity: 1;
}

.color-style-3 .isotope-filter-list.type-2 li:hover, .color-style-3 .isotope-filter-list.type-2 li.active {
    background: #dd4464;
    border-color: #dd4464;
}

.color-style-3 .price-item-1.type-3:hover {
    background: #dd4464;
}

.color-style-3 .price-item-1.type-3:hover .price-val b, .color-style-3 .price-item-1.type-3:hover .price-val sub {
    color: #fff !important;
}

.color-style-3 .price-item-1.type-3 .price-val sub, .color-style-3 .price-item-1.type-3 .price-val b {
    color: #dd4464;
}

.color-style-3 .price-item-1.type-3:hover .button-style-1.type-1 {
    background: #222 !important;
}

.isotope-filter-list.type-2 li:hover, .isotope-filter-list.type-2 li.active {
    background: #111;
    border-color: #111;
    color: #fff;
}

.button-wrap {
    font-size: 0;
}

.button-wrap .button-wr {
    margin: 0 1.071rem;
    display: inline-block;
}

.video-open.type-1 {
    height: 32.14rem;
    position: relative;
    width: 100%;
}

.video-open.type-2 {
    height: 750px;
}

.section-divider {
    border-bottom: 1px solid #e3e3e3;
    position: relative;
}

.section-divider:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -17px;
    background: url(../img/home_30/section_sep.png) no-repeat;
    width: 37px;
    height: 2.143rem;
    margin-left: -18px;
    z-index: 2;
}

.tab-link > div {
    cursor: pointer;
}

.tab-button-1 {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 0;
}

.tab-button-1 .tab-link {
    position: relative;
    width: 25%;
    padding: 0 1.071rem;
    float: left;
}

.tab-link-style-1 {
    position: relative;
    width: 100%;
    min-height: 120px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    padding: 2.143rem 0px;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.tab-link-style-1 .title {
    color: #fff;
    display: block;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.tab-link-style-1 i {
    font-size: 26px;
    padding-bottom: 0.7143rem;
    display: block;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.tab-link-style-1:hover, .tab-link.active .tab-link-style-1 {
    background: #fff;
}

.tab-link-style-1:hover .title, .tab-link.active .tab-link-style-1 .title {
    color: #111;
}

.tab-link-style-1:hover i, .tab-link.active .tab-link-style-1 i {
    color: #111;
}

.tabs-item-container {
    position: relative;
    width: 100%;
    min-height: 32.14rem;
}

.tab-item {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.tab-item.active {
    visibility: visible;
    opacity: 1;
}

.isotope-filter-list.type-2 {
    width: 100%;
    text-align: center;
    font-size: 0;
}

.isotope-filter-list.type-2 li {
    display: inline-block;
    height: 3.571rem;
    line-height: 3.571rem;
    font-size: 14px;
    text-align: center;
    padding: 0px 2.143rem;
    margin: 0 1.071rem;
    border: 1px solid #e3e3e3;
    color: #989898;
    text-transform: capitalize;
}

.no-item {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 900;
    color: #989898;
    display: none;
}

.load-item {
    display: none;
    position: relative;
    width: 100%;
}

.contact-form.type-2 {
    position: relative;
    float: right;
    width: 100%;
}

.contact-form.type-2 .input-group {
    max-width: 395px;
    display: inline-block;
    float: left;
}

.contact-form.type-2 .input-group.input-style-1 {
    height: 3.571rem;
    line-height: 3.571rem;
}

.contact-form.type-2 .submit.btn {
    float: right;
    clear: right;
}

.benefit-image {
    position: relative;
    width: 100%;
    display: inline-block;
    padding: 0px 25px;
}

.benefit-text {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0px 25px;
}

.benefit-text .icon {
    font-size: 2.143rem;
    color: #222;
}

.mobile-filter-wrap.type-1 .change-text {
    border-color: #fff;
    color: #fff;
    text-align: center;
    font-size: 14px;
}

.mobile-filter-wrap.type-2 .change-text {
    border-color: #e3e3e3;
    color: #989898;
    text-align: center;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400;
}

@media (max-width: 1400px) {
    .offset-arrow-2 .swiper-arrow-right {
        right: -3.571rem;
    }

    .offset-arrow-2 .swiper-arrow-left {
        left: -3.571rem;
    }
}

@media (max-width: 1200px) {
    .subsc-item {
        text-align: center;
    }

    .subsc-item .contact-form.type-2 .input-group, .subsc-item .contact-form.type-2 .submit.btn {
        float: none;
        vertical-align: bottom;
        margin-left: 0.7143rem;
    }
}

@media (max-width: 992px) {
    .color-style-3 .header-style-2 .nav-link.active, .color-style-3 .header-style-2 .nav-link:hover {
        background: none;
    }

    .isotope-filter-list.tab-list {
        position: absolute;
    }

    .isotope-filter-list.tab-list .tab-link {
        width: 100%;
        padding: 0;
    }

    .isotope-filter-list.tab-list .tab-link i {
        display: none;
    }

    .isotope-filter-list.tab-list .tab-link-style-1 {
        min-height: 100%;
        border: 0 none;
        padding: 0.7143rem 0;
    }

    .isotope-filter-list.tab-list .tab-link-style-1 .title {
        color: #989898;
    }

    .isotope-filter-list.tab-list .tab-link.active .tab-link-style-1 .title {
        color: #222;
    }

    .vertical-align.no-align-md {
        position: relative;
        top: 0;
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
    }

    .benefit-text.text-left, .benefit-text.text-right {
        text-align: center;
    }

    .mobile-filter-wrap.type-2 .isotope-filter-list.type-2 li {
        height: 2.857rem;
        line-height: 2.857rem;
        width: 100%;
        margin: 0;
        border: 0 none;
    }

    .benefit-image, .benefit-text {
        padding: 0;
    }
}

@media (max-width: 768px) {
    .button-wrap .button-wr {
        display: block;
        margin: 0 0 1.071rem;
    }

    .show-point-sm {
        padding-bottom: 4.286rem;
    }

    .arrow-closest .show-point-sm .pagination.hidden {
        bottom: 0;
        display: block;
    }

    .tab-item {
        display: none;
        visibility: visible;
        opacity: 1;
        position: relative;
    }

    .tab-item.active {
        display: inline-block;
    }
}

@media (max-width: 554px) {
    .item-icon.type-3 {
        padding: 2.143rem 1.071rem;
    }

    .video-open.type-1 {
        height: 350px;
    }

    .subsc-item .contact-form.type-2 .input-group {
        margin-bottom: 2.143rem;
    }
}

/*======HOME PAGE 32======*/
.page-style-6 .button-style-1.type-1 {
    font-size: 12px;
    font-weight: 700;
}

.page-style-6 .footer-style-4 .caption-5 {
    font-size: 16px;
}

.page-style-6 .sub-title {
    text-transform: uppercase;
}

.page-style-6 .portfolio-item-1.type-2 .desc .caption-4 {
    text-transform: none;
}

.page-style-6 .portfolio-item-1.type-2 .sub-caption {
    font-style: italic;
    color: rgba(255, 255, 255, 0.7);
}

.page-style-6 .input-group.input-style-1 {
    font-style: italic;
}

.color-style-5 .header-style-2.fix {
    background: #fff;
}

.color-style-5 .header-style-2.fix .nav-link {
    color: #989898;
}

.color-style-5 .button-style-1.type-1 {
    background: #222;
    color: #fff;
}

.color-style-5 .button-style-1.type-1:hover {
    color: #fff;
    background: #eda6a6;
}

.color-style-5 .header-style-2 .nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.color-style-5 .header-style-2 .nav-link.active, .color-style-5 .header-style-2 .nav-link:hover {
    color: #fff;
    background: #f59a9a;
}

.color-style-5 .qoute-type, .color-style-5 .timeline-list {
    border-color: #eda6a6;
}

.color-style-5 .section-bg-style, .color-style-5 .title-separator, .color-style-5 .timeline-list .tl-style .point, .color-style-5 .item-img-half .image-title {
    background: #eda6a6;
}

.color-style-5 .sub-title.color-1, .color-style-5 .list-img i, .color-style-5 .list-img a:hover, .color-style-5 .title-icon, .color-style-5 .contact-sm-item .icon {
    color: #eda6a6;
}

.color-style-5 .service-item-2:hover .hold-layer-2 {
    background: rgba(237, 166, 166, 0.8);
}

.color-style-5 .swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.dark .swiper-pagination-bullet.swiper-pagination-bullet-active, .color-style-5 .swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.dark .swiper-pagination-bullet:hover {
    background: #eda6a6;
}

.color-style-5 .swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.dark .swiper-pagination-bullet {
    background: #e3e3e3;
}

.color-style-5 .item-img-half .desc-text.bg-section-3, .color-style-5 .client-item-2 {
    background: #fef7ed;
}

.color-style-5 .client-item-2 .sepp-line {
    background: #eda6a6;
}

.color-style-5 .portfolio-item-1.type-2:hover .layer {
    background: rgba(237, 166, 166, 0.8);
}

.color-style-5 .client-item-2:hover {
    background: #eda6a6;
}

.color-style-5 .client-item-2:hover .sepp-line {
    background: rgba(255, 255, 255, 0.5) !important;
}

.color-style-5 .client-item-2:hover .caption-5 {
    color: #fff;
}

.footer-style-4 {
    background: #fff;
    border-top: 1px solid #e3e3e3;
}

.footer-style-4 .footer-item .title, .footer-style-4 .recent-mini-link a {
    color: #222;
    text-transform: uppercase;
}

.footer-style-4 .recent-mini-link a:hover {
    color: #989898;
}

.footer-style-4 .footer-address li a:hover {
    color: #222;
}

.footer-style-4 .footer-address li, .footer-style-4 .recent-mini-link span, .footer-style-4 .footer-form .simple-text p {
    color: #989898;
}

.footer-style-4 .submit {
    background: #222;
    color: #fff;
    font-size: 11px;
}

.footer-style-4 .submit:hover {
    background: #989898;
}

.footer-style-4 .input-group.input-style-1 .form-control {
    font-style: italic;
    color: #989898;
}

.footer-style-4 .copyright {
    text-align: center;
    font-size: 1.071rem;
    font-weight: 700;
}

.footer-style-4 .copyright a {
    color: #222;
}

.footer-style-4 .copyright a:hover {
    color: #fda42a;
}

.footer-style-4 .copyright i {
    color: #fda42a;
}

.footer-style-4 .footer-address li:hover a {
    color: #111;
}

.footer-style-4.type-2 .input-style-1 input {
    font-style: normal;
}

.footer-style-4.type-2 .recent-mini-link span i {
    font-style: normal;
}

.footer-style-4.type-3 {
    border-top: 0 none;
}

.folow.type-5 a {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #fff;
    color: #222;
    display: block;
    float: left;
    margin: 0 10px 0 0;
    text-align: center;
    line-height: 28px;
}

.folow.type-5 a:hover {
    background: #222;
    color: #fff;
}

.section-wrap {
    position: relative;
    width: 100%;
}

.section-bg-style {
    position: absolute;
    left: 0px;
    top: 0;
    width: 33%;
    height: 100%;
}

.about-item .name {
    font-size: 13px;
    text-transform: uppercase;
    color: #222;
    display: block;
}

.item-img-half {
    position: relative;
    width: 100%;
    display: inline-block;
}

.item-img-half .bg {
    width: 50%;
    right: 0;
    left: auto;
}

.item-img-half .desc-text {
    position: relative;
    width: 50%;
    background: #fff;
    padding: 0 70px;
    z-index: 3;
}

.item-img-half.type-2 .bg, .item-img-half.type-2 .image {
    width: 40%;
}

.item-img-half.type-2 .desc-text {
    width: 60%;
}

.item-img-half.right .desc-text {
    float: right;
}

.item-img-half.right .image {
    position: absolute;
    z-index: 1;
    height: 100%;
    left: 0;
    bottom: 0;
}

.item-img-half.right .image .custome-align {
    position: absolute;
    left: -70px;
    bottom: 75px;
    max-height: 90%;
}

.item-img-half .image-title {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 75px;
    color: #fff;
    background: #111;
}

.item-img-half .image-title h4 {
    color: #fff;
    line-height: 75px;
}

.title-icon {
    font-size: 2.857rem;
}

.about-me .pagination {
    bottom: 3.571rem;
}

.time-row {
    position: relative;
    display: inline-block;
    width: 100%;
}

.timeline-list {
    position: relative;
    width: 100%;
    border-left: 1px solid #111;
}

.timeline-list .timeline-date {
    position: relative;
    float: left;
    width: 30%;
}

.timeline-list .timeline-event {
    position: relative;
    float: left;
    width: 70%;
}

.timeline-list .tl-style {
    padding: 0 2.143rem;
}

.timeline-list .tl-style span {
    display: block;
    font-size: 13px;
    color: rgba(119, 119, 119, 0.7);
    padding-bottom: 1.071rem;
}

.timeline-list .tl-style .point {
    position: absolute;
    left: 5px;
    top: 35px;
    width: 0.7143rem;
    height: 1px;
    background: #111;
}

.timeline-list .tl-style .point:before {
    content: '';
    position: absolute;
    left: -11px;
    top: -5px;
    width: 11px;
    height: 11px;
    background: inherit;
    border: 3px solid #fff;
}

.timeline-list .tl-style .title {
    padding-bottom: 5px;
}

.bg-section-3.desc-text .timeline-list .tl-style .point:before {
    border-color: #fef7ed;
}

.team-home32 .swiper-arrow-right {
    color: #adadad;
}

.team-home32 .swiper-arrow-left {
    color: rgba(255, 255, 255, 0.7);
}

.team-slide-home32 {
    position: relative;
    width: 100%;
    padding: 155px 0;
}

.team-slide-home32 .image {
    position: absolute;
    right: 30px;
    top: 0;
}

.team-slide-home32 .desc-text {
    padding: 0px 60px;
}

.team-slide-home32 .desc-text .text {
    position: relative;
    width: 44%;
}

.team-slide-home32 .desc-text .text .sub-caption {
    text-transform: uppercase;
    color: rgba(85, 85, 85, 0.7);
}

.team-slide-home32 .desc-text .text .simple-text p span {
    color: #111;
    padding: 0 5px 0 0;
}

.client-item-2 {
    text-align: center;
    position: relative;
    width: 100%;
    padding: 0 0 45px;
    cursor: pointer;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.client-item-2 .sepp-line {
    display: block;
    width: 50px;
    height: 1px;
    margin: 0 auto;
    background: #111;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.client-item-2 .image {
    position: relative;
    width: 100%;
    height: 165px;
    display: inline-block;
    text-align: center;
}

.client-item-2 .image img {
    max-width: 100%;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: block;
    margin: 0 auto;
}

.client-item-2 .caption-5 {
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.client-item-2 .image .hove-img {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
    z-index: 5;
}

.client-item-2:hover .image img {
    opacity: 0;
}

.client-item-2:hover .image .hove-img {
    opacity: 1;
}

.client-home-32 .swipe-arrow {
    color: #aeaeae;
}

.client-home-32 .swipe-arrow:hover {
    color: #222;
}

.contact-bg-block {
    position: relative;
    background: #fff;
    text-align: center;
    float: left;
}

.contact-bg-block:first-child {
    border-right: 1px solid #e3e3e3;
}

.contact-bg-block .contact-form {
    padding: 0 70px;
}

@media (max-width: 1300px) {
    .client-home-32 .swipe-arrow {
        display: none;
    }

    .client-home-32 .pagination.hidden {
        display: block;
    }

    .client-home-32 .swiper-container {
        padding-bottom: 60px;
    }
}

@media (max-width: 1200px) {
    .wide-lg.type-2 .desc-text, .wide-lg.type-2 .bg, .wide-lg .desc-text, .wide-lg .bg {
        width: 100%;
    }

    .wide-lg.item-img-half.right .image {
        position: relative;
        width: 100%;
        height: auto;
    }

    .wide-lg.item-img-half.right .image .custome-align {
        position: relative;
        display: block;
        max-width: 80%;
        margin: 0 auto;
        left: auto;
        bottom: auto;
        max-height: 100%;
    }
}

@media (max-width: 992px) {
    .item-img-half .desc-text, .contact-bg-block .contact-form {
        padding: 0 30px;
    }

    .wide-md.type-2 .desc-text, .wide-md.type-2 .bg, .wide-md .desc-text, .wide-md .bg, .wide-md.item-img-half.type-3 .image {
        width: 100%;
    }

    .md-300 {
        height: 300px;
        position: relative;
        display: block;
    }

    .team-slide-home32 {
        padding: 100px 0;
    }

    .team-slide-home32 .image img {
        width: 100%;
        height: auto;
    }

    .team-slide-home32 .image {
        max-width: 40%;
        top: auto;
        bottom: 0;
    }

    .item.col-33 {
        width: 50%;
    }

    .color-style-5 .header-style-2 .nav-link.active, .color-style-5 .header-style-2 .nav-link:hover {
        background: none;
        color: #eda6a6;
    }

    .color-style-5 .header-style-2 .nav-link, .color-style-5 .drop-link .icon {
        color: #989898;
    }

    .price-item-1.type-2 {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .section-bg-style {
        background: none !important;
    }

    .about-me .pagination {
        bottom: 30px;
    }

    .wide-sm .desc-text, .wide-sm .bg {
        width: 100%;
    }

    .sm-300 {
        height: 300px;
        position: relative;
    }

    .timeline-list .tl-style {
        padding: 0 20px;
    }

    .item-img-half .desc-text {
        padding: 0 15px;
    }

    .team-slide-home32 .image {
        display: none;
    }

    .team-slide-home32 {
        padding: 0 0 80px;
    }

    .team-slide-home32 .desc-text .text {
        width: 100%;
    }

    .team-home32.arrow-closest .pagination.hidden {
        bottom: 80px;
    }

    .team-slide-home32 .desc-text {
        padding: 0 30px;
    }

    .about-item.padd-custome-50 {
        padding: 0;
    }
}

@media (max-width: 544px) {
    .team-slide-home32 {
        padding: 0 0 70px;
    }

    .team-home32.arrow-closest .pagination.hidden {
        bottom: 70px;
    }

    .item.col-33 {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .timeline-list .timeline-date {
        width: 100%;
        padding-bottom: 20px;
    }

    .timeline-list .timeline-event {
        width: 100%;
    }

    .item-img-half .image-title h4 {
        font-size: 14px;
    }
}

/*======HOME PAGE 12======*/
.padd-styled-bl {
    padding: 0px 0px 102px;
}

.padd-styled-bl .swiper-pagination-bullets {
    bottom: 165px;
}

.color-style-7 .styled-block.type-1:before {
    background: #ffbc65;
}

.color-style-7 .styled-block.type-1:after {
    border-color: #ffbc65;
}

.footer-style-3.type-2 {
    background: #222;
}

.footer-style-3.type-2 .copyright {
    color: #d2d2d2;
}

.footer-style-3.type-2 .folow.type-1 a {
    border-color: rgba(255, 255, 255, 0.2);
}

.color-style-7 .swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.dark .swiper-pagination-bullet.swiper-pagination-bullet-active, .color-style-7 .border-block.type-2 .image, .color-style-7 .styled-block.type-4 .styled-block-wrap, .color-style-7 .swiper-slide.active .preview-team {
    background: #ffbc65;
}

.color-style-7 .styled-block.type-2, .color-style-7 .styled-block.type-3, .color-style-7 .styled-block.type-5 {
    border-color: #ffbc65;
}

.color-style-7 .styled-block.type-2 .styled-block-wrap, .color-style-7 .styled-block.type-3 .styled-block-wrap, .color-style-7 .styled-block.type-5 .styled-block-wrap {
    background: #ffbc65;
}

.color-style-7 .border-block, .color-style-7 .team-item-4, .color-style-7 .styled-block.type-4, .color-style-7 .blog-item-4 .date {
    border-color: #ffbc65;
}

.color-style-7 .button-style-1.dark {
    background: #111;
    color: #fff;
}

.color-style-7 .button-style-1.dark:hover {
    background: #ffbc65;
}

.color-style-7 .button-style-1.type-1 {
    background: #fff;
    color: #222;
}

.color-style-7 .button-style-1.type-1:hover {
    background: #ffbc65;
    color: #fff;
}

.kineticjs-content {
    box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.2);
    border-radius: 50%;
}

.color-style-7 .price-item-1.type-2:hover .button-style-1.type-1 {
    background: #222;
    color: #fff;
}

.color-style-7 .price-item-1.type-2 .button-style-1.type-1:hover {
    background: #ffbc65;
    color: #fff;
}

.color-style-7 .tab-button-2 .tab-link.active, .color-style-7 .tab-button-2 .tab-link:hover, .color-style-7 .header-style-1.type-2 .navbar-nav .nav-item:hover .nav-link, .color-style-7 .header-style-1.type-2 .navbar-nav .nav-item.active .nav-link {
    background: #ffbc65;
    color: #fff;
}

.color-style-7 .item-icon.type-5 .icon i, .color-style-7 .item-icon.type-4.info-type .icon b, .color-style-7 .benefit-text .sub-caption, .color-style-7 .blog-item-4 .title a:hover, .color-style-7 .benefit-text .caption-4 a:hover {
    color: #ffbc65;
}

.color-style-7 .button-style-4 {
    color: #222;
    border-color: #222;
}

.color-style-7 .button-style-4:hover {
    border-color: #ffbc65;
    color: #ffbc65;
}

.color-style-7 .header-style-1.type-2 .dropdown-menu .drop-link .icon {
    top: 0px;
    color: #111;
}

.color-style-7 .header-style-1.type-2 .dropdown-menu .drop-link:hover .icon {
    color: #111;
}

.page-style-2 .button-style-1 {
    font-size: 12px;
}

.page-style-2 .caption-2.sm.text-left {
    text-align: left;
}

.page-style-2 .title-style-6.type-2 .sub-caption-line {
    color: #989898;
}

.page-style-2 .item-icon.type-4.info-type .icon b {
    font-weight: 400;
}

.page-style-2 .button-style-4 {
    font-size: 10px;
    font-weight: 700;
    background: none;
}

.page-style-2 .header-style-1 .nav-item {
    font-weight: 900;
    color: #222;
    text-transform: uppercase;
    font-size: 20px;
    position: relative;
}

.page-style-2 .mobile-filter-wrap.type-2 .change-text {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 12px;
}

.page-style-2 .recent-mini-link .caption-6 {
    text-transform: none;
}

.footer-address li b {
    text-transform: uppercase;
    color: #fff;
    padding-right: 5px;
}

.styled-block {
    position: relative;
    width: 100%;
    background: #fff;
    z-index: 1;
}

.styled-block.type-1:before {
    content: '';
    position: absolute;
    left: -30px;
    bottom: -30px;
    width: calc(100% + 60px);
    height: calc(100% - 70px);
    background: #222;
}

.styled-block.type-1:after {
    content: '';
    position: absolute;
    left: -100px;
    width: calc(100% + 200px);
    height: 104%;
    border: 1px solid #ffbc65;
    top: 70px;
}

.styled-block .wrap {
    position: relative;
    width: 100%;
    z-index: 1;
    background: #fff;
    padding: 0px 70px;
}

.styled-block.type-2 {
    height: 440px;
    border: 1px solid #222;
    position: relative;
    margin-top: 70px;
    max-width: 670px;
    margin-bottom: 30px;
    width: 100%;
}

.styled-block.type-2 .styled-block-wrap {
    bottom: 70px;
}

.styled-block.type-2 .image img {
    position: absolute;
    max-width: 100%;
}

.styled-block.type-2 .image img:nth-child(1) {
    left: 100px;
    top: -70px;
}

.styled-block.type-2 .image img:nth-child(2) {
    top: 70px;
    right: -100px;
}

.styled-block.type-3 {
    position: absolute;
    right: 100px;
    left: 100px;
    height: 100%;
    border: 1px solid #222;
    width: auto;
    margin-top: 40px;
}

.styled-block.type-3 .styled-block-wrap {
    bottom: 70px;
}

.styled-block .styled-block-wrap {
    background: #111;
    position: absolute;
    left: 70px;
    top: 30px;
    right: 70px;
    bottom: 30px;
}

.styled-block.type-4 {
    text-align: center;
    border: 1px solid #222;
}

.styled-block.type-4 .styled-block-wrap {
    position: relative;
    display: block;
    max-width: 430px;
    margin: 30px auto 0;
    left: 0;
    right: 0;
    top: 0;
    min-height: 300px;
}

.styled-block.type-4 .styled-block-wrap img {
    margin-top: -100px;
}

.testi-slider-home12 {
    padding-top: 70px;
}

.testi-slider-home12 .pagination {
    bottom: 60px;
}

.focus.focus-1.input-style-1:before {
    width: 100%;
    background: #111;
}

.focus.focus-2.input-style-1:before {
    width: 100%;
    background: #fff;
}

.letter {
    position: absolute;
    left: 50%;
    font-size: 170px;
    line-height: 170px;
    top: 50%;
    color: rgba(34, 34, 34, 0.1);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-weight: 600;
    z-index: 50;
}

.color-1 > .letter {
    color: rgba(255, 255, 255, 0.1);
}

.border-item {
    position: relative;
    display: inline-block;
    width: 100%;
}

.border-item .col-33 {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: #222;
}

.border-item .col-33:nth-child(4), .border-item .col-33:nth-child(5), .border-item .col-33:nth-child(6) {
    border-bottom: 0 none;
}

.border-item .col-33:nth-child(1), .border-item .col-33:nth-child(4) {
    border-left: 0 none;
}

.tabs-item-container.type-2 {
    min-height: 100%;
}

.tabs-item-container.type-2 .tab-item {
    border: 1px solid #e3e3e3;
    display: none;
    position: relative;
}

.tabs-item-container.type-2 .tab-item .text {
    position: relative;
    width: 55%;
    padding-left: 60px;
}

.tabs-item-container.type-2 .tab-item .image-abs {
    position: absolute;
    right: 0;
    width: 45%;
}

.tabs-item-container.type-2 .tab-item .image-abs img {
    max-width: 100%;
    position: absolute;
    bottom: 0;
    right: 60px;
}

.tabs-item-container.type-2 .tab-item.active {
    display: block;
}

.tab-button-2 {
    border: 1px solid #e3e3e3;
    border-right: 0 none;
}

.tab-button-2 .tab-link {
    min-height: 70px;
    line-height: 70px;
    padding: 0 30px 0px 50px;
    color: #989898;
    text-align: left;
    background: none;
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
    position: relative;
    color: #989898;
}

.tab-button-2 .tab-link:last-child {
    border-bottom: 0 none;
}

.tab-button-2 .tab-link.active, .tab-button-2 .tab-link:hover {
    background: #111;
    color: #fff;
}

.border-block.type-2 {
    padding: 65px 30px 65px 100px;
    width: auto;
    margin-right: 70px;
    border-width: 1px;
}

.border-block.type-2 .info-item-slider {
    padding-right: 65px;
}

.border-block.type-2 .border-padd {
    position: relative;
    width: 100%;
    padding-left: 60px;
    border-left: 1px solid rgba(152, 152, 152, 0.5);
}

.border-block.type-2 .image {
    border-left: 1px solid rgba(152, 152, 152, 0.5);
    min-height: 440px;
    position: relative;
    width: 100%;
    padding-left: 60px;
}

.border-block.type-2 .image img {
    position: absolute;
    left: 30px;
    top: 30px;
}

.slider-home12 {
    position: relative;
    width: 100%;
    height: 620px;
}

.slider-home12 .swiper-container {
    padding-top: 100px;
    margin-top: -40px;
    padding-bottom: 100px;
}

.slider-home12 .swiper-slide {
    width: auto;
}

.slider-home12 .swiper-slide:nth-child(2n+1) .image {
    margin-top: -100px;
}

.slider-home12 .swiper-slide .image {
    display: block;
}

.push-item.type-2 {
    font-size: 0;
}

.push-item.type-2 .benefit-image {
    padding: 0;
}

.push-item-wrapp {
    border: 1px solid #e3e3e3;
}

.timer-block {
    position: relative;
    width: 100%;
    background: #222;
}

.timer-block .top-desc {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 40px;
}

.clock-item .text {
    color: #fff;
    position: relative;
    text-align: center;
}

.clock-item .val {
    font-weight: 700;
    font-size: 42px;
    z-index: 5;
    color: #fff;
}

.circle-canva {
    position: relative;
    display: inline-block;
    width: 100%;
}

.clock-item {
    padding-left: 0px;
    padding-right: 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.clock-item .clock-canvas {
    max-width: 130px;
    display: block;
    margin: 0 auto;
}

.kineticjs-content {
    width: 130px !important;
    height: 130px !important;
}

.item-img-half .desc-text.bg-section-1 {
    background: #111;
}

.testi-block.type-2.title-style-4 .simple-text span {
    display: inline-block;
}

.cl-logo-wrap-3 {
    border: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    width: 100%;
    position: relative;
    display: inline-block;
}

.cl-logo-wrap-3 a {
    min-height: 225px;
    width: 33.333%;
    position: relative;
    float: left;
    display: block;
    position: relative;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.cl-logo-wrap-3 a img {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.cl-logo-wrap-3 a:nth-child(7), .cl-logo-wrap-3 a:nth-child(8), .cl-logo-wrap-3 a:nth-child(9) {
    border-bottom: 0 none;
}

.cl-logo-wrap-3 a:nth-child(1), .cl-logo-wrap-3 a:nth-child(4), .cl-logo-wrap-3 a:nth-child(7) {
    border-left: 0 none;
}

.team-swich-link .preview-team {
    width: 100%;
    padding: 25px 15px;
    cursor: pointer;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.team-swich-link .preview-team .sub-caption {
    color: #989898;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.team-swich-link {
    text-align: center;
    height: 380px;
    margin-top: 100px;
}

.swiper-slide.active .preview-team {
    background: #222;
    color: #fff;
}

.swiper-slide.active .preview-team .sub-caption {
    color: rgba(255, 255, 255, 0.7);
}

.team-slider-home12 {
    padding-bottom: 159px;
    padding-top: 50px;
}

.team-slider-home12 .swiper-slide {
    padding: 0 1px;
}

.swiper-arrow-left.swipe-arrow.vertical-type {
    top: -60px;
}

.swiper-arrow-right.swipe-arrow.vertical-type {
    top: auto;
}

.swipe-arrow.vertical-type {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.styled-block.type-5 {
    border: 1px solid #222;
    display: block;
    position: relative;
    width: 100%;
    max-width: 995px;
    margin: 65px auto 0;
}

.styled-block.type-5 .styled-block-wrap {
    max-height: 400px;
}

.styled-block.type-5 .contact-form {
    border: 1px solid #e3e3e3;
    padding: 0 70px;
    display: block;
    max-width: 780px;
    margin: 0 auto;
    background: #fff;
    z-index: 5;
    position: relative;
    margin-top: -65px;
}

.header-style-1.type-2 .mobile-menu {
    position: fixed;
    top: 50%;
    right: 50px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    height: 50px;
    z-index: 600;
    -webkit-transition: none;
    transition: none;
}

.header-style-1.type-2 .mobile-menu.active i:before, .header-style-1.type-2 .mobile-menu.active i:after {
    background: #222;
}

.header-style-1.type-2 .nav-list {
    background: #fff;
    padding: 254px 0px;
    z-index: 500;
}

.header-style-1.type-2 .navbar-nav .nav-link {
    padding: 0 80px;
}

.header-style-1.type-2 .navbar-brand {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    display: block;
    z-index: 5;
    position: fixed;
    left: 80px;
    top: 50%;
    margin-top: 50px;
    -webkit-transition: none;
    transition: none;
}

.header-style-1.type-2 .navbar-brand img {
    position: absolute;
}

.header-style-1.type-2 .nav-item .dropdown-menu {
    position: relative;
    margin: 0 auto;
    visibility: visible;
    left: auto;
    top: auto;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    float: none;
    width: 100%;
    display: none;
    visibility: visible;
    opacity: 1;
    text-align: center;
    border: 0 none;
}

.header-style-1.type-2 .drop-link .icon {
    color: #111;
    padding-left: 0;
    cursor: pointer;
    z-index: 500;
    line-height: 50px;
    height: 50px;
    vertical-align: middle;
    width: 50px;
    text-align: center;
    position: absolute;
    display: block;
    right: 0px;
    z-index: 5;
    top: 0;
}

.item-img-half .benefit-image {
    padding: 0;
}

.header-style-1.type-2 .drop-link.active .icon, .header-style-1.type-2 .drop-link:hover .icon {
    color: #fff;
}

.header-style-1.fix.type-2 .navbar-brand {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    top: 0;
    position: absolute;
    margin-top: 15px;
}

.header-style-1.fix.type-2 .mobile-menu {
    position: absolute;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    top: 5px;
    right: 0;
}

.header-style-1.fix.type-2 .navbar-brand.logo-mobile {
    left: 100px;
}

.col-515 {
    position: relative;
    float: left;
    width: 28.5%;
}

.col-800 {
    position: relative;
    float: left;
    width: 42%;
}

@media (max-width: 1800px) {
    .col-515 {
        width: 100%;
        text-align: center;
    }

    .col-515 .title-style-6 .simple-text {
        margin: 0 auto;
        display: block;
    }

    .col-515 .title-style-6 {
        margin-top: 90px;
    }

    .col-800 {
        width: 100%;
        margin-top: 70px;
    }

    .col-800 .styled-block.type-2 {
        margin: 70px auto 0;
    }

    .page-style-2 .col-515 .caption-2.sm.text-left {
        text-align: center;
    }
}

@media (max-width: 1400px) {
    .header-style-1.type-2 .navbar-brand {
        left: 40px;
    }

    .header-style-1.type-2 .mobile-menu {
        right: 30px;
    }

    .styled-block.type-1:after {
        display: none;
    }

    .header-style-1.type-2 .nav-list {
        padding: 130px 0;
    }
}

@media (max-width: 1200px) {
    .tabs-item-container.type-2 .tab-item .text {
        padding-left: 30px;
    }

    .border-block.type-2 {
        padding: 50px 30px 50px 0;
    }
}

@media (max-width: 992px) {
    .header-style-1.fix.type-2 {
        background: #111;
    }

    .header-style-1.type-2 .navbar-brand {
        top: 50%;
        margin-top: 0;
        -webkit-transform: rotate(0deg) translateY(-50%);
        transform: rotate(0deg) translateY(-50%);
        position: absolute;
        left: 0;
    }

    .header-style-1.type-2 .mobile-menu {
        top: 50%;
        margin-top: 0;
        -webkit-transform: rotate(0deg) translateY(-50%);
        transform: rotate(0deg) translateY(-50%);
        position: absolute;
        right: 0;
    }

    .header-style-1.type-2 .navbar-brand img {
        position: relative;
    }

    .header-style-1.type-2 .logo-mobile.navbar-brand {
        display: none;
    }

    .padd-styled-bl .swiper-pagination-bullets {
        bottom: 0;
    }

    .padd-styled-bl {
        padding-bottom: 60px;
    }

    .styled-block .wrap {
        padding: 0 30px;
    }

    .styled-block.type-2 .image img:nth-child(2) {
        right: 0;
    }

    .styled-block.type-2 .image img:nth-child(1) {
        left: 0;
    }

    .styled-block.type-2 {
        margin: 70px auto 0;
    }

    .border-item.size-1 .col-33 {
        width: 50%;
    }

    .border-item.size-1 .col-33:nth-child(5) {
        clear: both;
    }

    .border-item.size-1 .col-33:nth-child(3) {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    .border-item .col-33:nth-child(4) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .mobile-rel .tab-item {
        position: relative;
        width: 100%;
        display: none;
    }

    .mobile-rel .tab-item.active {
        display: inline-block;
    }

    .tab-button-2 .tab-link {
        min-height: 40px;
        line-height: 40px;
        text-align: center;
    }

    .isotope-filter-list.tab-list.tab-button-2 {
        width: 100%;
    }

    .tabs-item-container.type-2 .tab-item .text {
        padding: 0 15px;
    }

    .styled-block.type-1 {
        margin-top: 0;
    }

    .border-block.type-2 {
        margin-right: 0;
    }

    .border-block.type-2 .border-padd {
        padding-left: 30px;
    }

    .border-block.type-2 .image img {
        position: relative;
        width: 100%;
        left: 0;
    }

    .border-block.type-2 .image {
        padding: 0 15px;
    }

    .slider-home12 .swiper-slide .image img {
        height: 300px;
        width: auto;
    }

    .slider-home12 .swiper-slide:nth-child(2n+1) .image {
        margin-top: 0;
    }

    .slider-home12 .swiper-container {
        padding: 0;
        margin-top: 0px;
        padding-bottom: 60px;
    }

    .styled-block.type-3 {
        right: 30px;
        left: 30px;
    }

    .slider-home12 {
        height: auto;
    }

    .styled-block.type-3 {
        display: none;
    }

    .team-item-4 .image {
        position: relative;
        bottom: 0;
        width: 100%;
        text-align: center;
    }

    .team-item-4 .image img {
        margin: 0 auto 30px;
    }

    .team-item-4 .desc {
        width: 100%;
        padding: 0 30px;
        margin: 0 auto;
        text-align: center;
        max-width: 100%;
    }

    .testi-slider-home12 .pagination {
        bottom: 0;
    }

    .testi-slider-home12 {
        padding-bottom: 60px;
        padding-top: 0;
        margin-bottom: 0;
    }

    .styled-block.type-4 .styled-block-wrap img {
        margin-top: 0;
    }

    .styled-block.type-5 {
        margin-top: 0;
    }

    .styled-block.type-5 .styled-block-wrap {
        display: none;
    }

    .styled-block.type-5 .contact-form {
        border: 0 none;
    }

    .styled-block.type-5 .contact-form {
        margin-top: 0px;
    }

    .styled-block.type-5 .contact-form {
        padding: 0 30px;
    }

    .header-style-1.type-2 .nav-list {
        padding: 100px 0;
    }

    .team-item-4 .share-link {
        float: none;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets.vertical-type.center {
        margin-left: 0;
    }

    .col-800, .col-515 .title-style-6 {
        margin-top: 0px;
    }

    .col-800 {
        padding: 0 15px;
    }
}

@media (max-width: 768px) {
    .letter {
        font-size: 110px;
        line-height: 110px;
    }

    .border-item.size-1 .col-33 {
        width: 100%;
        height: auto;
    }

    .tabs-item-container.type-2 .tab-item .text {
        width: 100%;
    }

    .tabs-item-container.type-2 .tab-item .image-abs {
        position: relative;
        width: 100%;
        text-align: center;
    }

    .tabs-item-container.type-2 .tab-item .image-abs img {
        position: relative;
        right: 0;
        margin: 0 auto;
        display: block;
    }

    .border-block.type-2 .border-padd, .team-item-4 .desc, .styled-block.type-5 .contact-form {
        padding: 0 15px;
    }

    .border-block.type-2 .image {
        min-height: 100%;
    }

    .border-block.type-2 {
        padding: 50px 0;
    }

    .page-style-2 .caption-2 {
        font-size: 30px;
        line-height: 36px;
    }

    .styled-block.type-4 .styled-block-wrap, .color-style-7 .styled-block.type-4 .styled-block-wrap {
        background: none;
        margin-top: 0;
    }

    .styled-block.type-4 .styled-block-wrap img, .color-style-7 .styled-block.type-4 .styled-block-wrap img {
        width: 100%;
    }

    .color-style-7 .styled-block.type-2 .styled-block-wrap, .styled-block.type-2 .styled-block-wrap {
        background: none;
    }

    .border-item.size-1 .col-33:nth-child(5) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .clock-item:nth-child(1), .clock-item:nth-child(2) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .col-800 .styled-block.type-2 {
        margin-top: 0;
    }

    .main-baner .styled-block.type-1 {
        text-align: center;
    }
}

@media (max-width: 554px) {
    .styled-block.type-1:before {
        display: none;
    }

    .styled-block .wrap {
        padding: 0 15px;
    }

    .cl-logo-wrap-3 a {
        width: 100%;
        min-height: 150px;
    }

    .cl-logo-wrap-3 a:nth-child(7), .cl-logo-wrap-3 a:nth-child(8) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .timer-block .top-desc {
        border-bottom: 0 none;
    }

    .timer-block .top-desc {
        padding: 0 15px;
    }

    .blog-item-4 .date {
        position: relative;
        width: 100px;
        padding: 20px 15px;
        display: block;
        margin: 30px auto 0;
    }

    .blog-item-4 .date span {
        font-size: 16px;
        line-height: 20px;
    }

    .blog-item-4 .text {
        padding-left: 0;
        text-align: center;
    }

    .blog-item-4 .image {
        margin-left: 0;
    }

    .styled-block.type-2 {
        margin-top: 0;
        height: auto;
    }

    .styled-block.type-2 .image img {
        position: relative;
    }

    .styled-block.type-2 .image img:nth-child(2), .styled-block.type-2 .image img:nth-child(1) {
        top: 0;
    }

    .clock-item:nth-child(1), .clock-item:nth-child(2) {
        border-bottom: 0 none;
    }

    .clock-item {
        border-left: 0 none;
    }

    .styled-block.type-2 .image img {
        display: block;
        margin: 0 auto;
    }
}

@media (max-width: 480px) {
    .blog-item-4 .date {
        border: 0 none;
        margin: 0 auto;
        padding-bottom: 0px;
    }
}

/*======HOME PAGE 13======*/
.color-style-12 .header-style-2 .nav-link.active, .color-style-12 .header-style-2 .nav-link:hover {
    background: #222;
    color: #fff;
}

.color-style-12 .button-style-1.type-2 {
    background: #fff;
    color: #222;
}

.color-style-12 .button-style-1.type-2:hover {
    background: #eafbf4;
    color: #222;
}

.color-style-12 .bg-block-2, .color-style-12 .swiper-slide:hover .cl-item, .color-style-12 .swiper-slide.active .cl-item, .color-style-12 .price-item-2.type-1, .color-style-12 .team-item-3:hover .desc {
    background: #eafbf4;
}

.page-style-9.color-style-2 .team-item-3:hover .desc {
    background: #fbfbfb !important;
}

.page-style-9.color-style-2 .share-link.type-2 a:hover {
    background: #fff !important;
}

.color-style-12 .button-style-1.type-1 {
    background: #222;
    color: #fff;
}

.color-style-12 .button-style-1.type-1:hover {
    background: #eafbf4;
    color: #222;
}

.color-style-12 .item-icon.type-2 .number {
    color: #222 !important;
}

.color-style-12 .price-item-2:hover .button-style-1.type-1 {
    background: #fff;
    color: #222;
}

.color-style-12 .price-item-2 .button-style-1.type-1:hover {
    background: #eafbf4;
    color: #222;
}

.color-style-12 .preview-portfolio .layer {
    background: rgba(234, 251, 244, 0.6);
}

.color-style-12 .swipe-arrow:hover {
    color: #222;
}

.color-style-12 .blog-item-5 .layer {
    background: rgba(234, 251, 244, 0.8);
}

.color-style-12 div[class*="title-style"] .sub-title {
    color: #989898;
}

.color-style-12 div[class*="title-style"] .sub-title.color-2 {
    color: rgba(255, 255, 255, 0.7);
}

.color-style-12 .accordeon-style-img .accordeon-triger i {
    border-color: #eafbf4;
}

.color-style-12 .swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.dark .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #111;
}

.color-style-12 .footer-style-2 .copyright i {
    color: #ca821f;
}

.price-item-2.type-1:hover {
    background: #222 !important;
}

.page-style-5 .header-style-2 .nav-item {
    font-size: 12px;
    font-weight: 900;
    float: none;
    display: inline-block;
}

.page-style-5 .header-style-2 .nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.page-style-5 .button-style-1 {
    font-size: 12px;
    font-weight: 900;
}

.page-style-5 .footer-form .submit {
    font-size: 11px;
    font-weight: 900;
}

.header-style-2.type-3 {
    position: absolute;
    left: 0px;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 500;
    height: 100px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.logo-baner {
    position: absolute;
    left: 50%;
    top: 50px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 5;
}

.title-style-2.type-2 .title-separator {
    background: #fff;
    top: 50px;
}

.title-style-2.type-2 .title-separator:after {
    display: none;
}

.title-style-2.type-2 .line-style {
    position: relative;
}

.title-style-2.type-2 .line-style:after {
    content: '';
    position: absolute;
    top: 35px;
    width: 130px;
    height: 1px;
    background: #fff;
    left: -145px;
}

.image-top-section {
    position: relative;
}

.bg-block-2 {
    position: relative;
}

.play-button.lg {
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 14px;
    color: #222;
}

.play-button.lg:before {
    box-shadow: 0 0 0 20px rgba(234, 251, 244, 0.2);
}

.play-button.lg.model-style-play .line-play:before {
    height: 50px;
    top: -24px;
}

.play-button.lg.model-style-play .line-play:after {
    height: 20px;
    top: 27px;
}

.play-button.lg:hover {
    color: #fff;
}

div[class*="title-style"] .sub-title {
    text-transform: uppercase;
    font-size: 13px;
}

.section-title {
    position: absolute;
    top: 50%;
    left: 100px;
    text-align: center;
    z-index: 5;
}

.section-title .title {
    display: inline-block;
    text-transform: uppercase;
}

.section-title.color-2 .title {
    color: #222;
}

.section-title.color-1 .title {
    color: #fff;
}

.section-title .sepp {
    position: relative;
    width: 130px;
    height: 1px;
    display: inline-block;
    margin-bottom: 4px;
}

.section-title.color-2 .sepp {
    background: #000;
}

.section-title.color-1 .sepp {
    background: #fff;
}

.section-title.align-custome {
    margin-top: -120px;
}

.rotate {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}

.about-slider-home13 {
    position: absolute;
    width: 100%;
    max-width: 470px;
    top: -65px;
    right: -100px;
}

.accordeon-style-img .accordeon-triger {
    width: 100%;
    display: block;
    height: 64px;
    border: 1px solid rgba(255, 255, 255, 0);
    position: relative;
    background: #fff;
    margin-bottom: 1px;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.accordeon-style-img .accordeon-triger i {
    width: 70px;
    line-height: 62px;
    color: #222;
    text-align: center;
    font-size: 20px;
    border-right: 1px solid rgba(255, 255, 255, 0);
    position: relative;
    z-index: 5;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.accordeon-style-img .accordeon-triger .title {
    padding-left: 30px;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.accordeon-style-img .accordeon-triger:hover {
    background: #111;
    border-color: #111;
    border-top-color: #111;
    z-index: 5;
}

.accordeon-style-img .accordeon-triger:hover i {
    background: #fff;
}

.accordeon-style-img .accordeon-triger:hover .title {
    color: #fff;
}

.accordeon-item:first-child .accordeon-inner {
    display: block;
}

.accordeon-inner {
    display: none;
    padding: 25px;
}

.accordeon-triger span {
    position: absolute;
    right: 1.154rem;
    top: 0;
    width: 3.462rem;
    height: 100%;
}

.accordeon-triger span:after, .accordeon-triger span:before {
    content: '';
    display: block;
    position: absolute;
    background: #989898;
    top: 50%;
}

.accordeon-triger span:after {
    right: 0;
    margin-top: -0.5px;
    width: 15px;
    height: 1px;
}

.accordeon-triger span:before {
    right: 8px;
    margin-top: -7.5px;
    width: 1px;
    height: 15px;
}

.accordeon-style-img .accordeon-item.active .accordeon-triger {
    background: #111;
    border-color: #111;
    border-top-color: #111;
    z-index: 5;
}

.accordeon-style-img .accordeon-item.active .accordeon-triger i {
    background: #fff;
}

.accordeon-style-img .accordeon-item.active .accordeon-triger .title {
    color: #fff;
}

.accordeon-item.active .accordeon-triger span:before {
    opacity: 0;
}

.accordeon-item.active .accordeon-triger span:after, .accordeon-item:hover .accordeon-triger span:after {
    background: rgba(255, 255, 255, 0.7);
}

.service-item-4 {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #989898;
}

.service-item-4 .image {
    width: 250px;
    position: absolute;
    left: 0;
    top: 0;
}

.service-item-4 .text {
    padding-left: 280px;
}

.img-sh {
    box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.15);
}

.item-icon-bd {
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
}

.item-icon-bd.sm {
    width: 50px;
}

.item-icon-bd.dark {
    background: #111;
}

.item-icon .number {
    font-size: 60px;
    color: #222;
    line-height: 60px;
    font-weight: 700;
}

.full-swiper {
    width: 100%;
    height: 100%;
}

.full-swiper .pagination {
    bottom: 50px;
}

.item-img-half.type-4 .image {
    position: absolute;
    right: 0;
    top: 0;
    width: 40%;
    height: 100%;
    z-index: 50;
}

.item-img-half.type-4 .desc-text {
    width: 100%;
    background: none;
}

.item-img-half.type-4 .bg {
    width: 100%;
}

.bg-block-border {
    display: inline-block;
}

.bg-block-border .col-50 {
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
}

.bg-block-border .col-50:nth-child(3), .bg-block-border .col-50:nth-child(1) {
    border-left: 0 none;
}

.bg-block-border .col-50:nth-child(4), .bg-block-border .col-50:nth-child(3) {
    border-bottom: 0 none;
}

.testi-block.title-style-4.bg-block-2 .simple-text p {
    text-transform: none;
}

.testi-block.title-style-4.bg-block-2 .simple-text span {
    color: #111;
}

.testi-block.title-style-4.bg-block-2 .sub-caption {
    color: rgba(85, 85, 85, 0.7);
}

.team-slider-home13 {
    position: relative;
    width: 100%;
}

.team-slider-home13 .main-img {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    margin-left: -85px;
}

.cl-item {
    position: relative;
    cursor: pointer;
    display: inline-block;
    min-height: 240px;
    text-align: center;
    width: 100%;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.cl-item .title {
    position: relative;
    color: #fff;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.swiper-slide.active .cl-item, .swiper-slide:hover .cl-item {
    background: #fff;
}

.swiper-slide.active .cl-item .title, .swiper-slide:hover .cl-item .title {
    color: #111;
}

.portfolio-home13 {
    padding-right: 70px;
}

.swich-link-home13 {
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: relative;
}

.portfolio-swich-link {
    height: 530px;
    margin-top: 50px;
    margin-bottom: 10px;
}

.portfolio-swich-link .swiper-slide:nth-child(2n+1) {
    margin-left: 30px;
}

.preview-portfolio {
    position: relative;
    display: inline-block;
}

.preview-portfolio .layer {
    background: rgba(255, 255, 255, 0.7);
    opacity: 0;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.swiper-slide.active .preview-portfolio {
    z-index: 58;
}

.swiper-slide.active .preview-portfolio .layer {
    opacity: 1;
}

.preview-portfolio img {
    max-width: 230px;
    display: block;
}

.contact-form-home13 {
    text-align: left;
    margin-bottom: -70px;
    z-index: 5;
    position: relative;
}

@media (max-width: 1400px) {
    .section-title {
        left: 30px;
    }

    .image.hidden-lg {
        display: none;
    }

    .team-slider-home13 .main-img {
        margin-left: 0;
    }

    .team-slider-home13 .main-img img {
        max-width: 70%;
    }

    .image-top-section {
        width: 60%;
    }
}

@media (max-width: 1200px) {
    .portfolio-swich-link .swiper-slide:nth-child(2n+1) {
        margin-left: 0;
    }

    .portfolio-home13 {
        padding-right: 0;
    }

    .price-item-2 {
        padding: 0px 30px;
    }

    .team-item-3 .hide-link {
        opacity: 1;
    }
}

@media (min-width: 992px) {
    .header-style-2.stick {
        background: #111;
        position: fixed;
        top: 0;
        bottom: auto;
    }

    .header-style-2.type-3 .navbar-brand {
        display: none;
    }

    .header-style-2.type-3.fix {
        background: none;
        height: 70px;
    }

    .color-style-12 .header-style-2.type-3.stick {
        background: #666;
    }
}

@media (max-width: 992px) {
    .header-style-2.type-3 {
        height: 70px;
        position: fixed;
        top: 0;
        bottom: auto;
        background: #111;
    }

    .header-style-2.type-3 .navbar-brand {
        display: block;
    }

    .header-style-2.type-3.fix {
        background: #111;
    }

    .header-style-2.type-3 .nav-list {
        background: #222;
    }

    .logo-baner, .team-slider-home13 .main-img {
        display: none;
    }

    .image-top-section {
        text-align: center;
    }

    .about-slider-home13 {
        position: relative;
        top: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        max-width: 100%;
        float: left;
    }

    .about-slider-home13 .swiper-container {
        padding-bottom: 60px;
    }

    .about-slider-home13 .swiper-container .image img {
        width: 100%;
        height: auto;
    }

    .item-img-half.type-4 .image {
        position: relative;
        width: 100%;
        height: 380px;
        padding: 0 15px;
    }

    .portfolio-item-2 .image {
        min-height: 380px;
    }

    .show-point-md {
        padding-bottom: 60px;
    }

    .show-point-md .pagination.hidden {
        display: block;
    }

    .contact-form-home13 {
        margin-bottom: 0;
    }

    .hidden-md {
        display: none;
    }

    .image-top-section {
        width: 100%;
    }

    .header-style-1.type-2 .drop-link .icon {
        height: 40px;
        line-height: 40px;
    }
}

@media (max-width: 768px) {
    .section-title {
        position: relative;
        top: auto;
        left: auto;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        margin-bottom: 50px;
    }

    .section-title .sepp {
        width: 50px;
    }

    .item-img-half.type-4 .desc-text {
        padding: 0;
    }

    .section-title .sepp {
        display: none;
    }

    .title-style-4.text-left {
        text-align: center;
    }

    .title-style-2.type-2 .line-style:after {
        display: none;
    }

    .page-style-5 .title-style-2.type-2 {
        padding-left: 0;
        text-align: center;
    }

    .section-title.align-custome {
        margin-top: 0;
    }
}

@media (max-width: 554px) {
    .service-item-4 .image {
        display: block;
        position: relative;
        margin: 0 auto;
    }

    .service-item-4 .text {
        padding-left: 0;
    }

    .service-item-4 .text .title-style-4 {
        text-align: center;
    }

    .item-img-half.type-4 .image {
        height: 300px;
    }

    .portfolio-item-2 .image {
        min-height: 300px;
    }

    .bg-block-border .col-50 {
        width: 100%;
        border: 0 none;
    }

    .bg-block-border .col-50 .item-icon.type-5 {
        height: auto;
    }

    .price-item-2 {
        padding: 0 15px;
    }
}

@media (max-width: 480px) {
    .accordeon-style-img .accordeon-triger i {
        display: none;
    }

    .accordeon-style-img .accordeon-triger .title {
        padding: 20px 15px;
        font-size: 12px;
    }

    .section-title {
        margin-bottom: 30px;
    }

    .price-item-2 .button-style-1 {
        float: none;
    }

    .price-item-2 .price-val {
        display: block;
        margin-bottom: 30px;
        width: 100%;
        text-align: center;
    }
}

.color-style-14 .button-style-1.dark:hover {
    color: #fff;
    background: #dbc496;
}

/*======CONTACT PAGE======*/
.color-style-1 .header-style-2 .nav-link.active, .color-style-1 .header-style-2 .nav-link:hover, .color-style-1 .button-style-1 {
    background: #ffa800;
    color: #fff;
}

.color-style-1 .header-style-2 .nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.color-style-1 .header-style-2.fix {
    background: #222;
}

.color-style-1 .contact-sm-item.type-3 .icon, .color-style-1 .contact-sm-item.type-3 .link:hover {
    color: #ffa800;
}

.color-style-1 .button-style-1:hover {
    background: #222 !important;
}

.map-item-2 {
    position: relative;
    width: 100%;
    height: 492px;
}

.contact-sm-item.type-3 {
    text-align: center;
}

.contact-sm-item.type-3 .sep-line {
    display: block;
    width: 15px;
    height: 1px;
    background: #222;
    margin: 0 auto;
}

.contact-sm-item.type-3 p, .contact-sm-item.type-3 .link {
    font-size: 13px;
    color: #a1a1a1;
    line-height: 22px;
    display: block;
}

.contact-sm-item.type-3 .icon {
    color: #222;
    font-size: 30px;
}

@media (max-width: 992px) {
    .color-style-1 .header-style-2, .color-style-1 .header-style-2 .nav-list {
        background: #222;
    }

    .map-item-2 {
        height: 400px;
    }

    .color-style-1 .header-style-2 .nav-link {
        background: none;
    }

    .color-style-1 .header-style-2 .nav-link.active, .color-style-1 .header-style-2 .nav-link:hover {
        background: none;
        color: #fff;
    }
}

/*======SERVICE PAGE======*/
.sub-caption-2 {
    font-size: 14px;
    color: #fff;
}

.color-style-6 .header-style-2.fix {
    background: #111;
}

.color-style-6 .header-style-2 .nav-link.active, .color-style-6 .header-style-2 .nav-link:hover {
    background: #435e69;
    color: #fff;
}

.color-style-6 .button-style-1 {
    background: #222;
    color: #fff;
    font-size: 12px;
}

.color-style-6 .button-style-1:hover {
    background: #435e69;
}

.color-style-6 .button-style-1.type-2 {
    background: #435e69;
}

.color-style-6 .button-style-1.type-2:hover {
    background: #222;
    color: #fff;
}

.color-style-6 .circle-bg, .color-style-6 .skill-block .skill-line div, .color-style-6 .list-style-1 li .list-point {
    background: #435e69;
}

.color-style-6 .icon-numb-2, .color-style-6 .list-img i, .color-style-6 .item-icon.type-4 .icon, .color-style-6 .price-item-1 .title, .color-style-6 .price-item-1 .price-val b, .color-style-6 .price-item-1.type-2 .price-val sub {
    color: #435e69;
}

.color-style-6 .price-item-1 .sub-caption, .color-style-6 .price-item-1 .price-val span {
    color: #989898;
}

.color-style-6 .price-item-1 .price-header {
    border-color: #e3e3e3;
}

.color-style-6 .price-item-1 ul li b {
    color: #222;
}

.color-style-6 .header-style-2 .nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.color-style-6 .price-item-1 {
    background: #f9f9f9;
}

.color-style-6 .price-item-1:hover {
    background: #435e69;
}

.color-style-6 .price-item-1:hover .price-val span, .color-style-6 .price-item-1:hover .sub-caption, .color-style-6 .price-item-1:hover .title, .color-style-6 .price-item-1:hover .price-val b, .color-style-6 .price-item-1:hover .price-val sub {
    color: #fff;
}

.color-style-6 .price-item-1:hover ul li b {
    color: #fff;
}

.color-style-6 .price-item-1:hover .list-style-1 li .list-point {
    background: #fff;
}

.color-style-6 .price-item-1:hover ul li {
    color: rgba(255, 255, 255, 0.7);
}

.color-style-6 .price-item-1.type-2:hover .button-style-1 {
    background: #fff;
    color: #222;
}

.color-style-6 .price-item-1.type-2 .button-style-1:hover {
    background: #222;
    color: #fff;
}

.page-style-7 .footer-form .submit {
    font-size: 11px;
}

.page-style-7 .footer-style-2 .footer-item .title {
    text-transform: uppercase;
    font-size: 15px;
}

.circle-bg {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #111;
    text-align: center;
    line-height: 100px;
    margin: 0 auto;
    color: #fff !important;
}

.item-icon.type-2 .icon.circle-bg {
    color: #fff !important;
}

.tem-img-half.type-3 {
    height: 100%;
    position: relative;
    width: 50%;
}

.item-img-half.type-3 .image {
    width: 50%;
    position: absolute;
    height: 100%;
}

.item-img-half.type-3.right .image {
    left: 0;
}

.item-img-half.type-3 .image .bg {
    width: 100%;
}

.item-img-half.type-3.left .image {
    right: 0;
}

.item-img-half.type-3 .desc-text {
    padding: 0;
}

.item-img-half.type-3 .list-img-br .col-50 {
    max-height: 300px;
}

.box-width {
    max-width: 480px;
    margin: 0 auto;
}

.page-style-7 .model-style-play {
    font-weight: 700;
}

.icon-numb-2 {
    font-size: 42px;
    line-height: 42px;
    color: #111;
}

.item-icon.type-4 {
    position: relative;
    text-align: left;
}

.item-icon.type-4 .icon {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 36px;
    width: 65px;
}

.item-icon.type-4 .text {
    position: relative;
    padding-left: 65px;
}

.item-icon.type-4.info-type .text {
    padding-left: 140px;
}

.item-icon.type-4.info-type .icon b {
    font-weight: 900;
    font-size: 60px;
}

.client-item-3 {
    text-align: center;
}

.client-item-3 .title {
    text-transform: uppercase;
}

.client-item-3 .sub-caption {
    color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 992px) {
    .color-style-6 .header-style-2, .color-style-6 .header-style-2 .nav-list {
        background: #111;
    }

    .color-style-6 .header-style-2 .nav-link.active, .color-style-6 .header-style-2 .nav-link:hover {
        background: none;
    }

    .wide-md.item-img-half.type-3 .image {
        position: relative;
    }
}

@media (max-width: 480px) {
    .item-icon.type-4 .text {
        padding-left: 0px;
    }

    .item-icon.type-4 .icon {
        margin-bottom: 10px;
    }
}

/*======LANDING PAGE======*/
.full-height-baner.full-sm {
    height: 600px;
}

.item-icon.type-2 .icon.color-1 {
    color: #cd9129;
    font-size: 36px;
}

.color-style-2 .isotope-filter-list.type-1 li, .color-style-2 .mobile-filter-wrap.fix-line .change-text {
    font-weight: 700;
    text-transform: uppercase;
}

.ld-style.filter-mob-list.type-1 {
    text-align: center;
    border-bottom: 1px solid #e3e3e3;
}

.ld-style.filter-mob-list.type-1 li {
    width: auto;
    padding: 0px 50px;
    float: none;
    display: inline-block;
    border-bottom: 0px none;
}

.fix-line {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 500;
    font-size: 0;
}

.fix-line.fixed {
    position: fixed;
}

.ld-demo .image {
    height: 350px;
}

.ld-demo .layer {
    z-index: 4;
}

@media (max-width: 992px) {
    .full-height-baner.full-sm {
        height: auto;
    }

    .fix-line {
        left: 50%;
        margin-left: -150px;
    }

    .ld-style.filter-mob-list.type-1 li {
        width: 100%;
    }
}

/*======ABOUT PAGE======*/
.page-style-9 .button-style-1.type-2 {
    color: #222;
    background: #fff;
    font-size: 12px;
    font-weight: 700;
}

.page-style-9 .button-style-1.type-2:hover {
    background: #cd9129;
    color: #fff;
}

.page-style-9 .header-style-2 .nav-item {
    color: rgba(255, 255, 255, 0.7);
}

.page-style-9 .header-style-2.fix {
    background: #222;
}

.page-style-9 .header-style-2 .nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.page-style-9 .footer-style-2 .footer-item .title {
    font-size: 15px;
}

.footer-style-2 .folow.type-1 a {
    border-color: rgba(255, 255, 255, 0.2);
}

.color-style-2 .list-style-1.color-2 li .list-point {
    background: #cd9129;
}

.color-style-2 .item-icon.type-4 b {
    color: #cd9129;
}

.color-style-2 .swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.dark .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #cd9129;
}

.border-style.padd-lg {
    padding: 100px 60px;
}

.border-block {
    border: 3px solid #fff;
    position: relative;
    width: 100%;
}

.border-block.testi .image {
    position: absolute;
    left: 30px;
    max-width: 320px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.border-block.testi .text {
    padding-left: 400px;
}

.info-item-slider .wrap {
    padding: 0 40px 0 20px;
}

.info-item-slider .swiper-pagination-bullets.center {
    margin-left: -20px;
}

.team-item-3.type-2 .image img {
    height: 450px;
    max-width: none;
}

.team-item-1 .share-link.type-2 a:hover {
    background: #fff !important;
}

.about-testi-slider {
    max-width: 900px;
    padding: 35px 0;
}

.quote-block.text-left {
    text-align: left;
}

.client-item-4 {
    position: relative;
    float: left;
    width: 16.66%;
    min-height: 170px;
    border-right: 1px solid #e3e3e3;
}

.client-item-4:last-child {
    border-right: 0 none;
}

.client-item-4 a:hover {
    opacity: 0.5;
}

.line-border {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #e3e3e3;
}

.color-style-11 .header-style-2 .nav-link.active, .color-style-11 .header-style-2 .nav-link:hover {
    background: #d39318;
    color: #fff;
}

.color-style-11 .title-style-4 .title-separator, .color-style-11 .icon-numb, .color-style-11 .button-style-1.type-1, .color-style-11 .image-square .sq {
    background: #d39318;
}

.color-style-11 .title-style-6.type-2 .sub-caption-line, .color-style-11 .list-img.type-3 i {
    color: #d39318;
}

.color-style-11 .title-style-6.type-2 .sub-caption-line .right, .color-style-11 .title-style-6.type-2 .sub-caption-line .left, .color-style-11 .border-cube .c-l, .color-style-11 .border-cube .t-l, .color-style-11 .border-cube .b-l {
    background: #d39318;
    opacity: 0.8;
}

.border-col.team-item-1 .desc {
    box-shadow: 0px 0px 0px 1.071rem #d39318;
}

.color-style-11 .testi-about-block {
    border-color: #d39318;
}

.color-style-11 .swiper-container-horizontal > .swiper-pagination-bullets.pagination-type-1.dark .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #d39318;
}

.page-style-13 .button-style-1 {
    font-size: 12px;
}

.page-style-13 .button-style-1.type-2:hover {
    background: #d39318;
    color: #fff;
}

.page-style-13 .list-img.type-3 span {
    text-transform: none;
}

.page-style-9 .swipe-arrow:hover {
    color: rgba(255, 255, 255, 0.5);
}

.scrol-dark.header-style-2.fix {
    background: #111;
}

.scrol-dark.header-style-2 .nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.image-square {
    position: relative;
    width: 100%;
}

.image-square .sq {
    background: #222;
    position: relative;
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 390px;
}

.image-square.type-1 .sq {
    max-width: 360px;
}

.image-square img {
    position: absolute;
}

.image-square.type-1 img:nth-child(1) {
    left: 0;
    top: -40px;
}

.image-square.type-1 img:nth-child(2) {
    right: 0;
    top: 30px;
}

.image-square.type-2 .sq {
    max-width: 840px;
}

.image-square.type-2 img:nth-child(1) {
    left: 0;
    top: -40px;
}

.image-square.type-2 img:nth-child(2) {
    left: 185px;
    top: 30px;
    z-index: 5;
}

.image-square.type-2 img:nth-child(3) {
    right: 0;
    top: -40px;
}

.image-square.type-3 .sq {
    max-width: 430px;
}

.image-square.type-3 img:nth-child(1) {
    left: 0;
    top: -40px;
    z-index: 1;
}

.image-square.type-3 img:nth-child(2) {
    right: 0;
    top: 30px;
}

.image-square.no-bg .sq {
    background: none;
}

.title-style-6 {
    position: relative;
}

.title-style-6 .simple-text {
    max-width: 500px;
}

.title-style-6 .sub-caption-line {
    font-size: 16px;
    font-style: italic;
    display: inline-block;
}

.title-style-6 .sub-caption-line span {
    vertical-align: middle;
    line-height: 16px;
}

.title-style-6 .sub-caption-line .right {
    position: relative;
    display: inline-block;
    height: 1px;
    margin-left: 5px;
}

.title-style-6 .sub-caption-line .left {
    display: none;
    position: relative;
    height: 1px;
    margin-right: 5px;
}

.title-style-6 .sub-caption-line.lg .right, .title-style-6 .sub-caption-line.lg .left {
    width: 100px;
    margin-top: 3px;
}

.title-style-6 .sub-caption-line.sm .right, .title-style-6 .sub-caption-line.sm .left {
    width: 50px;
}

.title-style-6.type-1 .sub-caption-line {
    color: rgba(255, 255, 255, 0.7);
}

.title-style-6.type-1 .sub-caption-line .right, .title-style-6.type-1 .sub-caption-line .left {
    background: rgba(255, 255, 255, 0.7);
}

.title-style-6.type-2 .sub-caption-line {
    color: #222;
}

.title-style-6.type-2 .sub-caption-line .right, .title-style-6.type-2 .sub-caption-line .left {
    background: #222;
}

.title-style-6.center {
    text-align: center;
    margin: 0 auto;
}

.title-style-6.center .sub-caption-line .left {
    display: inline-block;
}

.title-style-6.center .simple-text {
    margin: 0 auto;
}

.border-cube {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.border-cube .c-l {
    position: absolute;
    left: -70px;
    top: -50px;
    width: 10px;
    background: #fff;
    height: calc(100% + 100px);
    z-index: 5;
}

.border-cube .c-l:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 160px;
    height: 10px;
    background: inherit;
}

.border-cube .c-l:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 160px;
    height: 10px;
    background: inherit;
}

.border-cube .t-l {
    position: absolute;
    left: 90px;
    top: -50px;
    height: 50px;
    width: 10px;
    background: #fff;
}

.border-cube .b-l {
    position: absolute;
    left: 90px;
    bottom: -50px;
    height: 50px;
    width: 10px;
    background: #fff;
}

.testi-about-block {
    position: relative;
    width: 100%;
    border: 10px solid #111;
    text-align: center;
    padding: 0 60px;
}

.testi-about-block .image img {
    border-radius: 50%;
}

.testi-about-block blockquote span {
    color: #111;
    font-size: 20px;
}

.testi-about-block .sub-caption {
    font-style: italic;
}

.testi-about-block.type-2 {
    border: 0 none;
}

.testi-about-block.type-2 .image img {
    border-radius: 0;
    display: block;
    margin: 0 auto;
}

.page-style-13 .header-style-2 .nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.page-style-13 .header-style-2.fix {
    background: #222;
}

.page-style-13 .footer-style-4 .caption-5 {
    font-size: 16px;
}

.page-style-13 .footer-style-4 .input-group.input-style-1 .form-control {
    font-style: normal;
}

@media (max-width: 1200px) {
    .border-cube {
        left: 30px;
    }
}

@media (max-width: 992px) {
    .page-style-9 .header-style-2, .page-style-13 .header-style-2, .page-style-13 .header-style-2 .nav-list {
        background: #222;
    }

    .page-style-9 .header-style-2 .mobile-menu.col-style-1 i, .page-style-9 .header-style-2 .mobile-menu.col-style-1 i:after, .page-style-9 .header-style-2 .mobile-menu.col-style-1 i:before {
        background: #fff;
    }

    .page-style-9 .header-style-2 .nav-list {
        background: #222;
    }

    .color-style-2.page-style-9 .header-style-2 .nav-link.active, .color-style-2.page-style-9 .header-style-2 .nav-link:hover {
        color: #fff;
    }

    .border-style.padd-lg {
        padding: 60px 30px;
    }

    .info-item-slider {
        padding-bottom: 60px;
    }

    .info-item-slider .pagination.vertical-type.center {
        left: 0;
        -webkit-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        top: auto;
        bottom: 0;
        width: 100%;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets.vertical-type .swiper-pagination-bullet {
        display: inline-block;
        float: none;
        margin: 0 5px;
    }

    .border-block.testi .image {
        max-width: 100%;
        position: relative;
        left: 0;
        top: 0;
        -webkit-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        padding: 0 30px 50px;
    }

    .border-block.testi .text, .testi-about-block {
        padding: 0 30px;
    }

    .client-item-4 {
        width: 33.333%;
    }

    .client-item-4:nth-child(3) {
        border-right: 0 none;
    }

    .color-style-11 .header-style-2 .nav-link.active, .color-style-11 .header-style-2 .nav-link:hover {
        background: none;
    }

    .about-testi-slider {
        padding: 0;
    }
}

@media (max-width: 768px) {
    .image-square.type-1 img:nth-child(1), .image-square.type-1 img:nth-child(2) {
        top: 0;
    }

    .image-square.type-2 img {
        max-width: 50%;
    }

    .image-square.type-2 img:nth-child(1) {
        top: 40px;
    }

    .image-square.type-2 img:nth-child(2) {
        top: 80px;
    }

    .border-cube {
        display: none;
    }

    .about-testi-slider {
        padding: 0 0 60px;
    }
}

@media (max-width: 554px) {
    .image-square.type-1, .image-square.type-2, .image-square.type-3 {
        display: none;
    }
}

@media (max-width: 480px) {
    .item-icon.type-4 .icon {
        position: relative;
        width: 100%;
        display: block;
        text-align: center;
    }

    .item-icon.type-4.info-type .icon b {
        font-size: 40px;
        display: block;
        margin-bottom: 15px;
    }

    .item-icon.type-4.info-type .text {
        padding-left: 0px;
    }

    .item-icon.type-4 {
        text-align: center;
    }

    .info-item-slider .wrap {
        padding: 0;
    }

    .border-block.testi .text, .testi-about-block {
        padding: 0 15px;
    }

    .border-block.testi .image {
        padding: 0 15px 30px;
    }

    .client-item-4 {
        width: 50%;
        border: 0 none;
        min-height: 140px;
    }

    .line-border {
        border-bottom: 0 none;
    }
}

/*======PORTFOLIO PAGE======*/
.mobile-filter-wrap.type-3 {
    text-align: center;
}

.mobile-filter-wrap.type-3 .isotope-filter-list li {
    text-transform: uppercase;
    font-size: 12px;
    color: #989898;
    padding: 0 20px;
    margin: 0 5px;
    height: 50px;
    line-height: 48px;
    border: 1px solid transparent;
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.mobile-filter-wrap.type-3 .isotope-filter-list li:hover, .mobile-filter-wrap.type-3 .isotope-filter-list li.active {
    border-color: #222;
    color: #222;
}

.mobile-filter-wrap.type-3.color-1 .isotope-filter-list li {
    color: rgba(255, 255, 255, 0.7);
}

.mobile-filter-wrap.type-3.color-1 .isotope-filter-list li:hover, .mobile-filter-wrap.type-3.color-1 .isotope-filter-list li.active {
    border-color: rgba(255, 255, 255, 0.5);
    color: #fff;
}

.page-style-10 .portfolio-item-1 .title a:hover {
    color: #989898;
}

.page-style-8 .footer-style-2 .footer-item .title {
    text-transform: uppercase;
    font-size: 15px;
}

.page-style-8 .footer-form .submit {
    font-size: 11px;
}

.page-style-8 .nav-item .dropdown-menu li a {
    font-size: 11px;
}

.sm-height-baner {
    position: relative;
    width: 100%;
    height: 570px;
    padding-top: 115px;
    z-index: 5;
}

.sm-height-baner .wrap {
    position: relative;
    width: 100%;
    height: 100%;
}

.color-style-9.page-style-4 .header-style-2 .nav-link.active, .color-style-9.page-style-4 .header-style-2 .nav-link:hover {
    background: none;
    color: #222;
}

.color-style-9.page-style-4 .header-style-2 {
    background: #fff;
}

.color-style-9.page-style-4 .header-style-2 .nav-link {
    color: #989898;
}

.color-style-9 .title-style-5 .title-separator .left-line, .color-style-9 .title-style-5 .title-separator .right-line {
    background: #fcae27;
}

.col-100 {
    width: 100%;
}

.portfolio-item-1.type-3 {
    width: 50%;
}

.portfolio-item-1.type-3.fr {
    text-align: right;
    margin-right: 35px;
}

.portfolio-item-1.type-3.fl {
    margin-left: 35px;
}

.portfolio-item-1.type-3 .image {
    display: block;
    overflow: hidden;
}

.portfolio-item-1.type-3 a:hover {
    color: #fcae27;
}

.izotope-container.align-wrap {
    margin-top: 160px;
}

.izotope-container.align-wrap .item.col-100 {
    margin-top: -160px;
}

.color-style-10 .title-style-1 .sub-title {
    color: #d0be76;
}

.color-style-10 .mobile-filter-wrap.type-3 .isotope-filter-list li:hover, .color-style-10 .mobile-filter-wrap.type-3 .isotope-filter-list li.active {
    border-color: #d0be76;
}

.color-style-10 .header-style-2 .nav-link.active, .color-style-10 .header-style-2 .nav-link:hover {
    background: #d0be76;
    color: #fff;
}

.page-style-11 .title-style-1 .sub-title {
    font-size: 42px;
    text-transform: none;
}

.page-style-11 .footer-style-4 .copyright {
    font-size: 13px;
    font-weight: 700;
}

.page-style-11 .footer-style-4 .input-group.input-style-1 .form-control {
    font-style: normal;
}

.izotope-container.size-2 .item {
    padding: 0 5px 10px;
}

.main-detail-baner {
    position: relative;
    max-width: 830px;
    margin: 0 auto;
    display: block;
    width: 100%;
    padding: 0 15px;
}

.color-style-1 .title-separator {
    background: #ffa800;
}

.color-style-1 .portfolio-arrow:hover .arrow-line i {
    color: rgba(255, 168, 0, 0.5);
}

.color-style-1 .arrow-line .line {
    background: rgba(255, 168, 0, 0.5);
}

.color-style-1 .slide-item .caption-4.color-1:hover a {
    color: #ffa800;
}

.portfolio-arrow {
    position: absolute;
    top: 50%;
    z-index: 5;
    margin-top: -70px;
}

.portfolio-arrow:hover .image, .portfolio-arrow:hover .title {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.portfolio-arrow:hover .arrow-line .line {
    width: 70px;
}

.link-menu {
    display: inline-block;
    position: relative;
}

.arrow-prev {
    left: 35px;
}

.arrow-next {
    right: 35px;
}

.portfolio-arrow .title, .portfolio-arrow .image {
    position: relative;
    display: block;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    opacity: 0;
    visibility: hidden;
}

.portfolio-arrow.arrow-prev .title, .portfolio-arrow.arrow-prev .image {
    padding-left: 60px;
}

.portfolio-arrow.arrow-next .title, .portfolio-arrow.arrow-next .image {
    padding-right: 60px;
    text-align: right;
}

.portfolio-arrow .title {
    display: block;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
}

.portfolio-arrow .title .sub-caption {
    color: rgba(255, 255, 255, 0.7);
    padding-bottom: 10px;
    display: block;
}

.portfolio-arrow .image {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
}

.arrow-line {
    display: block;
    position: relative;
}

.arrow-line .line {
    width: 0px;
    height: 1px;
    background: rgba(255, 255, 255, 0.5);
    position: relative;
    display: block;
    margin-left: 30px;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.arrow-line i {
    position: absolute;
    left: 0;
    top: 50%;
    font-size: 34px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.5);
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.portfolio-arrow.arrow-next .arrow-line i {
    left: auto;
    right: 0;
}

.portfolio-arrow.arrow-next .arrow-line .line {
    margin: 0 30px 0 0;
    float: right;
}

.author-info li {
    position: relative;
    width: 100%;
    clear: both;
    text-align: left;
    padding-bottom: 20px;
}

.author-info h6 {
    position: relative;
    float: left;
    display: block;
    max-width: 100px;
}

.author-info .sub-caption {
    position: relative;
    padding-left: 100px;
    display: block;
}

.page-nav {
    position: relative;
    width: 100%;
    min-height: 170px;
    background: #f9f9f9;
    display: block;
}

.page-nav .image {
    position: absolute;
    top: 0;
    width: 200px;
    height: 100%;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    overflow: hidden;
}

.page-nav.right .image {
    right: 0;
}

.page-nav.right .title {
    padding-right: 230px;
    text-align: right;
    right: 0;
}

.page-nav.left .title {
    padding-left: 230px;
}

.page-nav .title .sub-caption {
    display: block;
    padding-bottom: 15px;
    color: #555;
}

.page-nav, .page-nav .caption-4, .page-nav .title .sub-caption, .page-nav .all-project, .page-nav .title {
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.page-nav:hover {
    background: #111;
}

.page-nav:hover .caption-4 {
    color: #fff;
}

.page-nav:hover .title .sub-caption {
    color: rgba(255, 255, 255, 0.7);
}

.page-nav:hover .all-project {
    color: #fff;
}

.page-nav:hover .image {
    width: 150px;
}

.page-nav.left:hover .title {
    padding-left: 180px;
}

.page-nav.right:hover .title {
    padding-right: 180px;
}

.portfolio-desc .title-style-4 {
    position: relative;
    z-index: 1;
}

.all-project {
    color: #111;
    font-size: 30px;
}

.slide-item {
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
    z-index: 1;
}

.slide-item .image {
    position: relative;
    display: block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.slide-item .vertical-align {
    z-index: 5;
}

.slide-item .sub-caption {
    color: rgba(255, 255, 255, 0.7);
}

.page-nav .title .icon {
    font-size: 30px;
    display: none;
}

@media (max-width: 1200px) {
    .page-nav .image {
        width: 100px;
    }

    .page-nav.right .title {
        padding-right: 130px;
    }

    .page-nav.left .title {
        padding-left: 130px;
    }
}

@media (max-width: 992px) {
    .full-height-baner {
        z-index: 5;
    }

    .mobile-filter-wrap.type-3 .isotope-filter-list li {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 40px;
        line-height: 38px;
    }

    .mobile-filter-wrap.type-3 .change-text {
        border-color: #111;
        color: #111;
    }

    .page-style-10 .drop-link .icon {
        color: #989898;
    }

    .sm-height-baner {
        padding-top: 60px;
        height: auto;
    }

    .mobile-filter-wrap.type-3.color-1 .change-text {
        border-color: #fff;
        color: #fff;
    }

    .mobile-filter-wrap.type-3.color-1 .isotope-filter-list li {
        color: #989898;
        border: 0 none;
    }

    .mobile-filter-wrap.type-3.color-1 .isotope-filter-list li.active {
        color: #111;
    }

    .izotope-container.align-wrap .item.col-100, .izotope-container.align-wrap {
        margin-top: 0;
    }

    .portfolio-item-1.type-3.fl {
        margin-left: 0;
    }

    .portfolio-item-1.type-3.fr {
        margin-right: 0;
    }

    .page-style-4.color-style-9 .mobile-menu.col-style-1 i, .page-style-4.color-style-9 .mobile-menu.col-style-1 i:after, .page-style-4.color-style-9 .mobile-menu.col-style-1 i:before {
        background: #111;
    }

    .color-style-9.page-style-4 .header-style-2 .nav-link.active, .color-style-9.page-style-4 .header-style-2 .nav-link:hover {
        color: #fff;
    }

    .color-style-10 .header-style-2 .nav-link.active, .color-style-10 .header-style-2 .nav-link:hover {
        background: none;
        color: #111;
    }

    .color-style-10 .header-style-2 .drop-link .icon {
        color: #989898;
    }

    .video-open.type-2 {
        height: 500px;
    }
}

@media (max-width: 768px) {
    .portfolio-item-1.type-3 {
        width: 100%;
    }

    .page-nav .image {
        display: none;
    }

    .page-nav.right .title {
        padding-right: 15px;
    }

    .page-nav.left .title {
        padding-left: 15px;
    }

    .page-style-11 .title-style-1 .sub-title {
        font-size: 20px;
        line-height: 20px;
    }

    .portfolio-item-1.type-3.fr {
        text-align: left;
    }

    .izotope-container.align-wrap .item.col-100 {
        margin-bottom: 60px;
    }
}

@media (max-width: 554px) {
    .size-1 .item.col-50 {
        width: 100%;
    }

    .video-open.type-2 {
        height: 300px;
    }

    .page-nav .title .caption-4, .page-nav .title .sub-caption {
        display: none;
    }

    .page-nav .title .icon {
        display: inline-block;
    }

    .page-nav.right .title, .page-nav.left .title {
        text-align: center;
        width: 100%;
        padding: 0;
    }

    .page-nav {
        min-height: 70px;
    }

    .portfolio-arrow {
        display: none;
    }
}

.button-style-1.dark {
    background: #222;
    color: #fff;
}

.price-item-1.type-2:hover .title, .price-item-1.type-2:hover .price-val b, .price-item-1.type-2:hover .price-val sub {
    color: #fff !important;
}

.price-item-1.type-2:hover .button-style-1.main-color {
    background: #fff !important;
}

.price-item-1.type-2 .button-style-1.main-color:hover {
    color: #222;
}

.header-style-2.no-bg-avtive-link .nav-link.active, .header-style-2.no-bg-avtive-link .nav-link:hover {
    background: none !important;
    color: #222;
}

.main-color.button-style-1 {
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.main-color.button-style-1:before {
    background: #222;
    content: '';
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    z-index: -1;
    margin-left: -25px;
    margin-top: -25px;
    -webkit-transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transform: scale(0);
    transform: scale(0);
}

.main-color.button-style-1:hover {
    background: none !important;
}

.main-color.button-style-1:hover:before {
    -webkit-transform: scale(5);
    transform: scale(5);
}

.button-style-1.submit.type-1.main-color:hover {
    background: #111 !important;
}

.price-item-1.type-2.white-hover:hover {
    background: #fff !important;
}

.price-item-1.type-2.white-hover:hover .price-val b, .price-item-1.type-2.white-hover:hover .title, .price-item-1.type-2.white-hover:hover .price-val sub {
    color: #222 !important;
}

.colorpicker-checkbox {
    display: none;
}

.jscolor-popup div {
    border-radius: 0 !important;
}

.dark-mobile.header-style-2 .drop-link .icon {
    color: #fff !important;
}
