/*links*/
/* z index!
navbar z-index: 1000

*/

* {
    box-sizing: border-box;
    font-family: Montserrat, sans-serif;
}

/*helpfull styles*/
a img:hover {
    opacity: 0.8;
}

.align-item-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

a:hover {
    color: #C69C5D !important
}

.href-color {
    color: #CDA871;
}

.text-white {
    color: white !important;
}

.container {
    max-width: 1250px !important;
}

.container-1350 {
    max-width: 1350px !important;
}

.big-container {
    max-width: 2500px !important;
    margin: 0 auto;
}

.link-transform:hover {
    transform: scale(1.02);
}

.grecaptcha-badge {
    z-index: 1000;
}

.swiper-pagination-bullet:focus {
    outline: none;
}

.swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    margin-right: 10px;
}

.swiper-pagination-bullet-active {
    background-color: #CDA871 !important;
}

.swiper-slide img:hover {
    cursor: pointer;
}

/*form controll*/

.form-control-manhattan {
    font-family: Montserrat, sans-serif;
    color: #7A7E7E;
    font-size: 15px;
    font-weight: 400;
    border: 0;
    border-bottom: 2px solid rgba(222, 222, 222, 0.8);
    padding: 10px 10px;
}

.form-control-manhattan-length {
    min-height: 100px;
    max-height: 300px;
}

.form-control:focus {
    border-color: #CDA871;
    box-shadow: inset 0 0px 1px rgba(0, 0, 0, 0.035), 0 0 3px rgb(205, 168, 113);
}

/*cookies*/
#simplecookienotification_v01 {
    display: none;
    z-index: 99999;
    min-height: 35px;
    width: 80%;
    position: fixed;
    background: rgb(255, 255, 255);
    border-image: initial;
    border-top: 2px solid #CDA871;
    border-left: 2px solid #CDA871;
    border-right: 2px solid #CDA871;
    text-align: left;
    left: 50%;
    transform: translateX(-50%);
    color: rgb(119, 119, 119);
    bottom: 0px;
    padding: 20px;
}

/*breadcrumbs*/
.breadcrumb-full-height-banner {
    position: relative;
    width: 100%;
    min-height: 10rem;
    height: 30vmin;
}

.breadcrumb-bg {
    padding: 20px;
    display: flex;
}

.breadcrumb-header {
    font-size: 40px;
    color: white;
    font-weight: 500;
}

/*contact*/
.text-28px {
    font-family: Montserrat, sans-serif;
    font-size: 28px;
    color: #26282E;
    font-weight: 400;
    line-height: 38px;
}

.text-18px {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    color: #26282E;
    font-weight: 400;
    line-height: 28px;
}

.text-14px {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    color: #26282E;
    font-weight: 400;
    line-height: 28px;
}

.logo-royal-sail {
    max-width: 350px;
}

.box-contact {
    background-color: #F2F2F2;
    padding: 50px;
}

.box-contact h1 {
    font-family: Montserrat, sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 28px;
    color: #26282E;
}

.developer-img {
    width: 281px;
    max-height: 382px;
}

.developer-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.developer-p {
    font-weight: 400;
    font-size: 15px;
    color: #7A7E7E;
    line-height: 24px;
}

/*flats and garages subpage*/

.flats-banner {
    min-height: 10rem !important;
    max-height: 691px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.w-auto {
    max-width: inherit !important;
}

/*about project subpage*/
.header-descr-blue {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    line-height: 24px;
    font-size: 18px;
    color: #596275;
}

.b-b-grey {
    border-bottom: 1px solid #E4E4E4;
}

.pagination-block .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 17px;
}

.pagination-block li {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination-block li span,
.pagination-block li a {
    padding: 8px 14px;
}


.pagination-block li:hover,
.pagination-block .active {
    background-color: #CDA871;
    color: white;
}

.pagination-block a:hover {
    color: white !important;
}

.pagination-block .disabled {
    opacity: 0.5;
}


/*no picture*/
.img-no-picture {
    max-width: 120px;
}

/*to panel*/
.btn-to-panel {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
    z-index: 1001;
    box-shadow: 0 0 6px rgba(0, 0, 0, .3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    color: black;
}

.btn-bottom-bar {
    color: #fff !important;
    border-radius: 5px;
    padding: 10px;
    border: 0;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    margin: 2px;
    font-size: 11px;
    transition: all .3s;
    border: 3px solid rgba(0, 0, 0, 0);
}

.btn-bottom-bar-login {
    background-color: #28a745;
}

.btn-bottom-bar-logout {
    background-color: #dc3545;
}

.btn-bottom-bar:hover {
    border: 3px solid rgba(0, 0, 0, .3);
}

/*old elements from front/app.blade.php*/
.vis-image {
    width: 100%;
}

.vis-second {
    display: none;
}

.center-block > div > a {
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    position: relative;
    padding-top: 12px;
}

.center-block > :first-child {
    background-color: #2C3E50;
    color: white;
    position: relative;
    padding-top: 12px;
}

.center-block > div > a:hover, .center-block > div:hover {
    background-color: #2C3E50;
    color: white;
}

.center-block > div {
    text-decoration: none;
    display: block;
    font-weight: 300;

    width: 100%;
    margin-top: 1px;

    border: 1px solid black;
    height: 39px;
    text-align: center;
    margin-bottom: 5px;
}

.calculate img {
    margin-bottom: 20px;
}

.calculate thead {
    display: none;
}

.calculate td {
    vertical-align: middle;
    padding: 0.75em 0;
}

.calculate td p {
    margin-bottom: 0;
}

.calculate tbody tr:first-child td {
    border-top: 2px solid #cfae77;
    font-weight: bold;
}

.calculate tbody tr td {
    border-bottom: 2px solid #cfae77;
}

.calculate tbody tr td:first-child {
    text-align: left;
    width: 40%;
}

.calculate tbody tr td:nth-child(2) {
    background-color: #f7f7f7;
    width: 20%;
}

.calculate tbody tr td:nth-child(3) {
    background-color: #f0f0f0;
    width: 20%;
}

.calculate tbody tr td:nth-child(4) {
    background-color: #e8e8e8;
    width: 20%;
}

.calculate .table-bottom {
    font-size: 0.7rem;
}

#wyszukiwarka {
    background-color: black;
    padding: 10px;
}


.naglowki {
    color: #cfae77;
}

.navbar-logo {
    left: -100px;
    top: 49%
}

@media (max-width: 1400px) {
    .navbar-logo {
        left: -30px;
        top: 49%
    }
}

@media (max-width: 1160px) {
    .navbar-logo {
        left: -30px;
        top: 100%
    }
}

@media (max-width: 992px) {
    .navbar-logo {
        left: -30px;
        top: 49%
    }
}

@media (max-width: 768px) {
    .navbar-logo {
        left: -18px;
        top: 49%
    }
}

/*media queries breakpoints*/
@media (min-width: 1201px) and (max-width: 1600px) {
    .offset-xl-2-fixed {
        margin-left: 8%;
    }
}

@media (min-width: 1301px) {

}

@media (max-width: 1300px) {

}

@media (min-width: 1250px) {
    .container-right {
        max-width: 1582px;
        margin-left: auto;
    }
}

/*media*/
@media (min-width: 1201px) {
}

@media (max-width: 1200px) {

}

@media (min-width: 993px) {
    .header-style-2 {
        height: 120px !important;
    }
}

@media (max-width: 992px) {
    .mx-mobile-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .w-mobile-100 {
        width: 100% !important;
    }

    .breadcrumb-header {
        font-size: 25px;
    }

    .header-style-2 {
        height: 90px !important;
    }

    .text-28px {
        font-size: 16px;
        line-height: 24px;
    }

    .text-18px {
        font-size: 13px;
    }

    .logo-royal-sail {
        max-width: 200px;
    }

    .box-contact h1 {
        font-size: 13px;
    }

    .box-contact {
        padding: 20px;
        margin: 20px 0 20px 0;
    }

    .box-contact img {
        max-width: 20px;
        max-height: 20px;
    }

    .developer-p {
        font-size: 12px;
    }

    /*about project subpage*/
    .header-descr-blue {
        font-size: 14px;
    }

}

@media (min-width: 769px) {
}

@media (max-width: 768px) {

}

@media (min-width: 577px) {
}

@media (max-width: 576px) {

}